import Component from "../../lib/base/component";
import Schema from "./schema";
import {getDefaultValue} from "./schema";
import {runInAction, observable, extendObservable, computed, autorun, toJS, isObservableArray, isObservableObject, isObservable, intercept, observe} from  "../../lib/mobx/mobx-2.6.2.umd";
import {parsePath} from "../../lib/base/pageImpl";
import Data from "./data";
import {isArray,isFunction, cloneJSON} from "../../lib/base/util";
import Number from "../../lib/base/number";
import UUID from "../../lib/base/uuid";
import Operational from "../../lib/base/operational";
import {prepareConfig} from "./util";

var VTREE = "vtree";
var VTREE_ONLY_PARENT = "vtreeOnlyParent";
var VTREE_ONLY_CHILD = "vtreeOnlyChild";

/**
 * data要求： 1. 路径中不允许使用特殊的标识"@, [, ]"(因为@, [, ]作为数组的过虑条件, 例如: a.b.c[@id=001]); 2.
 * 数组数据项的key值不允许使用特殊的标识"[, ]"
 * 
 * 数据的操作 1. 删除数据: 直接操作mobx数组, 例如this.comp("data").value.split(0, 1); 2. 修改数据:
 * 直接操作mobx对象, 例如this.comp("data").value[0].name = "xxx"; 3. 添加数据: 3.1 数组添加行:
 * 3.2 添加对象:
 */
export default class TableData extends Data {
     constructor(page, id, props, context){
        super(page, id, prepareConfig(id, props), context);

     }
     
     	setTreeFilter(key, filter){
		//子类实现
	}
	
	clearTreeFilter(){
		//子类实现
	}
     
     _getColRuleValueFromUserdata(col, ruleName, row){
    	 if (col){
    		 if (row){
            	 	if (typeof(row) === 'string'){
            			row = this.getRowByID(row);
            	 	}
    		 }else{
    			 row = this.current;
    		 }
    		 let userdata = row._userdata;
    		 if(userdata){
    			 if (ruleName === "required"){
    				 return userdata[col] && userdata[col][ruleName] && userdata[col][ruleName].val;
    			 }else{
    				 return userdata[col] && userdata[col][ruleName];	 
    			 }
    			 
    		 }
    	 }
    	 return false;
     }
     
     getColReadonly(col, row){
    	 return this._getColRuleValueFromUserdata(col, "readonly", row);
     }
     
     getColHidden(col, row){
    	 return this._getColRuleValueFromUserdata(col, "hidden", row);
     }
     
     getColRequired(col, row){
    	 return this._getColRuleValueFromUserdata(col, "required", row);
     }
     
     
     updateCurrent(array, row){
    	 if (row){
    		 this.to(row);
    	 }else{
    		 row = this.getCurrentItem();
    		 if (!this.exist(row)){
    			 this.first();
    		 }else{
    			 //this.to(this.value[0]);//数据行存在不处理
    		 }
    	 }
     }
     
     checkHasData(){
    	 if(this.getCount()<=0){
    		 /* lzg 2018.10.30屏蔽
    		 setTimeout(()=>{
    			 throw new Error("数据集[id="+this.id+"]无数据，如果允许无数据请设置\"允许无数据\"属性为true");
    		 },1);
    		 */
    	 }
     }
     
  	 isChild(row, rows){
 		let idColumn = this.getIdColumn();
 		if (row && rows && idColumn){
 			let isTree = this.isTree();
 	 		for (let i=0; i<rows.length; i++){
 				if (rows[i] && (rows[i][idColumn]==row[idColumn])){
 					return true;
 				}
 				if (isTree){
 					if (this.isChild(row, this.getChildren(rows[i])||[])){
 						return true;
 					}
 				}
 			}
 		}
 		return false;
 	 }
  	 
     _clear(array){
    	 if (this.isChild(this.getCurrentRow(), array)){
    		 this._currentOB.set(null);
    	 }
    	 this._clsDeleteDatas();
    	 super._clear(array);
     }
     
     destroy(){
     	if(this.valueByMasterDisposer) this.valueByMasterDisposer(); 
    	if(this.currentByMasterDisposer) this.currentByMasterDisposer(); 
    	super.destroy();
     }
     
     getDefaultRow(){
         var ret = null;
         if (this.props && this.props.schema && this.props.schema.items && this.props.schema.items.props){
           ret = {};
		   let _userdata = {};
           for (var k in this.props.schema.items.props){
             if (this.props.schema.items.props.hasOwnProperty(k)){
               ret[k] = getDefaultValue(this.props.schema.items.props[k].type);
			   _userdata[k] = {readonly: true}
             }
           }
		   
		   ret._userdata = _userdata; //支持模拟行只读
         }
         return ret;
     }
     
     _clsDeleteDatas(){
    	 this.deleteDatas.splice(0,this.deleteDatas.length);
     }
     
     check(force){
    	 var ret = super.check(force);
    	 for (var i=0; i<this.slaveDatas.length; i++){
    		 var slaveData = this.slaveDatas[i];
    		 var slaveDataInfo = slaveData.check(force);
    		 if (!slaveDataInfo.valid){
    			 ret.valid = false;
    			 ret.msg.push.apply(ret.msg, slaveDataInfo.msg);
    		 }
    	 }
    	 return ret;
     }
     
     doRefreshAfter(success, options, params){
    	 this._clsDeleteDatas();
    	 super.doRefreshAfter(success, options, params);
     }
     
     init(){
    	this.slaveDatas = []; 
      	this._masterInited = observable(false);
      	this._currentOB = observable(null);
      	this._currentOB.set(this.getDefaultRow());  //模拟假数据
    	this.deleteDatas = observable([]);
    	super.init();

		var func = function() {
			this._bindMaster();
		};

		var self = this;
		if (!this.master || this.master.masterData || !this.master.id) {
		    func.call(self);
		} else {// 有依赖的主data
			this.compPromise(this.master.id).then(function() {
				func.call(self);
			}, function(error) {// data[xid=self.xid]初始化失败，error
				throw new Error("data[id="+self.id+"]初始化失败，" +error);
			});
		}
     }
     
     isValueReadied(){
		 //主从准备好后刺激计算
		 this._masterInited.get();
     }    

 	saveAllData(option){
		option = option || {};
		option.allData = true;
		option.onlySelf = true;
		return this.saveData(option);
	}
	
 	getChildren(parentRow){
 		if(parentRow && this.isTree()){
 			let treeOp = this.getTreeOption();
 			return treeOp && treeOp.children && parentRow[treeOp.children];
 		}
 	}
 	
 	hasChildren(parentRow){
 		var children = this.getChildren(parentRow);
 		if (children && children.length>0){
 			return true;
 		}else{
 			return false;
 		}
 		
 	}
 	
    each(callback,parentRow){
    	 if(isFunction(callback)){
    		 let isTree = this.isTree();
    		 let rows = !parentRow?this.value:(isTree?this.getChildren(parentRow):[]);
    		 if(rows){
    			 for (let i=0,len=rows.length; i<len; i++){
    				 var row = rows[i];
    				 if(false===callback.call(this,{parent:parentRow, row:row, index:i, data:this})) return false;
    				 if(isTree){
    					 if(false===this.each(callback,row)) return false;
    				 }
    			 }
    		 }
    	 }
    }

    eachAll(callback,parentRow){
   	 if(isFunction(callback)){
   		 let isTree = this.isTree();
   		 let rows = !parentRow?this._allvalue:(isTree?this.getChildren(parentRow):[]);
   		 if(rows){
   			 for (let i=0,len=rows.length; i<len; i++){
   				 var row = rows[i];
   				 if(false===callback.call(this,{parent:parentRow, row:row, index:i, data:this})) return false;
   				 if(isTree){
   					 if(false===this.eachAll(callback,row)) return false;
   				 }
   			 }
   		 }
   	 }
   }
     
	_masterFilter(val, row) {
		if (this.master){
			let mdata = this.master.masterData;
			if (mdata && row && row.$data==mdata) {
				let idCol = mdata.getIdColumn();
				let v = row[idCol];
				return val === v;
			}
		}
		return true;
	}
	
	_getMasterCurrentRow(){
		if (this.master){
			let mdata = this.master.masterData;
			if (mdata) {
				return mdata.getCurrentRow();
			}
		}		
	}
    
	_aggregate(type, col, filterCallback, parentRow) {
		filterCallback || (filterCallback=this._getMasterCurrentRow());
			let ret = 0.0, len = 0, min = null, max = null;
			this.eachAll((param)=>{
				let ok = true;
				if(isFunction(filterCallback)){
					ok = filterCallback.call(this,param);
				}else if(filterCallback){
					let masterRow = filterCallback;
					let id = this.master ? param.row[this.master.relation] : null;
					ok = this._masterFilter(id, masterRow);
				}
				if(ok){
					len++;
					if(col){
						let v = param.row[col];
						if(typeof(v)==="number" && !isNaN(v)){
							ret = Number.accAdd(ret, v);
							max = max === null ? v : (max < v ? v : max);
							min = min === null ? v : (min > v ? v : min);
						}else if('avg' === type){
							//忽略没有值的列
							len--;
						} 
					}
				}
			}, parentRow);
			if ('count' === type)
				return len;
			else if ('avg' === type)
				return Number.accDiv(ret, len);
			else if ('sum' === type)
				return ret;
			else if ('min' === type)
				return min;
			else if ('max' === type)
				return max;
		}
	
		label(col) {
			var def = this.getColumnDef(col);
			return def ? (def.label?def.label:col) : ''
		}
		
		col(col){
			return col || "";
		}
		
		count(filterCallback) {
			return this._aggregate("count", null, filterCallback);
		}
		
		avg(col, filterCallback) {
			return this._aggregate("avg", col, filterCallback);
		}
		
		sum(col, filterCallback) {
			return this._aggregate("sum", col, filterCallback);
		}
		
		min(col, filterCallback) {
			return this._aggregate("min", col, filterCallback);
		}
		
		max(col, filterCallback) {
			return this._aggregate("max", col, filterCallback);
		}
     
		showError(info){
			let msg = info.message || '未知错误';
			wx.showToast({duration:3000,title:msg,icon:'none'});
		}
		
		showModalError(info){
			let msg = info.message || '未知错误';
			wx.showModal({
			    showCancel:false,
			    title: '友情提示',
			    content: msg
			  });
		}

		getSlaveData(id){
			if(isArray(this.slaveDatas)){
				let ret;
				for (let data of this.slaveDatas.values()) {
					if(data.id==id){
						ret = data;
						break;
					}
				}
				return ret;
			}
		}

		
     //主从绑定处理
     _bindMaster(){
		if (this.master && !this.master.masterData && this.master.id) {
			this.master.masterData = this.page.comp(this.master.id);
			if (this.master.masterData) {
				this.master.masterData.slaveDatas.push(this);
				var master = this.master.masterData;
				this.valueByMasterComputed = observable(0);
				this.valueByMasterComputedCount = 0;
				this.byMaster = {};
				this._valueItems = [];
				this.__value = computed(()=>{
					//依赖计算项，用于刺激重新计算value，计数器安全考虑，10万后重新计数
					this.valueByMasterComputedCount = this.valueByMasterComputed.get();
					this.valueByMasterComputedCount>=100000 && (this.valueByMasterComputedCount=0);
					//console.log('--------------333', this.id, ' return value:', this._valueItems);
					//进行计算结果拷贝，否则不会刺激后续依赖计算,必须使用observable，toJS时只有observable时才会处理_userdata等属性
					return observable(Array.from(this._valueItems));
				});
				
				this.valueByMasterDisposer = autorun(()=>{
					//查找从数据
					let matchingItems = this._valueItems;
					matchingItems.length>0 && matchingItems.splice(0, matchingItems.length);
					//进行依赖计算的项关联，保证后续变化后能重新计算，master._currentOB：主数据集当前行进入依赖,this.allItems.length:data全部数据进入依赖
					let mCurrentRow = master._currentOB.get();
					let mIDColumn= master.getIdColumn();
					let allItems = this._allvalue;
					let allItemsLength = allItems.length;
					//console.log("-------valueByMaster:",this.id);
					//开始进行计算
					runInAction(()=>{
						if(mCurrentRow){
							//slm 不能用master.getCurrentRowID 否则会多计算依赖
							let mrid = mCurrentRow[mIDColumn];

							let byMaster = this.byMaster[mrid];
							// 根据主加载当前的数据
							if (!byMaster || !byMaster.loaded) {
								//注意：此处只能必须使用setTimeout，否则会把其他不相关依赖进来
								setTimeout(
										()=>{
											this._initData(true,true);
											this.obsCurrentByMaster.set(mrid);
										}
								,1);
								
								if (byMaster){
									byMaster.loaded = true;
								}else{
									this.byMaster[mrid] = {
										loaded : true
									};
								}
							}
							
							for (let i = 0; i < allItemsLength; i++) {
								var current = allItems[i];
								if (current[this.master.relation] === mrid)
									matchingItems.push(current);
							}
							
							//设置状态,设置当前行不加入到计算链
							var crow = null;
							if (byMaster && byMaster.loaded) {
								crow = (!byMaster.current && matchingItems.length > 0)?matchingItems[0]:byMaster.current;
								if (byMaster.offset!==undefined) this.setOffset(byMaster.offset);
								if (byMaster.total!==undefined) this.setTotal(byMaster.total);
								crow && this.to(crow);
							} else if (matchingItems.length > 0){
								crow = matchingItems[0];
								this.to(crow);
							} else this._currentOB.set(null);//没有数据修改当前行为null
						}else{
							this._currentOB.set(null);
							this.setTotal(0);
							this.setOffset(0);
							
							//解决主表没有数据时, 从表组件的promise没有结束导致page的loaded事件不触发的问题
							this.InitDataResolve && this.InitDataResolve();
		        			this.inited();
						}
						//console.log('--------------111', this.id, ' return value:',matchingItems);
					});
					//console.log('--------------222', this.id, ' return value:',matchingItems);
					this.valueByMasterComputed.set(this.valueByMasterComputedCount+1);
				});
				this.setTotal(0);
				
				//根据主数据保存从数据，当前行，分页等状态信息
				this.currentByMasterDisposer = observe(master._currentOB,(newValue, oldValue) => {
					let mCurrentRow = oldValue;
					if(mCurrentRow){
						var rid = this.getRowID(mCurrentRow);
						if (!this.byMaster[rid])
							this.byMaster[rid] = {};
						this.byMaster[rid].current = this.current;
						this.byMaster[rid].offset = this.getOffset();
						this.byMaster[rid].total = this.getTotal();
					}
				});
				
				this._masterInited.set(true);
			}
		}
     }
     
     getTreeOption(){
    	 /*
    	  * {
    	  * children:子数组列
    	  * rootFilter:根数据过滤条件,
    	  * parent:父关系列
    	  * }
    	  */
    	 return this.props.options.treeOption;
     }
     
     isTree(){
    	 let treeOp = this.getTreeOption();
    	 return treeOp && treeOp.isTree;
     }
     
     isLeaf(row){
    	 if (this.isTree()){
    		 var opt = this.getTreeOption();
    		 if (row && opt && opt.leafCol){
    			 return row[opt.leafCol] == 1;
    		 }
    		 return false;
    	 }else{
    		 return true;
    	 }
     }
     
     _initDefinition(){
    	 if (this.props.filters){
    		 for (let name in this.props.filters){
    			 this.setFilter(name, this.props.filters[name]);
    		 }
    	 }
    	 //记录需要级联删除的数据
    	 this.defSlaves = this.props.options.defSlaves;
    	 //创建统计数据
    	 this.defAggCols = this.props.options.defAggCols;
    	 //主从定义
    	 if(this.props.options.master)
    		 this.master = {id:this.props.options.master.id,relation:this.props.options.master.relation};
    	 
    	 if(this.props.options.isMain){
    		 this.isMain = true;
    		 this.page.$mainData = this;
    	 }
    	 
    	 this.primaryColumns = this.props.options.primaryColumns||[this.props.options.idColumn];
    	 
    	 var aggData = {};
    	 if(this.defAggCols){
    		 for(let n in this.defAggCols){
    			 aggData[n] = ""; 
    		 }
    	 }
    	 this._aggOB = observable(aggData);
    	 
    	 this._initColRule();
    	 super._initDefinition();
    	 let verLock = this.getVersionLock();
    	 if(verLock){
    		 this._colRules[verLock.name] && (this._colRules[verLock.name].readonly=true);
    	 }
     }
     
     _initColRule(){
    	 var rules = {};
    	 
    	 var coldefs = this.getColumnDefs();
    	 for (var o in coldefs){
    		 if (coldefs.hasOwnProperty(o)){
    			 rules[o] = {
    					 readonly: false,
    					 required: false,
    					 hidden: false,
    					 unique: false
    			 }
    		 }
    	 }
    	 this._colRules = observable(rules);
     }
     
     getColRuleValue(col, ruleName){
    	 if (col && ruleName){
    		 var colRuleValue = this._colRules[col] && this._colRules[col][ruleName];
    		 if (ruleName === "readonly"){
    			 var dataReadonly = this.getReadonly();
    			 return colRuleValue || dataReadonly;
    		 }else{
    			 return colRuleValue;
    		 }
    	 }else{
    		 return false;
    	 }
     }
     setColRuleValue(col, ruleName, value){
    	 if (col && ruleName && this._colRules && this._colRules[col]){
    		 this._colRules[col][ruleName] = !!value;
    	 }
     }

     _initData(force,append){
 		if(!this.master || force)
 			super._initData(append);
     }
     
     isChanged(options){
     	//记录依赖
		this._dataChangedUUID.get();
		//this.toJson({includeComputed: true, includeState: true});
    	 if(this.deleteDatas.length<=0){
    		 let ret = false;
    		 this.eachAll((p)=>{
    			 let item = p.row;
    			 if (item && (item.getState()===Data.STATE.NEW 
        				 || item.getState()===Data.STATE.EDIT)){
    				 ret = true;
    				 return false;
    			 }
    		 }, options && options.parentRow && this._getChildren(options.parentRow));
    		 //增加从数据感知
    		 ret || (ret = this.isSlaveChanged());
    		 return ret;
    	 }else{
    		 return true;
    	 }
     }
     
     isSlaveChanged(){
		 let ret = false;
    	 //增加从数据感知
		 for(let i=0,len=this.slaveDatas.length;i<len;i++){
			 ret = this.slaveDatas[i].isChanged();
			 if(ret) break;
		 }
		 return ret;
     }
     
     //没有实现filter相关方法，在子类中实现
     buildFilter() {
    	 return '';
     }
	 getFilter(name) {
		 return '';
	 }
	 setFilter(name, filter) {
		 return '';
	 }
     
	 getColumnIDs() {
    	 var items = this._getColumns(); 
    	 var result = null;
    	 for ( var o in items) {
    		 if('_key'!==o)
    			 result = null !== result ? (result + this.delim + o) : o;
    	 }
    	 return result;
     }
     
	 getColumnDefs() {
    	 var items = this._getColumns(); 
    	 var result = {};
    	 for ( var o in items) {
    		 if('_key'!==o)
    			 result[o] = items[o];
    	 }
    	 return result;
     }

	 _getColumns(){
    	 var result = {};
    	 if (this.props.schema && this.props.schema.items && this.props.schema.items.props){
    		 result = this.props.schema.items.props;
    	 }
    	return result;
     }
	
	 existID(id){
		 let row = null;
		 this.each((p)=>{
			if (id == this.getRowID(p.row)){
				row = p.row;
				return false;
			} 
		 });
		 return row!=null;
	 }
	 
	 getRowByID(id){
		 if (id !== undefined && id !== null) {
			 let ret = null;
			 this.each((p)=>{
				if (id == this.getRowID(p.row)){
					ret = p.row;
					return false;
				} 
			 });
			 return ret;
		 }else{
			 return this.getCurrentRow();
		 }
	 }
	 
	 getValue(col, row){
		 if (!col) return undefined;
		 row = row || this.getCurrentRow();
		 if (!row) return undefined;
		 var value = row[col];
		 return value;
	 }
	 
	 setValue(col, value, row){
		 if (!col) return;
		 row = row || this.getCurrentRow();
		 if (!row) return ;
		 row[col] = undefined!==value?value:null;
	 }

	 fieldsValue(row,options){
		row = row || this.getCurrentRow();
		if (!row) return ;
		if(!options) return;
		options = options || {};
		for(var p in options){
			row[p] = undefined!==options[p]?options[p]:null;
		}
	 }
	 
	 getValueByID(col, id){
		 var row = this.getRowByID(id);
		 if(row) return this.getValue(col, row);
	 }
	 
	 setValueByID(col, value, id) {
		 var row = this.getRowByID(id, true);
		 if(row) this.setValue(col, value, row);
	 }
     
     //-------------支持当前行开始------------------------
     getCurrentItem(){
    	 return this.current;
     }
     
     getCurrentRow(){
    	 return this.current;
     }
     
     getCurrentRowState(){
    	 try{
    		 return this.current.getState();
    	 }catch(e){
    		 return Data.STATE.NONE;
    	 }
     }
     
     get current(){
    	 var result = this._currentOB.get();
    	 if (!result){
    		 result = {};  //兼容当前行为空时, 引用报错的问题
    	 }
    	 return result;
     }
     
     get agg(){
    	 return this._aggOB;
     }
     
     exist(row){
    	 let ret = false;
    	 row && this.eachAll((p)=>{
    		 if(p.row===row){
    			 ret = true;
    			 return false;
    		 }
    	 });
    	 return ret;
     }
     
     to(row){
    	 if (typeof row == 'string' || typeof row == 'number'){
    		 row = this.getRowByID(row);
    	 }
    	 //假定数组中一定是object
    	 if ((this.exist(row) || (row == null))
    			 && (row !== this.getCurrentRow())){
    		 var eventData = {
    			source : this,
    			row : row,
    			originalRow : this.getCurrentRow(),
				cancel : false
			 };
    		 this.fireEvent(Data.EVENT_INDEX_CHANGING, eventData);
    		 if (eventData.cancel) return;
    		 this._currentOB.set(row);
    		 this._updateShareDataCurrentIndex();
    		 this.fireEvent(Data.EVENT_INDEX_CHANGED, eventData);
    	 }
     }
     
     getIdColumn(){
    	 return this.props.options.idColumn;
     }
     
     getColumnDef(name){
    	 return this.props.schema.items.props[name];
     }
     
     find(fields, values, First, CaseInsensitive, PartialKey, all){
    	 if(!isArray(fields)){
        	 var result = this._findItemByFilter(this._allvalue, {key: fields, value: values});
        	 return result;
    	 }else if(isArray(values)){
 			var res = [];
			var len = 0;
			if (values && fields)
				len = values.length > fields.length ? fields.length : values.length;
			if(len>0){
				this.each((p)=>{
					let ok = true;
					let r = p.row;
					for (let i = 0; i < len; i++) {
						var v = this.getValue(fields[i], r);
						if (typeof (v) === 'string') {
							v = !CaseInsensitive ? v : v.toLowerCase();
							var value = !CaseInsensitive ? values[i] : (values[i] + '').toLowerCase();
							ok = ok && (!PartialKey ? v == value : v.indexOf(value) != -1);
						} else
							ok = values[i] == v;
						if (!ok)
							break;
					}
					if (ok) {
						res.push(r);
						if (First) return false;
					}
				});
			}
			return res;
    	 }
     }
     
     bof(){
    	 return this.current === this.getFirstRow(); 
     }
     
     eof(){
    	 return this.current === this.getLastRow(); 
     }
     
	 next(){
		let crow = this.getCurrentItem(), isNext = false;
		this.each((p)=>{
			if (isNext) {
				this.to(p.row);
				return false;
			}
			if (p.row == crow)
				isNext = true;
		});
	 }
	 
	 pre(){ 
		let crow = this.getCurrentItem(), preRow = null;
		this.each((p)=>{
			if (p.row == crow) {
				if (null !== preRow)
					this.to(preRow);
				return false;
			}
			preRow = p.row;
		});
	 }
	 
	 first() {
		 this.to(this.getFirstRow());
	 }
	 
	 last() {
		 this.to(this.getLastRow());
	 }
	 
	 getFirstRow() {
		 if (this.value.length > 0)
			 return this.value[0];
		 else
			return null;
	 }
	
	 getLastRow() {
		 if (this.value.length > 0)
			 return this._lastRow(this.value);
		 else
			 return null;
	 }
	 
	_lastRow(rows) {
		if (rows.length > 0){
			var len = rows.length, ret = rows[len - 1], children = this.getChildren(ret);
			if (children && isObservableArray(children) && (children.length>0))
				return this._lastRow(children);
			else
				return ret;
		}else{
			return null;
		}
	 }
	
	 getCurrentRowID(){
		 return this.getRowID(this.getCurrentItem());
	 }
	 
	 getRowID(row){
		 row = row || this.getCurrentItem();
		 if (row){

			 return row[this.getIdColumn()];
		 }else{
			 return null;
		 }
	 }
	 
	 getIDs(){
		let ret = [];
		let idCol = this.getIdColumn();
		for (let j=0; j<this.value.length;	j++){
			let r = this.value[j];
			ret.push(r[idCol]);
		}
		return ret;
	 }
	 
     //-------------支持当前行结束------------------------
	 buildState(context){
		 //主从准备好后刺激计算
		 this._masterInited.get();
		 var state = super.buildState(context);
    	 state.current = cloneJSON(toJS(this._currentOB, true, null, true), true);
    	 state.agg = toJS(this._aggOB, true, null, true);
    	 return state;
     }
     
     loadAllPageData(options){
    	 if(options && options.parentRow){
    		 options.parent = this._getChildren(options.parentRow);
    	 }
    	 return super.loadAllPageData((options && options.parent) || this._allvalue, options);
     }
     
     loadPageData(index, options){
    	 if(options && options.parentRow){
    		 options.parent = this._getChildren(options.parentRow);
    	 }
    	 return super.loadPageData((options && options.parent) || this._allvalue, index, options);
     }
     
     loadNextPageData(options){
    	 if(options && options.parentRow){
    		 options.parent = this._getChildren(options.parentRow);
    	 }
    	 return super.loadNextPageData((options && options.parent) || this._allvalue, options);
     }
     
     refreshData(options){
    	 if(options && options.parentRow){
    		 options.parent = this._getChildren(options.parentRow);
    	 }
    	 return super.refreshData(options);
     }
     
     remove(value, parent, quiet){
    	 parent = parent || this._allvalue;
    	 value = value || this.getCurrentItem();
		 let index = parent.indexOf(value);
		 if(index===-1){
			 let p;
			 this.each((params)=>{
				 if(value===params.row){
					 p = params.parent;
					 return false;
				 }
			 });
			 if(p){
				 parent = this._getChildren(p);
				 index = parent.indexOf(value);
			 }
		 }
    	 super.remove(value, parent);
    	 var isCur = value === this.getCurrentItem();
    	 if(!quiet && isCur){
    		 var size = parent.length;
    		 if (size==0){
    			 this.to(null);
    		 }else if (index < size){
    			 this.to(parent[index]);
    		 }else {
    			 this.to(parent[index-1]);
    		 }
    	 }
     }
     
     _slaveRemove(row){
		let idCol = this.getIdColumn();
		let rowid = row[idCol];
    	
		if(rowid){
			if(isArray(this.slaveDatas)){
				let ret;
				for (let slaveData of this.slaveDatas.values()) {
					let removeRows = [];
					slaveData.each((p)=>{
						let r = p.row;
						let id = slaveData.master ? r[slaveData.master.relation] : null;
						if(rowid==id){
							removeRows.push({row: r, parent: p.parent});
						}
					});
					if(removeRows.length>0){
						for (let r of removeRows.values()) {
							slaveData.remove(r.row, r.parent, true);
						}
					}
				}
			}
		}
     }
     
     row2json(row, all){
		 var option = {};
		 if (typeof all == "object"){
			option = all;	 
		 }else{
		 	option.includeComputed = all;
		 }
    	 var result = {};
    	 if (row){
    		 result = option.includeComputed ? toJS(row, true, null, true) : toJS(row); 
    		 if (result) result = JSON.parse(JSON.stringify(result));
    	 }
    	 
		 if (result && !option.includeComputed){
			delete result._recoredState; 
		 }
		 
    	 if (result && option.excludeCalculateCol){
			for(var col in result){
				var defCol = this.getColumnDef(col);
				if(this.isCalculateCol(defCol)){
					delete result[col];	
				}
			}
		 }
    	 return result;
     }
     
     /*
     _toJSONObj(data){
    	 if (!data) return data;
    	 if (isObservable(data)){
    		 return toJS(data);
    	 }else{
    		 if (data instanceof Array){
    			 var ret = [];
    			 for (let i=0;i < data.length; i++){
    				 ret.push(toJS(data[i]));
    			 }
    			 return ret;
    		 }else{
    			 return data;
    		 }
    	 }
     }
     */
     
     loadFromStorage(key, isNullNew){
    	 let ret = super.loadFromStorage(key);
    	 if(isNullNew && this.getCount()===0){
    		 ret = this.newData();
    	 }
    	 return ret;
     }
     
     //重新处理，parent对应array，parentRow对应树型数据的父
     newData(options){
    	 if(options && options.parentRow){
    		 options.parent = this._getChildren(options.parentRow);
    	 }
    	 return super.newData(options); 
     }
     
 	//新建逻辑
 	doNewData(rows, options) {
 		rows = this.updateIdValue(rows, options);
 		return super.doNewData(rows, options);
 	}
 	
	getPK(){
		let idCols = [];
		let idColumns = this.primaryColumns;
		for(let i=0,len=idColumns.length;i<len;i++){
			let idColumn = idColumns[i];
			let defcol = this.getColumnDef(idColumn);
			idCols.push((defcol && defcol.define) || idColumn);
		}
		return idCols.join(",");
	}
 	
	updateIdValue(rows, options){
		rows = rows || [{}];
		if (!isArray(rows)){
			rows = [rows];
		}
		
		let idColumns = this.primaryColumns;
		if(idColumns && idColumns.length>0){
			for(let j=0,len=idColumns.length;j<len;j++){
				let idColumn = idColumns[j];
				let defcol = this.getColumnDef(idColumn);
				if(defcol && ('string'===defcol.type)){
					for (let i=0; i<rows.length; i++){
						if (!rows[i].hasOwnProperty(idColumn)){
							let uuid = new UUID().toString();
							rows[i][idColumn] = uuid.replace(/-/g,'').toUpperCase();
						}
					}
				}
			}
		}
		
		let versionCol = this.versionLock;
		if(versionCol){
			for (let i=0; i<rows.length; i++){
				if (!rows[i].hasOwnProperty(versionCol)){
					rows[i][versionCol] = 0;
				}
			}
		}
		
		//树形数据的
		if (this.isTree() && options.parentRow) {
			let treeOption = this.getTreeOption();;
			let parentId = this.getRowID(options.parentRow);
			for (let i=0; i<rows.length; i++){
				rows[i][treeOption.parent] = parentId;
			}
		}

		let masterRelation,masterRowId;
		if(this.master && this.master.masterData){
			var masterData = this.master.masterData;
			var masterRow = masterData.getCurrentRow();
			masterRowId = masterData.getRowID(masterRow);
			masterRelation = this.master.relation;
			
			if(masterRelation){
				for (let i=0; i<rows.length; i++){
					rows[i][masterRelation] = masterRowId;
				}
			}
		}
		
		return rows;
	}
     
     getOrderBys() {
    	 return this.getArrayOrderBys(this._allvalue);
     }
     
     getOderBysObj(){
    	 return this._getArrayOrderBys(this._allvalue);
     }
     
     setOrderBy(name, type) {
    	 return this.setArrayOrderBy(this._allvalue, name, type);
     }
     
	 clearOrderBy(){
		 let items = this.getOderBysObj();
		 if(isArray(items)&&items.length>0){
			 for (let i=items.length-1; i>=0; i--){
				 this.setOrderBy(items[i].name);
			 }
		 }
	 }
	 
	 getOrderBy(name){
    	 return this.getArrayOrderBy(this._allvalue, name);
     }
	 
	 isVirtualTree(){
		let ret = false;
		if (this.isTree()){
			let treeOption = this.getTreeOption() || {};
			if ((treeOption.rootMode === VTREE 
				|| treeOption.rootMode===VTREE_ONLY_PARENT
				|| treeOption.rootMode===VTREE_ONLY_CHILD)
				&& treeOption.fullId
				&& treeOption.parent
				&& treeOption.virtualCol){
				ret = true;
			}
		}
		return ret;
	}
	
	buildVirtualNode(data){
		data = data || [];
		let treeOption = this.getTreeOption()||{};
		let sortNodes = this.doSortNode(data, treeOption.fullId);
		let remainNodes = this.doDeleteNodeByRootMode(sortNodes, treeOption);
		let virtualNodes = this.doBuildVirtualNodes(remainNodes, treeOption);
		let ret = [];
		//保留原始的顺序
		for (let i=0; i<data.length; i++){
			let item = data[i];
			if (item && remainNodes[item[treeOption.fullId]]){
				ret.push(item);
			}
		}
		ret.push.apply(ret, virtualNodes);
		return ret;
	}
	
	doBuildVirtualNodes(remainNodes, treeOption){
		let remainKeys = Object.keys(remainNodes);
		let keys = [];
		let ret = [];
		for (let i=0; i<remainKeys.length; i++){
			let node = remainNodes[remainKeys[i]];
			if (node){
				this.doBuildVirtualNode(node, treeOption, keys, remainKeys, ret);
			}
		}
		return ret;
	}
	
	doBuildVirtualNode(node, treeOption, keys, remainKeys, ret){
		let fid = node[treeOption.fullId];
		while(fid){
			fid = fid.substr(0, fid.lastIndexOf("/"));
			if (keys.indexOf(fid) != -1 || !fid){
				break;
			}
			keys.push(fid);
			let virNode = {};
			let fullPaths = treeOption.fullPaths || [];
			for (let i=0; i<fullPaths.length; i++){
				let fullPath = fullPaths[i];
				let value = node[fullPath.name];
				let separator = fullPath.separator||"/";
				if (value && (value.indexOf(separator) != -1)){
					virNode[fullPath.name] = value.substr(0, value.lastIndexOf(separator));
					if (virNode[fullPath.name].indexOf(separator)!= -1){
						virNode[fullPath.from] = virNode[fullPath.name].substr(virNode[fullPath.name].lastIndexOf(separator)+1);
					}else{
						virNode[fullPath.from] = virNode[fullPath.name];
					}
				}else{
					virNode[fullPath.name] = null;
					virNode[fullPath.from] = null;
				}
			}
			virNode[treeOption.virtualCol] = "virtual";
			let fids = fid.split("/");
			let parent = null;
			if (fids.length>1){
				parent = fids[fids.length-2] || null;
			}
			virNode[treeOption.parent] = parent;
			if (remainKeys.indexOf(fid) == -1){
				ret.push(virNode);
			}
			node = virNode;
		}
	}
	
	doDeleteNodeByRootMode(sortNodes, treeOption){
		if (treeOption.rootMode === VTREE_ONLY_PARENT){
			return this.doDeleteChildNode(sortNodes);
		}else if (treeOption.rootMode == VTREE_ONLY_CHILD){
			return this.doDeleteParentNode(sortNodes);
		}else{
			return sortNodes;
		}
	}
	
	doDeleteChildNode(sortNodes){
		let ret = {};
		let keys = Object.keys(sortNodes);
		for (let i=0; i<keys.length; i++){
			let key = keys[i];
			if (this._isChildKey(key, keys)){
				continue;
			}
			ret[key] = sortNodes[key];
		}
		return ret;
	}
	
	_isChildKey(key, keys){
		for (let j=0; j<keys.length; j++){
			let secondKey = keys[j];
			if (key == secondKey) continue;
			if (key.indexOf(secondKey)===0){
				//是子节点时忽略
				return true;
			}
		}
	}
	
	doDeleteParentNode(sortNodes){
		let ret = {};
		let keys = Object.keys(sortNodes);
		for (let i=0; i<keys.length; i++){
			let key = keys[i];
			if (this._isParentKey(key, keys)){
				continue;
			}
			
			ret[key] = sortNodes[key];
		}
		return ret;
	}
	
	_isParentKey(key, keys){
		for (let j=0; j<keys.length; j++){
			let secondKey = keys[j];
			if (key == secondKey) continue;
			if (secondKey.indexOf(key)===0){
				//是子节点时忽略
				return true;
			}
		}
	}
	
	
	doSortNode(data, fullId){
		let ret = {};
		for (let i=0; i<data.length; i++){
			let item = data[i];
			if (item && item[fullId]){
				ret[item[fullId]] = item;
			}
		}
		return ret;
	}
	
	
	 
	 loadAllTreeData(data, useVTree){
		 if (this.isVirtualTree() && useVTree){
			data = this.buildVirtualNode(data);
		 }
		 let treeData = this.processTreeRows(data);
		 this.doLoadAllTreeData(treeData, null);
	 }
	 
	 doLoadAllTreeData(rows, parentRow){
			let parent = parentRow ? this._getChildren(parentRow) : null;
			rows = rows || [];
			
			let count = rows.length;
			this.setTotal(count, parentRow);
			
			let allChildren = [];
			for (let i=0; i<rows.length; i++){
				allChildren[i] = rows[i].$children;
				delete rows[i].$children;
			}
			
			this.loadData(rows, false, parent, undefined);
			
			var idCol = this.getIdColumn();
			for (let i=0; i<rows.length; i++){
				var row = rows[i];
				if (row && row[idCol]){
					var child = this.getRowByID(row[idCol]);
					if (child){
						this.doLoadAllTreeData(allChildren[i], child);						
					}
				}
			}
			this.setArrayLoaded(parent || this._allvalue, rows.length>0);
		
			
			if ((this.getTreeOption()||{}).loadAll){
				this.setArrayLoaded(parent || this._allvalue, true);
			}
			
			
			
			if (isObservableArray(parent)){
				 this.updateCurrent(parent);
				 this.setArrayLimit(parent, -1);
				 this.setArrayOffset(parent, 0);
			}			
		}

	 processTreeRows(rows){
		if (this.isTree() && rows && rows.length>0) {
			let treeOption = this.getTreeOption();
			var pCol = treeOption.parent || "";
			var idCol = this.props.options.idColumn;
			if(pCol && idCol){
				var tempRows = {};
				//建立索引
				for (let i=0; i<rows.length; i++){
					let rid = rows[i][idCol];
					tempRows[rid] = rows[i];
				}
				
				//根据数据构造tree
				for(let i = rows.length-1; i>-1; i--){
					let row = rows[i];
					let rowid = row[idCol];
					let parentid = row[pCol];
					if(parentid!==undefined && parentid!==null && parentid!==""){
						let pRow = tempRows[parentid];
						if(pRow){
							
							let pRows = pRow.$children;
							pRows || (pRow.$children = pRows = []);
							pRows.unshift(row);
							rows.splice(i, 1)
						}
					}
				}					
			}
		}
		return rows;
	}
	 
	 /*
	  * 获取树型数据行的子,如果不存在就创建
	  */
	 _getChildren(parentRow){
		 if(!this.isTree()||!parentRow){
			 return this._allvalue;
		 }else{
			 let rows = this.getChildren(parentRow);
			 if(!rows){
	 			let treeOp = this.getTreeOption();
				let o = {};
				o[treeOp.children] = [];
		 		extendObservable(parentRow,o);
		 		rows = this.getChildren(parentRow);
			 }
			 return rows;
		 }
	 }
	 
     hasMore(parentRow){
   		 return this.hasArrayMore(this._getChildren(parentRow));
     }
     
     isLoaded(parentRow){
    	 return this.isArrayLoaded(this._getChildren(parentRow));
     }
     
     setLoaded(parentRow, loaded){
    	 this.setArrayLoaded(this._getChildren(parentRow),loaded);
     }
     
     getTotal(parentRow){
   		 return this.getArrayTotal(this._getChildren(parentRow));
     }
     
     setTotal(total,parentRow){
   		 this.setArrayTotal(this._getChildren(parentRow), total); 
     }
     
     setLimit(limit,parentRow){
    	 this.setArrayLimit(this._getChildren(parentRow), limit);
     }
     
     getLimit(parentRow){
		 return this.getArrayLimit(this._getChildren(parentRow));
     }
     
     getPageNumber(parentRow){
    	 let limit = this.getLimit(parentRow);
    	 let offset = this.getOffset(parentRow);
    	 if (limit){
    		 return Math.ceil(offset/limit);
    	 }else{
    		 return 0;
    	 }    	 
     }
     
     setOffset(offset,parentRow){
    	 this.setArrayOffset(this._getChildren(parentRow), offset);
     }
     
     getOffset(parentRow){
   		 return this.getArrayOffset(this._getChildren(parentRow));
     }
     
     getCount(parentRow){
		if (!this.isTree())
			return this.value.length;
		else {
			let len = 0;
			this.each((p)=>{
				len++;
			}, parentRow);
			return len;
		}
     }
     
     getRowIndex(row,parentRow){
    	 let rows = null;
    	 if (this.master && this.master.masterData&&!parentRow){
    		 //解决从表分页序号的问题
    		 rows = this.value || [];
    	 }else{
    		 rows = this._getChildren(parentRow);
    	 }
   		 return rows?rows.indexOf(row):-1;
     }
     
     getIndex(row){
    	 if (typeof(row) === 'string'){
    		 row = this.getRowByID(row);
    	 }
    	 if (row){
    		 var limit = this.getLimit(row);
    		 if (limit==-1 || this.getOffset(row)==0){
    			 return this.getRowIndex(row) + 1;
    		 }else{
    			 return this.getOffset(row) - limit + this.getRowIndex(row) + 1;	 
    		 }
    	 }else{
    		 return -1;
    	 }
     }
     
     
     exchangeRow(row1,row2,parentRow){
    	 let rows = this._getChildren(parentRow);
    	 if(rows){
    		 let index1 = rows.indexOf(row1);
    		 if(index1<0) return;
    		 let index2 = rows.indexOf(row2);
    		 if(index2<0) return;
    		 [rows[index1],rows[index2]] = [rows[index2],rows[index1]];
    		 //rows.splice(index1,1,row2);
    		 //rows.splice(index2,1,row1);
    	 }
     }
     
     deleteData(rows, options){
    	 rows = rows || this.getCurrentRow();
    	 if (!isArray(rows)){
    		 rows = [rows];
    	 }
    	 var newRows = [];
    	 for (let i=0; i<rows.length; i++){
    		 if (rows[i] && !isObservableObject(rows[i]) && !isObservableArray(rows[i])){
    			 var curRow = this.getRowByID(rows[i]);
    			 if (curRow){
    				 newRows.push(curRow);
    			 }
    		 }else{
    			 newRows.push(rows[i]);
    		 }
    	 }

    	 if(options && options.parentRow){
    		 options.parent = this._getChildren(options.parentRow);
    	 }    	 
    	 return super.deleteData(newRows, options);
     }
     
     
     _copyVars(vars){
     	var result = {};
     	for (var key in vars){
     		result[key] = vars[key];
     	}
     	return result;
     }

     canDeleteRow(row){
    	 let fn = this.props['deleteConstraint'];
    	 if(fn){
    		 if (typeof fn === "function"){
    			 return fn(row);
    		 }else{
    			 let newVars = this._copyVars(this.context.vars);
        		 newVars['$row'] = row;
        		 return this.page[fn](newVars); 
    		 }
    		 
    	 }else{
    		 return true;
    	 }
     }
     
     getCanNotDeleteRowMsg(row){
    	 let fn = this.props['deleteConstraintMsg'];
    	 if(fn){
    		 if (typeof fn == "function"){
    			 return fn(row) || "不满足删除条件";
    		 }else{
        		 let newVars = this._copyVars(this.context.vars);
        		 newVars['$row'] = row;
        		 return this.page[fn](newVars)||"不满足删除条件"; 
    		 }
    	 }    	 
     }

     checkDeleteRows(rows){
    	 let ret = {rows:[], msg:[]};
		 for(let i=0,len=rows.length;i<len;i++){
			 let row = rows[i];
			 if(this.canDeleteRow(row)){
				 ret.rows.push(row);
			 }else{
				 ret.msg.push(this.getCanNotDeleteRowMsg(row));
			 }
		 }
    	 return ret;
     }
     
     hasChildren(row){
  	   let ret = false;
  	   if(this.isTree() && row){
  		   let treeOp = this.getTreeOption();
  		   let childrenColumnName = treeOp.children;
  		   let children = row[childrenColumnName];
  		   if(children && children.length>0){
  			   ret = true;
  		   }
  	   }
  	   return ret;
     }
     
     doDeleteData(rows, options){
    	 if(!options || !options.force){
    		 let checkRet = this.checkDeleteRows(rows);
    		 if(checkRet.msg.length>0){
    			 wx.showModal({
    				 showCancel:false,
    				 title: '友情提示',
    				 content: checkRet.msg.join("; ")
    			 });
    		 }
    		 rows = checkRet.rows;
    	 }
    	 if(rows.length>0){
    		 if(this.directDeleteMode){
    			 return this.doDirectDeleteData(rows, options);
    		 }else{
    			 for(let i=0,len=rows.length;i<len;i++){
    				 let row = rows[i];
    				 this.remove(row, options && options.parent);
    				 row && this.deleteDatas.push(row);
    			 }
    			 return {async: true, success: true};
    		 }
    	 }else{
    		 return {async: true, success: false};
    	 }
     }
     
     initOperation(){
    	 super.initOperation();
    	 
    	 this.defineOperation('clear', {
    		 label : "清空",
    		 icon : 'icon-minus',
    		 init : function() {
    		 },
    		 method : function(args) {
    			 return this.owner.clear();
    		 }
    	 });
    	 
    	 this.defineOperation('save', {
    		 label : "保存",
    		 icon : 'glyphicon glyphicon-floppy-disk',
    		 init : function() {
    			 /*var op = this, data = this.owner, canSave = function() {
    				 op.setEnable(!data.getReadonly() && data.isChanged());
    			 };
    			 this.owner.on(Data.EVENT_DATA_CHANGE, canSave);
    			 this.owner.on(Data.EVENT_SAVEDATA_AFTER, canSave);
    			 this.owner.on(Data.EVENT_INDEX_CHANGED, canSave);*/
    		 },
    		 method : function(args,ctx) {
    			 let batch = Operational.getBatch(ctx);
    			 let ret = this.owner.saveData({batch:batch});
    			 return !batch?ret:null;
    		 }
    	 });
    	 
    	 this.defineOperation('delete', {
    		    label: "删除",
    		    icon: 'icon-minus',
    		    init: function(){
    				/*var op = this, data = this.owner, canDel = function() {
    					setTimeout(function(){
    						op.setEnable(!data.getReadonly() && data.getCount() > 0 && !!data.getCurrentItem(true));
    					},1);
    				};
    				this.owner.on(Data.EVENT_DATA_CHANGE, canDel);
    				this.owner.on(Data.EVENT_INDEX_CHANGED, canDel);*/
    		    },
    			argsDef : [ {
    				name : 'rows',
    				displayName : "删除的数据"
    			} ],
    			method : function(args,ctx) {
       			 	let batch = Operational.getBatch(ctx);
    				let ret = this.owner.deleteData(args.rows,{batch:batch});
    				return !batch?ret:null;
    			}
    	});
    	 
    	 this.defineOperation('deleteAll', {
 		    label: "删除全部",
 		    icon: 'icon-minus',
 		    init: function(){
 				/*var op = this, data = this.owner, canDel = function() {
 					setTimeout(function(){
 						op.setEnable(!data.getReadonly() && data.getCount() > 0);
 					},1);
 				};
 				this.owner.on(Data.EVENT_DATA_CHANGE, canDel);*/
 		    },
 			argsDef : [{
				name : 'force',
				displayName : '禁止提示'
			},{
				name : 'confirmText',
				displayName : '删除提示'
			}],
 			method : function(args,ctx) {
   			 	let batch = Operational.getBatch(ctx);
				let option = {batch: batch};
				option.confirm = typeof(args.force)==='string'?('true'!==args.force):!args.force;
				option.confirmText = args.confirmText;
 				let ret = this.owner.deleteAllData(option);
				return !batch?ret:null;
 			}
    	 });

    	 this.defineOperation('new', {
			label : "新建",
			icon : 'icon-plus',
			init : function() {
				var op = this, data = this.owner, canNew = function() {
					setTimeout(function(){
						op.setEnable(!data.getReadonly());
					},1);
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, canNew);
				this.owner.on(Data.EVENT_INDEX_CHANGED, canNew);
			},
			argsDef : [ {
				name : 'defaultValues',
				displayName : "默认值"
			},
			{
				name : 'index',
				displayName : "序号"
			}],
			method : function(args) {
				return this.owner.newData(args);
			}
		});
    	
    	this.defineOperation('refresh', {
			label : "刷新",
			icon : 'icon-refresh',
			argsDef : [ {
				name : 'force',
				displayName : '禁止提示'
			} ],
			method : function(args) {
				var option = {};
				option.confirm = typeof(args.force)==='string'?('true'!==args.force):!args.force;
				return this.owner.refreshData(option);
			}
		});
    	
    	this.defineOperation('firstRow', {
			label : "第一行",
			icon : 'icon-chevron-left',
			init : function() {
				/*var op = this, data = this.owner, can = function() {
					var len = data.getCount();
					op.setEnable(len > 1 && data.getFirstRow()!==data.getCurrentItem(true));
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
				this.owner.on(Data.EVENT_INDEX_CHANGED, can);*/
			},
			method : function() {
				return this.owner.first();
			}
		});
    	
    	this.defineOperation('prevRow', {
			label : "前一行",
			icon : 'icon-chevron-left',
			init : function() {
				var op = this, data = this.owner, can = function() {
					var len = data.getCount();
					op.setEnable(len > 1 && data.getFirstRow()!==data.getCurrentItem(true));
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
				this.owner.on(Data.EVENT_INDEX_CHANGED, can);
			},
			method : function() {
				return this.owner.pre();
			}
		});
    	
    	this.defineOperation('nextRow', {
			label : "下一行",
			icon : 'icon-chevron-right',
			init : function() {
				var op = this, data = this.owner, can = function() {
					var len = data.getCount();
					op.setEnable(len > 1 && data.getLastRow()!==data.getCurrentItem(true));
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
				this.owner.on(Data.EVENT_INDEX_CHANGED, can);
			},
			method : function() {
				return this.owner.next();
			}
		});
    	
    	this.defineOperation('lastRow', {
			label : "最后一行",
			icon : 'icon-chevron-right',
			init : function() {
				var op = this, data = this.owner, can = function() {
					var len = data.getCount();
					op.setEnable(len > 1 && data.getLastRow()!==data.getCurrentItem(true));
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
				this.owner.on(Data.EVENT_INDEX_CHANGED, can);
			},
			method : function() {
				return this.owner.last();
			}
		});
    	
    	this.defineOperation('loadPage', {
			label : "加载页",
			icon : '',
			argsDef : [ {
				name : 'pageIndex',
				displayName : "页"
			} ],
			method : function(args) {
				var data = this.owner;				
				var pageIndex = args.pageIndex-0;
				(isNaN(pageIndex)||'number'!=typeof(pageIndex))&&(pageIndex = 1);
				return data.loadPageData(pageIndex);
			}
		});
    	
    	this.defineOperation('firstPage', {
			label : "第一页",
			icon : 'icon-chevron-left',
			init : function() {
				var op = this, data = this.owner, can = function() {
					op.setEnable(data.getLimit()!=-1 && data.getOffset()>data.getLimit());
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
			},
			method : function(args) {
				return this.owner.loadPageData(1);
			}
		});
    	
    	this.defineOperation('prevPage', {
			label : "上页",
			icon : 'icon-chevron-left',
			init : function() {
				var op = this, data = this.owner, can = function() {
					op.setEnable(data.getLimit()!=-1 && data.getOffset()>data.getLimit());
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
			},
			method : function(args) {
				var data = this.owner;
				var pageIndex = data.getOffset()/data.getLimit() - 1;
				return data.loadPageData(pageIndex);
			}
		});
    	
    	this.defineOperation('nextPage', {
			label : "下页",
			icon : 'icon-chevron-right',
			init : function() {
				var op = this, data = this.owner, can = function() {
					op.setEnable(data.getLimit()!=-1 && data.getOffset()<=data.getTotal());
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
			},
			method : function(args) {
				var data = this.owner;
				var pageIndex = data.getOffset()/data.getLimit() + 1;
				return data.loadPageData(pageIndex);
			}
		});
    	 
    	this.defineOperation('lastPage', {
			label : "最后页",
			icon : 'icon-chevron-right',
			init : function() {
				var op = this, data = this.owner, can = function() {
					op.setEnable(data.getLimit()!=-1 && data.getOffset()<=data.getTotal());
				};
				this.owner.on(Data.EVENT_DATA_CHANGE, can);
			},
			method : function(args) {
				var data = this.owner,mod=data.getTotal()%data.getLimit();
				var pageIndex = Math.round(data.getTotal()/data.getLimit()-0.5) + (mod===0?0:1);
				return data.loadPageData(pageIndex);
			}
		});
    	
    	this.defineOperation('loadNextPage', {
			label : "下页",
			icon : 'icon-chevron-right',
			method : function() {
				return this.owner.loadNextPageData();
			}
		});
    	
    	this.defineOperation('loadAllPage', {
			label : "全部",
			icon : 'icon-chevron-right',
			method : function() {
				return this.owner.loadAllPageData();
			}
    	});
    	
    	this.defineOperation('saveToStorage', {
			label : "保存到Storage",
			argsDef : [ {
				name : 'key',
				displayName : '键值'
			} ],
			icon : '',
			method : function(args) {
				return this.owner.saveToStorage(args.key);
			}
    	});
    	
    	this.defineOperation('loadFromStorage', {
			label : "从Storage加载",
			argsDef : [ {
				name : 'key',
				displayName : '键值'
			},{
				name : 'isNullNew',
				displayName : '为空新增'
			} ],
			icon : '',
			method : function(args) {
				return this.owner.loadFromStorage(args.key,args.isNullNew);
			}
    	});
    	
    	this.defineOperation('removeStorage', {
			label : "删除Storage",
			argsDef : [ {
				name : 'key',
				displayName : '键值'
			} ],
			icon : '',
			method : function(args) {
				return this.owner.removeStorage(args.key);
			}
    	});
    	
     }
     
}

TableData.getMainData = function(page){
	return page?page.$mainData:null; 
};

wx.comp = wx.comp || {};
wx.comp.TableData = TableData;

