(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.mobx = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
    (function (global){
      "use strict";
      var __extends = (this && this.__extends) || function (d, b) {
        for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
      };
      registerGlobals();
      exports.extras = {
        allowStateChanges: allowStateChanges,
        getAtom: getAtom,
        getDebugName: getDebugName,
        getDependencyTree: getDependencyTree,
        getObserverTree: getObserverTree,
        isComputingDerivation: isComputingDerivation,
        isSpyEnabled: isSpyEnabled,
        resetGlobalState: resetGlobalState,
        spyReport: spyReport,
        spyReportEnd: spyReportEnd,
        spyReportStart: spyReportStart,
        trackTransitions: trackTransitions
      };
      exports._ = {
        getAdministration: getAdministration,
        resetGlobalState: resetGlobalState
      };
      if (typeof __MOBX_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        __MOBX_DEVTOOLS_GLOBAL_HOOK__.injectMobx(module.exports);
      }
      var actionFieldDecorator = createClassPropertyDecorator(function (target, key, value, args, originalDescriptor) {
        var actionName = (args && args.length === 1) ? args[0] : (value.name || key || "<unnamed action>");
        var wrappedAction = action(actionName, value);
        addHiddenProp(target, key, wrappedAction);
      }, function (key) {
        return this[key];
      }, function () {
        invariant(false, "It is not allowed to assign new values to @action fields");
      }, false, true);
      function action(arg1, arg2, arg3, arg4) {
        if (arguments.length === 1 && typeof arg1 === "function")
          return createAction(arg1.name || "<unnamed action>", arg1);
        if (arguments.length === 2 && typeof arg2 === "function")
          return createAction(arg1, arg2);
        if (arguments.length === 1 && typeof arg1 === "string")
          return namedActionDecorator(arg1);
        return namedActionDecorator(arg2).apply(null, arguments);
      }
      exports.action = action;
      function namedActionDecorator(name) {
        return function (target, prop, descriptor) {
          if (descriptor && typeof descriptor.value === "function") {
            descriptor.value = createAction(name, descriptor.value);
            descriptor.enumerable = false;
            descriptor.configurable = true;
            return descriptor;
          }
          return actionFieldDecorator(name).apply(this, arguments);
        };
      }
      function runInAction(arg1, arg2, arg3) {
        var actionName = typeof arg1 === "string" ? arg1 : arg1.name || "<unnamed action>";
        var fn = typeof arg1 === "function" ? arg1 : arg2;
        var scope = typeof arg1 === "function" ? arg2 : arg3;
        invariant(typeof fn === "function", "`runInAction` expects a function");
        invariant(fn.length === 0, "`runInAction` expects a function without arguments");
        invariant(typeof actionName === "string" && actionName.length > 0, "actions should have valid names, got: '" + actionName + "'");
        return executeAction(actionName, fn, scope, undefined);
      }
      exports.runInAction = runInAction;
      function isAction(thing) {
        return typeof thing === "function" && thing.isMobxAction === true;
      }
      exports.isAction = isAction;
      function autorun(arg1, arg2, arg3) {
        var name, view, scope;
        if (typeof arg1 === "string") {
          name = arg1;
          view = arg2;
          scope = arg3;
        }
        else if (typeof arg1 === "function") {
          name = arg1.name || ("Autorun@" + getNextId());
          view = arg1;
          scope = arg2;
        }
        assertUnwrapped(view, "autorun methods cannot have modifiers");
        invariant(typeof view === "function", "autorun expects a function");
        invariant(isAction(view) === false, "Warning: attempted to pass an action to autorun. Actions are untracked and will not trigger on state changes. Use `reaction` or wrap only your state modification code in an action.");
        if (scope)
          view = view.bind(scope);
        var reaction = new Reaction(name, function () {
          this.track(reactionRunner);
        });
        function reactionRunner() {
          view(reaction);
        }
        reaction.schedule();
        return reaction.getDisposer();
      }
      exports.autorun = autorun;
      function when(arg1, arg2, arg3, arg4) {
        var name, predicate, effect, scope;
        if (typeof arg1 === "string") {
          name = arg1;
          predicate = arg2;
          effect = arg3;
          scope = arg4;
        }
        else if (typeof arg1 === "function") {
          name = ("When@" + getNextId());
          predicate = arg1;
          effect = arg2;
          scope = arg3;
        }
        var disposer = autorun(name, function (r) {
          if (predicate.call(scope)) {
            r.dispose();
            var prevUntracked = untrackedStart();
            effect.call(scope);
            untrackedEnd(prevUntracked);
          }
        });
        return disposer;
      }
      exports.when = when;
      function autorunUntil(predicate, effect, scope) {
        deprecated("`autorunUntil` is deprecated, please use `when`.");
        return when.apply(null, arguments);
      }
      exports.autorunUntil = autorunUntil;
      function autorunAsync(arg1, arg2, arg3, arg4) {
        var name, func, delay, scope;
        if (typeof arg1 === "string") {
          name = arg1;
          func = arg2;
          delay = arg3;
          scope = arg4;
        }
        else if (typeof arg1 === "function") {
          name = arg1.name || ("AutorunAsync@" + getNextId());
          func = arg1;
          delay = arg2;
          scope = arg3;
        }
        invariant(isAction(func) === false, "Warning: attempted to pass an action to autorunAsync. Actions are untracked and will not trigger on state changes. Use `reaction` or wrap only your state modification code in an action.");
        if (delay === void 0)
          delay = 1;
        if (scope)
          func = func.bind(scope);
        var isScheduled = false;
        var r = new Reaction(name, function () {
          if (!isScheduled) {
            isScheduled = true;
            setTimeout(function () {
              isScheduled = false;
              if (!r.isDisposed)
                r.track(reactionRunner);
            }, delay);
          }
        });
        function reactionRunner() { func(r); }
        r.schedule();
        return r.getDisposer();
      }
      exports.autorunAsync = autorunAsync;
      function reaction(arg1, arg2, arg3, arg4, arg5, arg6) {
        var name, expression, effect, fireImmediately, delay, scope;
        if (typeof arg1 === "string") {
          name = arg1;
          expression = arg2;
          effect = arg3;
          fireImmediately = arg4;
          delay = arg5;
          scope = arg6;
        }
        else {
          name = arg1.name || arg2.name || ("Reaction@" + getNextId());
          expression = arg1;
          effect = arg2;
          fireImmediately = arg3;
          delay = arg4;
          scope = arg5;
        }
        if (fireImmediately === void 0)
          fireImmediately = false;
        if (delay === void 0)
          delay = 0;
        var _a = getValueModeFromValue(expression, ValueMode.Reference), valueMode = _a[0], unwrappedExpression = _a[1];
        var compareStructural = valueMode === ValueMode.Structure;
        if (scope) {
          unwrappedExpression = unwrappedExpression.bind(scope);
          effect = action(name, effect.bind(scope));
        }
        var firstTime = true;
        var isScheduled = false;
        var nextValue = undefined;
        var r = new Reaction(name, function () {
          if (delay < 1) {
            reactionRunner();
          }
          else if (!isScheduled) {
            isScheduled = true;
            setTimeout(function () {
              isScheduled = false;
              reactionRunner();
            }, delay);
          }
        });
        function reactionRunner() {
          if (r.isDisposed)
            return;
          var changed = false;
          r.track(function () {
            var v = unwrappedExpression(r);
            changed = valueDidChange(compareStructural, nextValue, v);
            nextValue = v;
          });
          if (firstTime && fireImmediately)
            effect(nextValue, r);
          if (!firstTime && changed === true)
            effect(nextValue, r);
          if (firstTime)
            firstTime = false;
        }
        r.schedule();
        return r.getDisposer();
      }
      exports.reaction = reaction;
      var computedDecorator = createClassPropertyDecorator(function (target, name, _, decoratorArgs, originalDescriptor) {
        invariant(typeof originalDescriptor !== "undefined", "@computed can only be used on getter functions, like: '@computed get myProps() { return ...; }'. It looks like it was used on a property.");
        var baseValue = originalDescriptor.get;
        var setter = originalDescriptor.set;
        invariant(typeof baseValue === "function", "@computed can only be used on getter functions, like: '@computed get myProps() { return ...; }'");
        var compareStructural = false;
        if (decoratorArgs && decoratorArgs.length === 1 && decoratorArgs[0].asStructure === true)
          compareStructural = true;
        var adm = asObservableObject(target, undefined, ValueMode.Recursive);
        defineObservableProperty(adm, name, compareStructural ? asStructure(baseValue) : baseValue, false, setter);
      }, function (name) {
        var observable = this.$mobx.values[name];
        if (observable === undefined)
          return undefined;
        return observable.get();
      }, function (name, value) {
        this.$mobx.values[name].set(value);
      }, false, true);
      function computed(targetOrExpr, keyOrScopeOrSetter, baseDescriptor, options) {
        if (typeof targetOrExpr === "function" && arguments.length < 3) {
          if (typeof keyOrScopeOrSetter === "function")
            return computedExpr(targetOrExpr, keyOrScopeOrSetter, undefined);
          else
            return computedExpr(targetOrExpr, undefined, keyOrScopeOrSetter);
        }
        return computedDecorator.apply(null, arguments);
      }
      exports.computed = computed;
      function computedExpr(expr, setter, scope) {
        var _a = getValueModeFromValue(expr, ValueMode.Recursive), mode = _a[0], value = _a[1];
        return new ComputedValue(value, scope, mode === ValueMode.Structure, value.name, setter);
      }
      function createTransformer(transformer, onCleanup) {
        invariant(typeof transformer === "function" && transformer.length === 1, "createTransformer expects a function that accepts one argument");
        var objectCache = {};
        var resetId = globalState.resetId;
        var Transformer = (function (_super) {
          __extends(Transformer, _super);
          function Transformer(sourceIdentifier, sourceObject) {
            _super.call(this, function () { return transformer(sourceObject); }, null, false, "Transformer-" + transformer.name + "-" + sourceIdentifier, undefined);
            this.sourceIdentifier = sourceIdentifier;
            this.sourceObject = sourceObject;
          }
          Transformer.prototype.onBecomeUnobserved = function () {
            var lastValue = this.value;
            _super.prototype.onBecomeUnobserved.call(this);
            delete objectCache[this.sourceIdentifier];
            if (onCleanup)
              onCleanup(lastValue, this.sourceObject);
          };
          return Transformer;
        }(ComputedValue));
        return function (object) {
          if (resetId !== globalState.resetId) {
            objectCache = {};
            resetId = globalState.resetId;
          }
          var identifier = getMemoizationId(object);
          var reactiveTransformer = objectCache[identifier];
          if (reactiveTransformer)
            return reactiveTransformer.get();
          reactiveTransformer = objectCache[identifier] = new Transformer(identifier, object);
          return reactiveTransformer.get();
        };
      }
      exports.createTransformer = createTransformer;
      function getMemoizationId(object) {
        if (object === null || typeof object !== "object")
          throw new Error("[mobx] transform expected some kind of object, got: " + object);
        var tid = object.$transformId;
        if (tid === undefined) {
          tid = getNextId();
          addHiddenProp(object, "$transformId", tid);
        }
        return tid;
      }
      function expr(expr, scope) {
        if (!isComputingDerivation())
          console.warn("[mobx.expr] 'expr' should only be used inside other reactive functions.");
        return computed(expr, scope).get();
      }
      exports.expr = expr;
      function extendObservable(target) {
        var properties = [];
        for (var _i = 1; _i < arguments.length; _i++) {
          properties[_i - 1] = arguments[_i];
        }
        invariant(arguments.length >= 2, "extendObservable expected 2 or more arguments");
        invariant(typeof target === "object", "extendObservable expects an object as first argument");
        invariant(!(isObservableMap(target)), "extendObservable should not be used on maps, use map.merge instead");
        properties.forEach(function (propSet) {
          invariant(typeof propSet === "object", "all arguments of extendObservable should be objects");
          invariant(!isObservable(propSet), "extending an object with another observable (object) is not supported. Please construct an explicit propertymap, using `toJS` if need. See issue #540");
          extendObservableHelper(target, propSet, ValueMode.Recursive, null);
        });
        return target;
      }
      exports.extendObservable = extendObservable;
      function extendObservableHelper(target, properties, mode, name) {
        var adm = asObservableObject(target, name, mode);
        for (var key in properties)
          if (hasOwnProperty(properties, key)) {
            if (target === properties && !isPropertyConfigurable(target, key))
              continue;
            var descriptor = Object.getOwnPropertyDescriptor(properties, key);
            setObservableObjectInstanceProperty(adm, key, descriptor);
          }
        return target;
      }
      function getDependencyTree(thing, property) {
        return nodeToDependencyTree(getAtom(thing, property));
      }
      function nodeToDependencyTree(node) {
        var result = {
          name: node.name
        };
        if (node.observing && node.observing.length > 0)
          result.dependencies = unique(node.observing).map(nodeToDependencyTree);
        return result;
      }
      function getObserverTree(thing, property) {
        return nodeToObserverTree(getAtom(thing, property));
      }
      function nodeToObserverTree(node) {
        var result = {
          name: node.name
        };
        if (hasObservers(node))
          result.observers = getObservers(node).map(nodeToObserverTree);
        return result;
      }
      function intercept(thing, propOrHandler, handler) {
        if (typeof handler === "function")
          return interceptProperty(thing, propOrHandler, handler);
        else
          return interceptInterceptable(thing, propOrHandler);
      }
      exports.intercept = intercept;
      function interceptInterceptable(thing, handler) {
        if (isPlainObject(thing) && !isObservableObject(thing)) {
          deprecated("Passing plain objects to intercept / observe is deprecated and will be removed in 3.0");
          return getAdministration(observable(thing)).intercept(handler);
        }
        return getAdministration(thing).intercept(handler);
      }
      function interceptProperty(thing, property, handler) {
        if (isPlainObject(thing) && !isObservableObject(thing)) {
          deprecated("Passing plain objects to intercept / observe is deprecated and will be removed in 3.0");
          extendObservable(thing, {
            property: thing[property]
          });
          return interceptProperty(thing, property, handler);
        }
        return getAdministration(thing, property).intercept(handler);
      }
      function isComputed(value, property) {
        if (value === null || value === undefined)
          return false;
        if (property !== undefined) {
          if (isObservableObject(value) === false)
            return false;
          var atom = getAtom(value, property);
          return isComputedValue(atom);
        }
        return isComputedValue(value);
      }
      exports.isComputed = isComputed;
      function isObservable(value, property) {
        if (value === null || value === undefined)
          return false;
        if (property !== undefined) {
          if (isObservableArray(value) || isObservableMap(value))
            throw new Error("[mobx.isObservable] isObservable(object, propertyName) is not supported for arrays and maps. Use map.has or array.length instead.");
          else if (isObservableObject(value)) {
            var o = value.$mobx;
            return o.values && !!o.values[property];
          }
          return false;
        }
        return !!value.$mobx || isAtom(value) || isReaction(value) || isComputedValue(value);
      }
      exports.isObservable = isObservable;
      var decoratorImpl = createClassPropertyDecorator(function (target, name, baseValue) {
        var prevA = allowStateChangesStart(true);
        if (typeof baseValue === "function")
          baseValue = asReference(baseValue);
        var adm = asObservableObject(target, undefined, ValueMode.Recursive);
        defineObservableProperty(adm, name, baseValue, true, undefined);
        allowStateChangesEnd(prevA);
      }, function (name) {
        var observable = this.$mobx.values[name];
        if (observable === undefined)
          return undefined;
        return observable.get();
      }, function (name, value) {
        setPropertyValue(this, name, value);
      }, true, false);
      function observableDecorator(target, key, baseDescriptor) {
        invariant(arguments.length >= 2 && arguments.length <= 3, "Illegal decorator config", key);
        assertPropertyConfigurable(target, key);
        invariant(!baseDescriptor || !baseDescriptor.get, "@observable can not be used on getters, use @computed instead");
        return decoratorImpl.apply(null, arguments);
      }
      function observable(v, keyOrScope) {
        if (v === void 0) { v = undefined; }
        if (typeof arguments[1] === "string")
          return observableDecorator.apply(null, arguments);
        invariant(arguments.length < 3, "observable expects zero, one or two arguments");
        if (isObservable(v))
          return v;
        var _a = getValueModeFromValue(v, ValueMode.Recursive), mode = _a[0], value = _a[1];
        var sourceType = mode === ValueMode.Reference ? ValueType.Reference : getTypeOfValue(value);
        switch (sourceType) {
          case ValueType.Array:
          case ValueType.PlainObject:
            return makeChildObservable(value, mode);
          case ValueType.Reference:
          case ValueType.ComplexObject:
            return new ObservableValue(value, mode);
          case ValueType.ComplexFunction:
            throw new Error("[mobx.observable] To be able to make a function reactive it should not have arguments. If you need an observable reference to a function, use `observable(asReference(f))`");
          case ValueType.ViewFunction:
            deprecated("Use `computed(expr)` instead of `observable(expr)`");
            return computed(v, keyOrScope);
        }
        invariant(false, "Illegal State");
      }
      exports.observable = observable;
      var ValueType;
      (function (ValueType) {
        ValueType[ValueType["Reference"] = 0] = "Reference";
        ValueType[ValueType["PlainObject"] = 1] = "PlainObject";
        ValueType[ValueType["ComplexObject"] = 2] = "ComplexObject";
        ValueType[ValueType["Array"] = 3] = "Array";
        ValueType[ValueType["ViewFunction"] = 4] = "ViewFunction";
        ValueType[ValueType["ComplexFunction"] = 5] = "ComplexFunction";
      })(ValueType || (ValueType = {}));
      function getTypeOfValue(value) {
        if (value === null || value === undefined)
          return ValueType.Reference;
        if (typeof value === "function")
          return value.length ? ValueType.ComplexFunction : ValueType.ViewFunction;
        if (isArrayLike(value))
          return ValueType.Array;
        if (typeof value === "object")
          return isPlainObject(value) ? ValueType.PlainObject : ValueType.ComplexObject;
        return ValueType.Reference;
      }
      function observe(thing, propOrCb, cbOrFire, fireImmediately) {
        if (typeof cbOrFire === "function")
          return observeObservableProperty(thing, propOrCb, cbOrFire, fireImmediately);
        else
          return observeObservable(thing, propOrCb, cbOrFire);
      }
      exports.observe = observe;
      function observeObservable(thing, listener, fireImmediately) {
        if (isPlainObject(thing) && !isObservableObject(thing)) {
          deprecated("Passing plain objects to intercept / observe is deprecated and will be removed in 3.0");
          return getAdministration(observable(thing)).observe(listener, fireImmediately);
        }
        return getAdministration(thing).observe(listener, fireImmediately);
      }
      function observeObservableProperty(thing, property, listener, fireImmediately) {
        if (isPlainObject(thing) && !isObservableObject(thing)) {
          deprecated("Passing plain objects to intercept / observe is deprecated and will be removed in 3.0");
          extendObservable(thing, {
            property: thing[property]
          });
          return observeObservableProperty(thing, property, listener, fireImmediately);
        }
        return getAdministration(thing, property).observe(listener, fireImmediately);
      }

//----------------
//toJS支持是否获取计算属性的开关, 2016.12.22, hcr
      function __getObjProps(source, includeComputed){
        var result = [];
        if (includeComputed && source && source.$mobx && source.$mobx.values){
          for (var key in source.$mobx.values){
            if (!includeComputed && isComputedValue(source.$mobx.values[key])){
              //忽略计算属性
            }else{
              result.push(key);
            }
          }
        }else{
          for (var key in source)
            result.push(key);
        }

        return result;
      }
//----------------
//TODO hcr. includeComputed：表示用于展现
      function toJS(source, detectCycles, __alreadySeen, includeComputed) {
        if (detectCycles === void 0) { detectCycles = true; }
        if (__alreadySeen === void 0) { __alreadySeen = null; }
        function cache(value) {
          if (detectCycles)
            __alreadySeen.push([source, value]);
          return value;
        }
        if (isObservable(source)) {
          if (detectCycles && __alreadySeen === null)
            __alreadySeen = [];
          if (detectCycles && source !== null && typeof source === "object") {
            for (var i = 0, l = __alreadySeen.length; i < l; i++)
              if (__alreadySeen[i][0] === source)
                return __alreadySeen[i][1];
          }
          if (isObservableArray(source)) {
            var res = cache([]);
            var toAdd = source.map(function (value) { return toJS(value, detectCycles, __alreadySeen, includeComputed); });
            res.length = toAdd.length;
            for (var i = 0, l = toAdd.length; i < l; i++)
              res[i] = toAdd[i];
            return res;
          }
          if (isObservableObject(source)) {
            var res = cache({});
            var keys = __getObjProps(source, includeComputed);
            for (var i=0; i<keys.length; i++){
              var key = keys[i];
              if (!includeComputed && source.$mobx.values[key] && source.$mobx.values[key].get){
                //TODO hcr 不调用read的拦截器, 获取原始的值, 如null
                res[key] = toJS(source.$mobx.values[key].get(), detectCycles, __alreadySeen, includeComputed);
              }else{
                //TODO hcr 会调用read的拦截器，转换成默认值
                res[key] = toJS(source[key], detectCycles, __alreadySeen, includeComputed);
              }
            }
            /*
            for (var key in source)
                res[key] = toJS(source[key], detectCycles, __alreadySeen);
            */
            return res;
          }
          if (isObservableMap(source)) {
            var res_1 = cache({});
            source.forEach(function (value, key) { return res_1[key] = toJS(value, detectCycles, __alreadySeen, includeComputed); });
            return res_1;
          }
          if (isObservableValue(source))
            return toJS(source.get(), detectCycles, __alreadySeen, includeComputed);
        }

        //展现时, 调用toViewJSON
        if (includeComputed && source && source.toViewJSON){
          source.toJSON = source.toViewJSON;
        }
        return source;
      }
      exports.toJS = toJS;
      function toJSlegacy(source, detectCycles, __alreadySeen) {
        if (detectCycles === void 0) { detectCycles = true; }
        if (__alreadySeen === void 0) { __alreadySeen = null; }
        deprecated("toJSlegacy is deprecated and will be removed in the next major. Use `toJS` instead. See #566");
        function cache(value) {
          if (detectCycles)
            __alreadySeen.push([source, value]);
          return value;
        }
        if (source instanceof Date || source instanceof RegExp)
          return source;
        if (detectCycles && __alreadySeen === null)
          __alreadySeen = [];
        if (detectCycles && source !== null && typeof source === "object") {
          for (var i = 0, l = __alreadySeen.length; i < l; i++)
            if (__alreadySeen[i][0] === source)
              return __alreadySeen[i][1];
        }
        if (!source)
          return source;
        if (isArrayLike(source)) {
          var res = cache([]);
          var toAdd = source.map(function (value) { return toJSlegacy(value, detectCycles, __alreadySeen); });
          res.length = toAdd.length;
          for (var i = 0, l = toAdd.length; i < l; i++)
            res[i] = toAdd[i];
          return res;
        }
        if (isObservableMap(source)) {
          var res_2 = cache({});
          source.forEach(function (value, key) { return res_2[key] = toJSlegacy(value, detectCycles, __alreadySeen); });
          return res_2;
        }
        if (isObservableValue(source))
          return toJSlegacy(source.get(), detectCycles, __alreadySeen);
        if (typeof source === "object") {
          var res = cache({});
          for (var key in source)
            res[key] = toJSlegacy(source[key], detectCycles, __alreadySeen);
          return res;
        }
        return source;
      }
      exports.toJSlegacy = toJSlegacy;
      function toJSON(source, detectCycles, __alreadySeen) {
        if (detectCycles === void 0) { detectCycles = true; }
        if (__alreadySeen === void 0) { __alreadySeen = null; }
        deprecated("toJSON is deprecated. Use toJS instead");
        return toJSlegacy.apply(null, arguments);
      }
      exports.toJSON = toJSON;
      function log(msg) {
        console.log(msg);
        return msg;
      }
      function whyRun(thing, prop) {
        switch (arguments.length) {
          case 0:
            thing = globalState.trackingDerivation;
            if (!thing)
              return log("whyRun() can only be used if a derivation is active, or by passing an computed value / reaction explicitly. If you invoked whyRun from inside a computation; the computation is currently suspended but re-evaluating because somebody requested it's value.");
            break;
          case 2:
            thing = getAtom(thing, prop);
            break;
        }
        thing = getAtom(thing);
        if (isComputedValue(thing))
          return log(thing.whyRun());
        else if (isReaction(thing))
          return log(thing.whyRun());
        else
          invariant(false, "whyRun can only be used on reactions and computed values");
      }
      exports.whyRun = whyRun;
      function createAction(actionName, fn) {
        invariant(typeof fn === "function", "`action` can only be invoked on functions");
        invariant(typeof actionName === "string" && actionName.length > 0, "actions should have valid names, got: '" + actionName + "'");
        var res = function () {
          return executeAction(actionName, fn, this, arguments);
        };
        res.isMobxAction = true;
        return res;
      }
      function executeAction(actionName, fn, scope, args) {
        invariant(!isComputedValue(globalState.trackingDerivation), "Computed values or transformers should not invoke actions or trigger other side effects");
        var notifySpy = isSpyEnabled();
        var startTime;
        if (notifySpy) {
          startTime = Date.now();
          var l = (args && args.length) || 0;
          var flattendArgs = new Array(l);
          if (l > 0)
            for (var i = 0; i < l; i++)
              flattendArgs[i] = args[i];
          spyReportStart({
            type: "action",
            name: actionName,
            fn: fn,
            target: scope,
            arguments: flattendArgs
          });
        }
        var prevUntracked = untrackedStart();
        transactionStart(actionName, scope, false);
        var prevAllowStateChanges = allowStateChangesStart(true);
        try {
          return fn.apply(scope, args);
        }
        finally {
          allowStateChangesEnd(prevAllowStateChanges);
          transactionEnd(false);
          untrackedEnd(prevUntracked);
          if (notifySpy)
            spyReportEnd({ time: Date.now() - startTime });
        }
      }
      function useStrict(strict) {
        if (arguments.length === 0) {
          deprecated("`useStrict` without arguments is deprecated, use `isStrictModeEnabled()` instead");
          return globalState.strictMode;
        }
        else {
          invariant(globalState.trackingDerivation === null, "It is not allowed to set `useStrict` when a derivation is running");
          globalState.strictMode = strict;
          globalState.allowStateChanges = !strict;
        }
      }
      exports.useStrict = useStrict;
      function isStrictModeEnabled() {
        return globalState.strictMode;
      }
      exports.isStrictModeEnabled = isStrictModeEnabled;
      function allowStateChanges(allowStateChanges, func) {
        var prev = allowStateChangesStart(allowStateChanges);
        var res = func();
        allowStateChangesEnd(prev);
        return res;
      }
      function allowStateChangesStart(allowStateChanges) {
        var prev = globalState.allowStateChanges;
        globalState.allowStateChanges = allowStateChanges;
        return prev;
      }
      function allowStateChangesEnd(prev) {
        globalState.allowStateChanges = prev;
      }
      var BaseAtom = (function () {
        function BaseAtom(name) {
          if (name === void 0) { name = "Atom@" + getNextId(); }
          this.name = name;
          this.isPendingUnobservation = true;
          this.observers = [];
          this.observersIndexes = {};
          this.diffValue = 0;
          this.lastAccessedBy = 0;
          this.lowestObserverState = IDerivationState.NOT_TRACKING;
        }
        BaseAtom.prototype.onBecomeUnobserved = function () {
        };
        BaseAtom.prototype.reportObserved = function () {
          reportObserved(this);
        };
        BaseAtom.prototype.reportChanged = function () {
          transactionStart("propagatingAtomChange", null, false);
          propagateChanged(this);
          transactionEnd(false);
        };
        BaseAtom.prototype.toString = function () {
          return this.name;
        };
        return BaseAtom;
      }());
      exports.BaseAtom = BaseAtom;
      var Atom = (function (_super) {
        __extends(Atom, _super);
        function Atom(name, onBecomeObservedHandler, onBecomeUnobservedHandler) {
          if (name === void 0) { name = "Atom@" + getNextId(); }
          if (onBecomeObservedHandler === void 0) { onBecomeObservedHandler = noop; }
          if (onBecomeUnobservedHandler === void 0) { onBecomeUnobservedHandler = noop; }
          _super.call(this, name);
          this.name = name;
          this.onBecomeObservedHandler = onBecomeObservedHandler;
          this.onBecomeUnobservedHandler = onBecomeUnobservedHandler;
          this.isPendingUnobservation = false;
          this.isBeingTracked = false;
        }
        Atom.prototype.reportObserved = function () {
          startBatch();
          _super.prototype.reportObserved.call(this);
          if (!this.isBeingTracked) {
            this.isBeingTracked = true;
            this.onBecomeObservedHandler();
          }
          endBatch();
          return !!globalState.trackingDerivation;
        };
        Atom.prototype.onBecomeUnobserved = function () {
          this.isBeingTracked = false;
          this.onBecomeUnobservedHandler();
        };
        return Atom;
      }(BaseAtom));
      exports.Atom = Atom;
      var isAtom = createInstanceofPredicate("Atom", BaseAtom);
      var ComputedValue = (function () {
        function ComputedValue(derivation, scope, compareStructural, name, setter) {
          this.derivation = derivation;
          this.scope = scope;
          this.compareStructural = compareStructural;
          this.dependenciesState = IDerivationState.NOT_TRACKING;
          this.observing = [];
          this.newObserving = null;
          this.isPendingUnobservation = false;
          this.observers = [];
          this.observersIndexes = {};
          this.diffValue = 0;
          this.runId = 0;
          this.lastAccessedBy = 0;
          this.lowestObserverState = IDerivationState.UP_TO_DATE;
          this.unboundDepsCount = 0;
          this.__mapid = "#" + getNextId();
          this.value = undefined;
          this.isComputing = false;
          this.isRunningSetter = false;
          this.name = name || "ComputedValue@" + getNextId();
          if (setter)
            this.setter = createAction(name + "-setter", setter);
        }
        ComputedValue.prototype.peek = function () {
          this.isComputing = true;
          var prevAllowStateChanges = allowStateChangesStart(false);
          var res = this.derivation.call(this.scope);
          allowStateChangesEnd(prevAllowStateChanges);
          this.isComputing = false;
          return res;
        };
        ;
        ComputedValue.prototype.peekUntracked = function () {
          var hasError = true;
          try {
            var res = this.peek();
            hasError = false;
            return res;
          }
          finally {
            if (hasError)
              handleExceptionInDerivation(this);
          }
        };
        ComputedValue.prototype.onBecomeStale = function () {
          propagateMaybeChanged(this);
        };
        ComputedValue.prototype.onBecomeUnobserved = function () {
          invariant(this.dependenciesState !== IDerivationState.NOT_TRACKING, "INTERNAL ERROR only onBecomeUnobserved shouldn't be called twice in a row");
          clearObserving(this);
          this.value = undefined;
        };
        ComputedValue.prototype.get = function () {
          invariant(!this.isComputing, "Cycle detected in computation " + this.name, this.derivation);
          startBatch();
          if (globalState.inBatch === 1) {
            if (shouldCompute(this))
              this.value = this.peekUntracked();
          }
          else {
            reportObserved(this);
            if (shouldCompute(this))
              if (this.trackAndCompute())
                propagateChangeConfirmed(this);
          }
          var result = this.value;
          endBatch();
          return result;
        };
        ComputedValue.prototype.recoverFromError = function () {
          this.isComputing = false;
        };
        ComputedValue.prototype.set = function (value) {
          if (this.setter) {
            invariant(!this.isRunningSetter, "The setter of computed value '" + this.name + "' is trying to update itself. Did you intend to update an _observable_ value, instead of the computed property?");
            this.isRunningSetter = true;
            try {
              this.setter.call(this.scope, value);
            }
            finally {
              this.isRunningSetter = false;
            }
          }
          else
            invariant(false, "[ComputedValue '" + this.name + "'] It is not possible to assign a new value to a computed value.");
        };
        ComputedValue.prototype.trackAndCompute = function () {
          if (isSpyEnabled()) {
            spyReport({
              object: this,
              type: "compute",
              fn: this.derivation,
              target: this.scope
            });
          }
          var oldValue = this.value;
          var newValue = this.value = trackDerivedFunction(this, this.peek);
          return valueDidChange(this.compareStructural, newValue, oldValue);
        };
        ComputedValue.prototype.observe = function (listener, fireImmediately) {
          var _this = this;
          var firstTime = true;
          var prevValue = undefined;
          return autorun(function () {
            var newValue = _this.get();
            if (!firstTime || fireImmediately) {
              var prevU = untrackedStart();
              listener(newValue, prevValue);
              untrackedEnd(prevU);
            }
            firstTime = false;
            prevValue = newValue;
          });
        };
        ComputedValue.prototype.toJSON = function () {
          return this.get();
        };
        ComputedValue.prototype.toString = function () {
          return this.name + "[" + this.derivation.toString() + "]";
        };
        ComputedValue.prototype.whyRun = function () {
          var isTracking = Boolean(globalState.trackingDerivation);
          var observing = unique(this.isComputing ? this.newObserving : this.observing).map(function (dep) { return dep.name; });
          var observers = unique(getObservers(this).map(function (dep) { return dep.name; }));
          return (("\nWhyRun? computation '" + this.name + "':\n * Running because: " + (isTracking ? "[active] the value of this computation is needed by a reaction" : this.isComputing ? "[get] The value of this computed was requested outside a reaction" : "[idle] not running at the moment") + "\n") +
            (this.dependenciesState === IDerivationState.NOT_TRACKING
              ?
              " * This computation is suspended (not in use by any reaction) and won't run automatically.\n\tDidn't expect this computation to be suspended at this point?\n\t  1. Make sure this computation is used by a reaction (reaction, autorun, observer).\n\t  2. Check whether you are using this computation synchronously (in the same stack as they reaction that needs it).\n"
              :
              " * This computation will re-run if any of the following observables changes:\n    " + joinStrings(observing) + "\n    " + ((this.isComputing && isTracking) ? " (... or any observable accessed during the remainder of the current run)" : "") + "\n\tMissing items in this list?\n\t  1. Check whether all used values are properly marked as observable (use isObservable to verify)\n\t  2. Make sure you didn't dereference values too early. MobX observes props, not primitives. E.g: use 'person.name' instead of 'name' in your computation.\n  * If the outcome of this computation changes, the following observers will be re-run:\n    " + joinStrings(observers) + "\n"));
        };
        return ComputedValue;
      }());
      var isComputedValue = createInstanceofPredicate("ComputedValue", ComputedValue);
      var IDerivationState;
      (function (IDerivationState) {
        IDerivationState[IDerivationState["NOT_TRACKING"] = -1] = "NOT_TRACKING";
        IDerivationState[IDerivationState["UP_TO_DATE"] = 0] = "UP_TO_DATE";
        IDerivationState[IDerivationState["POSSIBLY_STALE"] = 1] = "POSSIBLY_STALE";
        IDerivationState[IDerivationState["STALE"] = 2] = "STALE";
      })(IDerivationState || (IDerivationState = {}));
      exports.IDerivationState = IDerivationState;
      function shouldCompute(derivation) {
        switch (derivation.dependenciesState) {
          case IDerivationState.UP_TO_DATE: return false;
          case IDerivationState.NOT_TRACKING:
          case IDerivationState.STALE: return true;
          case IDerivationState.POSSIBLY_STALE: {
            var hasError = true;
            var prevUntracked = untrackedStart();
            try {
              var obs = derivation.observing, l = obs.length;
              for (var i = 0; i < l; i++) {
                var obj = obs[i];
                if (isComputedValue(obj)) {
                  obj.get();
                  if (derivation.dependenciesState === IDerivationState.STALE) {
                    hasError = false;
                    untrackedEnd(prevUntracked);
                    return true;
                  }
                }
              }
              hasError = false;
              changeDependenciesStateTo0(derivation);
              untrackedEnd(prevUntracked);
              return false;
            }
            finally {
              if (hasError) {
                changeDependenciesStateTo0(derivation);
              }
            }
          }
        }
      }
      function isComputingDerivation() {
        return globalState.trackingDerivation !== null;
      }
      function checkIfStateModificationsAreAllowed() {
        if (!globalState.allowStateChanges) {
          invariant(false, globalState.strictMode
            ? "It is not allowed to create or change state outside an `action` when MobX is in strict mode. Wrap the current method in `action` if this state change is intended"
            : "It is not allowed to change the state when a computed value or transformer is being evaluated. Use 'autorun' to create reactive functions with side-effects.");
        }
      }
      function trackDerivedFunction(derivation, f) {
        changeDependenciesStateTo0(derivation);
        derivation.newObserving = new Array(derivation.observing.length + 100);
        derivation.unboundDepsCount = 0;
        derivation.runId = ++globalState.runId;
        var prevTracking = globalState.trackingDerivation;
        globalState.trackingDerivation = derivation;
        var hasException = true;
        var result;
        try {
          result = f.call(derivation);
          hasException = false;
        }
        finally {
          if (hasException) {
            handleExceptionInDerivation(derivation);
          }
          else {
            globalState.trackingDerivation = prevTracking;
            bindDependencies(derivation);
          }
        }
        return result;
      }
      function handleExceptionInDerivation(derivation) {
        var message = ("[mobx] An uncaught exception occurred while calculating your computed value, autorun or transformer. Or inside the render() method of an observer based React component. " +
          "These functions should never throw exceptions as MobX will not always be able to recover from them. " +
          ("Please fix the error reported after this message or enable 'Pause on (caught) exceptions' in your debugger to find the root cause. In: '" + derivation.name + "'. ") +
          "For more details see https://github.com/mobxjs/mobx/issues/462");
        if (isSpyEnabled()) {
          spyReport({
            type: "error",
            message: message
          });
        }
        console.warn(message);
        changeDependenciesStateTo0(derivation);
        derivation.newObserving = null;
        derivation.unboundDepsCount = 0;
        derivation.recoverFromError();
        endBatch();
        resetGlobalState();
      }
      function bindDependencies(derivation) {
        var prevObserving = derivation.observing;
        var observing = derivation.observing = derivation.newObserving;
        derivation.newObserving = null;
        var i0 = 0, l = derivation.unboundDepsCount;
        for (var i = 0; i < l; i++) {
          var dep = observing[i];
          if (dep.diffValue === 0) {
            dep.diffValue = 1;
            if (i0 !== i)
              observing[i0] = dep;
            i0++;
          }
        }
        observing.length = i0;
        l = prevObserving.length;
        while (l--) {
          var dep = prevObserving[l];
          if (dep.diffValue === 0) {
            removeObserver(dep, derivation);
          }
          dep.diffValue = 0;
        }
        while (i0--) {
          var dep = observing[i0];
          if (dep.diffValue === 1) {
            dep.diffValue = 0;
            addObserver(dep, derivation);
          }
        }
      }
      function clearObserving(derivation) {
        var obs = derivation.observing;
        var i = obs.length;
        while (i--)
          removeObserver(obs[i], derivation);
        derivation.dependenciesState = IDerivationState.NOT_TRACKING;
        obs.length = 0;
      }
      function untracked(action) {
        var prev = untrackedStart();
        var res = action();
        untrackedEnd(prev);
        return res;
      }
      exports.untracked = untracked;
      function untrackedStart() {
        var prev = globalState.trackingDerivation;
        globalState.trackingDerivation = null;
        return prev;
      }
      function untrackedEnd(prev) {
        globalState.trackingDerivation = prev;
      }
      function changeDependenciesStateTo0(derivation) {
        if (derivation.dependenciesState === IDerivationState.UP_TO_DATE)
          return;
        derivation.dependenciesState = IDerivationState.UP_TO_DATE;
        var obs = derivation.observing;
        var i = obs.length;
        while (i--)
          obs[i].lowestObserverState = IDerivationState.UP_TO_DATE;
      }
      var persistentKeys = ["mobxGuid", "resetId", "spyListeners", "strictMode", "runId"];
      var MobXGlobals = (function () {
        function MobXGlobals() {
          this.version = 4;
          this.trackingDerivation = null;
          this.runId = 0;
          this.mobxGuid = 0;
          this.inTransaction = 0;
          this.isRunningReactions = false;
          this.inBatch = 0;
          this.pendingUnobservations = [];
          this.pendingReactions = [];
          this.allowStateChanges = true;
          this.strictMode = false;
          this.resetId = 0;
          this.spyListeners = [];
        }
        return MobXGlobals;
      }());
      var globalState = (function () {
        var res = new MobXGlobals();
        if (global.__mobservableTrackingStack || global.__mobservableViewStack)
          throw new Error("[mobx] An incompatible version of mobservable is already loaded.");
        if (global.__mobxGlobal && global.__mobxGlobal.version !== res.version)
          throw new Error("[mobx] An incompatible version of mobx is already loaded.");
        if (global.__mobxGlobal)
          return global.__mobxGlobal;
        return global.__mobxGlobal = res;
      })();
      function registerGlobals() {
      }
      function resetGlobalState() {
        globalState.resetId++;
        var defaultGlobals = new MobXGlobals();
        for (var key in defaultGlobals)
          if (persistentKeys.indexOf(key) === -1)
            globalState[key] = defaultGlobals[key];
        globalState.allowStateChanges = !globalState.strictMode;
      }
      function hasObservers(observable) {
        return observable.observers && observable.observers.length > 0;
      }
      function getObservers(observable) {
        return observable.observers;
      }
      function invariantObservers(observable) {
        var list = observable.observers;
        var map = observable.observersIndexes;
        var l = list.length;
        for (var i = 0; i < l; i++) {
          var id = list[i].__mapid;
          if (i) {
            invariant(map[id] === i, "INTERNAL ERROR maps derivation.__mapid to index in list");
          }
          else {
            invariant(!(id in map), "INTERNAL ERROR observer on index 0 shouldnt be held in map.");
          }
        }
        invariant(list.length === 0 || Object.keys(map).length === list.length - 1, "INTERNAL ERROR there is no junk in map");
      }
      function addObserver(observable, node) {
        var l = observable.observers.length;
        if (l) {
          observable.observersIndexes[node.__mapid] = l;
        }
        observable.observers[l] = node;
        if (observable.lowestObserverState > node.dependenciesState)
          observable.lowestObserverState = node.dependenciesState;
      }
      function removeObserver(observable, node) {
        if (observable.observers.length === 1) {
          observable.observers.length = 0;
          queueForUnobservation(observable);
        }
        else {
          var list = observable.observers;
          var map_1 = observable.observersIndexes;
          var filler = list.pop();
          if (filler !== node) {
            var index = map_1[node.__mapid] || 0;
            if (index) {
              map_1[filler.__mapid] = index;
            }
            else {
              delete map_1[filler.__mapid];
            }
            list[index] = filler;
          }
          delete map_1[node.__mapid];
        }
      }
      function queueForUnobservation(observable) {
        if (!observable.isPendingUnobservation) {
          observable.isPendingUnobservation = true;
          globalState.pendingUnobservations.push(observable);
        }
      }
      function startBatch() {
        globalState.inBatch++;
      }
      function endBatch() {
        if (globalState.inBatch === 1) {
          var list = globalState.pendingUnobservations;
          for (var i = 0; i < list.length; i++) {
            var observable_1 = list[i];
            observable_1.isPendingUnobservation = false;
            if (observable_1.observers.length === 0) {
              observable_1.onBecomeUnobserved();
            }
          }
          globalState.pendingUnobservations = [];
        }
        globalState.inBatch--;
      }
      function reportObserved(observable) {
        var derivation = globalState.trackingDerivation;
        if (derivation !== null) {
          if (derivation.runId !== observable.lastAccessedBy) {
            observable.lastAccessedBy = derivation.runId;
            derivation.newObserving[derivation.unboundDepsCount++] = observable;
          }
        }
        else if (observable.observers.length === 0) {
          queueForUnobservation(observable);
        }
      }
      function invariantLOS(observable, msg) {
        var min = getObservers(observable).reduce(function (a, b) { return Math.min(a, b.dependenciesState); }, 2);
        if (min >= observable.lowestObserverState)
          return;
        throw new Error("lowestObserverState is wrong for " + msg + " because " + min + " < " + observable.lowestObserverState);
      }
      function propagateChanged(observable) {
        if (observable.lowestObserverState === IDerivationState.STALE)
          return;
        observable.lowestObserverState = IDerivationState.STALE;
        var observers = observable.observers;
        var i = observers.length;
        while (i--) {
          var d = observers[i];
          if (d.dependenciesState === IDerivationState.UP_TO_DATE)
            d.onBecomeStale();
          d.dependenciesState = IDerivationState.STALE;
        }
      }
      function propagateChangeConfirmed(observable) {
        if (observable.lowestObserverState === IDerivationState.STALE)
          return;
        observable.lowestObserverState = IDerivationState.STALE;
        var observers = observable.observers;
        var i = observers.length;
        while (i--) {
          var d = observers[i];
          if (d.dependenciesState === IDerivationState.POSSIBLY_STALE)
            d.dependenciesState = IDerivationState.STALE;
          else if (d.dependenciesState === IDerivationState.UP_TO_DATE)
            observable.lowestObserverState = IDerivationState.UP_TO_DATE;
        }
      }
      function propagateMaybeChanged(observable) {
        if (observable.lowestObserverState !== IDerivationState.UP_TO_DATE)
          return;
        observable.lowestObserverState = IDerivationState.POSSIBLY_STALE;
        var observers = observable.observers;
        var i = observers.length;
        while (i--) {
          var d = observers[i];
          if (d.dependenciesState === IDerivationState.UP_TO_DATE) {
            d.dependenciesState = IDerivationState.POSSIBLY_STALE;
            d.onBecomeStale();
          }
        }
      }
      var Reaction = (function () {
        function Reaction(name, onInvalidate) {
          if (name === void 0) { name = "Reaction@" + getNextId(); }
          this.name = name;
          this.onInvalidate = onInvalidate;
          this.observing = [];
          this.newObserving = [];
          this.dependenciesState = IDerivationState.NOT_TRACKING;
          this.diffValue = 0;
          this.runId = 0;
          this.unboundDepsCount = 0;
          this.__mapid = "#" + getNextId();
          this.isDisposed = false;
          this._isScheduled = false;
          this._isTrackPending = false;
          this._isRunning = false;
        }
        Reaction.prototype.onBecomeStale = function () {
          this.schedule();
        };
        Reaction.prototype.schedule = function () {
          if (!this._isScheduled) {
            this._isScheduled = true;
            globalState.pendingReactions.push(this);
            startBatch();
            runReactions();
            endBatch();
          }
        };
        Reaction.prototype.isScheduled = function () {
          return this._isScheduled;
        };
        Reaction.prototype.runReaction = function () {
          if (!this.isDisposed) {
            this._isScheduled = false;
            if (shouldCompute(this)) {
              this._isTrackPending = true;
              this.onInvalidate();
              if (this._isTrackPending && isSpyEnabled()) {
                spyReport({
                  object: this,
                  type: "scheduled-reaction"
                });
              }
            }
          }
        };
        Reaction.prototype.track = function (fn) {
          startBatch();
          var notify = isSpyEnabled();
          var startTime;
          if (notify) {
            startTime = Date.now();
            spyReportStart({
              object: this,
              type: "reaction",
              fn: fn
            });
          }
          this._isRunning = true;
          trackDerivedFunction(this, fn);
          this._isRunning = false;
          this._isTrackPending = false;
          if (this.isDisposed) {
            clearObserving(this);
          }
          if (notify) {
            spyReportEnd({
              time: Date.now() - startTime
            });
          }
          endBatch();
        };
        Reaction.prototype.recoverFromError = function () {
          this._isRunning = false;
          this._isTrackPending = false;
        };
        Reaction.prototype.dispose = function () {
          if (!this.isDisposed) {
            this.isDisposed = true;
            if (!this._isRunning) {
              startBatch();
              clearObserving(this);
              endBatch();
            }
          }
        };
        Reaction.prototype.getDisposer = function () {
          var r = this.dispose.bind(this);
          r.$mobx = this;
          return r;
        };
        Reaction.prototype.toString = function () {
          return "Reaction[" + this.name + "]";
        };
        Reaction.prototype.whyRun = function () {
          var observing = unique(this._isRunning ? this.newObserving : this.observing).map(function (dep) { return dep.name; });
          return ("\nWhyRun? reaction '" + this.name + "':\n * Status: [" + (this.isDisposed ? "stopped" : this._isRunning ? "running" : this.isScheduled() ? "scheduled" : "idle") + "]\n * This reaction will re-run if any of the following observables changes:\n    " + joinStrings(observing) + "\n    " + ((this._isRunning) ? " (... or any observable accessed during the remainder of the current run)" : "") + "\n\tMissing items in this list?\n\t  1. Check whether all used values are properly marked as observable (use isObservable to verify)\n\t  2. Make sure you didn't dereference values too early. MobX observes props, not primitives. E.g: use 'person.name' instead of 'name' in your computation.\n");
        };
        return Reaction;
      }());
      exports.Reaction = Reaction;
      var MAX_REACTION_ITERATIONS = 100;
      function runReactions() {
        if (globalState.isRunningReactions === true || globalState.inTransaction > 0)
          return;
        globalState.isRunningReactions = true;
        var allReactions = globalState.pendingReactions;
        var iterations = 0;
        while (allReactions.length > 0) {
          if (++iterations === MAX_REACTION_ITERATIONS) {
            resetGlobalState();
            throw new Error(("Reaction doesn't converge to a stable state after " + MAX_REACTION_ITERATIONS + " iterations.")
              + (" Probably there is a cycle in the reactive function: " + allReactions[0]));
          }
          var remainingReactions = allReactions.splice(0);
          for (var i = 0, l = remainingReactions.length; i < l; i++)
            remainingReactions[i].runReaction();
        }
        globalState.isRunningReactions = false;
      }
      var isReaction = createInstanceofPredicate("Reaction", Reaction);
      function isSpyEnabled() {
        return !!globalState.spyListeners.length;
      }
      function spyReport(event) {
        if (!globalState.spyListeners.length)
          return false;
        var listeners = globalState.spyListeners;
        for (var i = 0, l = listeners.length; i < l; i++)
          listeners[i](event);
      }
      function spyReportStart(event) {
        var change = objectAssign({}, event, { spyReportStart: true });
        spyReport(change);
      }
      var END_EVENT = { spyReportEnd: true };
      function spyReportEnd(change) {
        if (change)
          spyReport(objectAssign({}, change, END_EVENT));
        else
          spyReport(END_EVENT);
      }
      function spy(listener) {
        globalState.spyListeners.push(listener);
        return once(function () {
          var idx = globalState.spyListeners.indexOf(listener);
          if (idx !== -1)
            globalState.spyListeners.splice(idx, 1);
        });
      }
      exports.spy = spy;
      function trackTransitions(onReport) {
        deprecated("trackTransitions is deprecated. Use mobx.spy instead");
        if (typeof onReport === "boolean") {
          deprecated("trackTransitions only takes a single callback function. If you are using the mobx-react-devtools, please update them first");
          onReport = arguments[1];
        }
        if (!onReport) {
          deprecated("trackTransitions without callback has been deprecated and is a no-op now. If you are using the mobx-react-devtools, please update them first");
          return function () { };
        }
        return spy(onReport);
      }
      function transaction(action, thisArg, report) {
        if (thisArg === void 0) { thisArg = undefined; }
        if (report === void 0) { report = true; }
        transactionStart((action.name) || "anonymous transaction", thisArg, report);
        var res = action.call(thisArg);
        transactionEnd(report);
        return res;
      }
      exports.transaction = transaction;
      function transactionStart(name, thisArg, report) {
        if (thisArg === void 0) { thisArg = undefined; }
        if (report === void 0) { report = true; }
        startBatch();
        globalState.inTransaction += 1;
        if (report && isSpyEnabled()) {
          spyReportStart({
            type: "transaction",
            target: thisArg,
            name: name
          });
        }
      }
      function transactionEnd(report) {
        if (report === void 0) { report = true; }
        if (--globalState.inTransaction === 0) {
          runReactions();
        }
        if (report && isSpyEnabled())
          spyReportEnd();
        endBatch();
      }
      function hasInterceptors(interceptable) {
        return (interceptable.interceptors && interceptable.interceptors.length > 0);
      }
      function registerInterceptor(interceptable, handler) {
        var interceptors = interceptable.interceptors || (interceptable.interceptors = []);
        interceptors.push(handler);
        return once(function () {
          var idx = interceptors.indexOf(handler);
          if (idx !== -1)
            interceptors.splice(idx, 1);
        });
      }
      function interceptChange(interceptable, change) {
        var prevU = untrackedStart();
        var interceptors = interceptable.interceptors;
        for (var i = 0, l = interceptors.length; i < l; i++) {
          change = interceptors[i](change);
          invariant(!change || change.type, "Intercept handlers should return nothing or a change object");
          if (!change)
            return null;
        }
        untrackedEnd(prevU);
        return change;
      }
      function hasListeners(listenable) {
        return listenable.changeListeners && listenable.changeListeners.length > 0;
      }
      function registerListener(listenable, handler) {
        var listeners = listenable.changeListeners || (listenable.changeListeners = []);
        listeners.push(handler);
        return once(function () {
          var idx = listeners.indexOf(handler);
          if (idx !== -1)
            listeners.splice(idx, 1);
        });
      }
      function notifyListeners(listenable, change) {
        var prevU = untrackedStart();
        var listeners = listenable.changeListeners;
        if (!listeners)
          return;
        listeners = listeners.slice();
        for (var i = 0, l = listeners.length; i < l; i++) {
          if (Array.isArray(change)) {
            listeners[i].apply(null, change);
          }
          else {
            listeners[i](change);
          }
        }
        untrackedEnd(prevU);
      }
      var ValueMode;
      (function (ValueMode) {
        ValueMode[ValueMode["Recursive"] = 0] = "Recursive";
        ValueMode[ValueMode["Reference"] = 1] = "Reference";
        ValueMode[ValueMode["Structure"] = 2] = "Structure";
        ValueMode[ValueMode["Flat"] = 3] = "Flat";
      })(ValueMode || (ValueMode = {}));
      function withModifier(modifier, value) {
        assertUnwrapped(value, "Modifiers are not allowed to be nested");
        return {
          mobxModifier: modifier,
          value: value
        };
      }
      function getModifier(value) {
        if (value) {
          return value.mobxModifier || null;
        }
        return null;
      }
      function asReference(value) {
        return withModifier(ValueMode.Reference, value);
      }
      exports.asReference = asReference;
      asReference.mobxModifier = ValueMode.Reference;
      function asStructure(value) {
        return withModifier(ValueMode.Structure, value);
      }
      exports.asStructure = asStructure;
      asStructure.mobxModifier = ValueMode.Structure;
      function asFlat(value) {
        return withModifier(ValueMode.Flat, value);
      }
      exports.asFlat = asFlat;
      asFlat.mobxModifier = ValueMode.Flat;
      function asMap(data, modifierFunc) {
        return map(data, modifierFunc);
      }
      exports.asMap = asMap;
      function getValueModeFromValue(value, defaultMode) {
        var mode = getModifier(value);
        if (mode)
          return [mode, value.value];
        return [defaultMode, value];
      }
      function getValueModeFromModifierFunc(func) {
        if (func === undefined)
          return ValueMode.Recursive;
        var mod = getModifier(func);
        invariant(mod !== null, "Cannot determine value mode from function. Please pass in one of these: mobx.asReference, mobx.asStructure or mobx.asFlat, got: " + func);
        return mod;
      }
      function makeChildObservable(value, parentMode, name) {
        var childMode;
        if (isObservable(value))
          return value;
        switch (parentMode) {
          case ValueMode.Reference:
            return value;
          case ValueMode.Flat:
            assertUnwrapped(value, "Items inside 'asFlat' cannot have modifiers");
            childMode = ValueMode.Reference;
            break;
          case ValueMode.Structure:
            assertUnwrapped(value, "Items inside 'asStructure' cannot have modifiers");
            childMode = ValueMode.Structure;
            break;
          case ValueMode.Recursive:
            _a = getValueModeFromValue(value, ValueMode.Recursive); childMode = _a[0]; value = _a[1];
            break;
          default:
            invariant(false, "Illegal State");
        }
        if (Array.isArray(value))
          return createObservableArray(value, childMode, name);
        if (isPlainObject(value) && Object.isExtensible(value))
          return extendObservableHelper(value, value, childMode, name);
        return value;
        var _a;
      }
      function assertUnwrapped(value, message) {
        if (getModifier(value) !== null)
          throw new Error("[mobx] asStructure / asReference / asFlat cannot be used here. " + message);
      }
      var safariPrototypeSetterInheritanceBug = (function () {
        var v = false;
        var p = {};
        Object.defineProperty(p, "0", { set: function () { v = true; } });
        Object.create(p)["0"] = 1;
        return v === false;
      })();
      var OBSERVABLE_ARRAY_BUFFER_SIZE = 0;
      var StubArray = (function () {
        function StubArray() {
        }
        return StubArray;
      }());
      StubArray.prototype = [];
      var ObservableArrayAdministration = (function () {
        function ObservableArrayAdministration(name, mode, array, owned) {
          this.mode = mode;
          this.array = array;
          this.owned = owned;
          this.lastKnownLength = 0;
          this.interceptors = null;
          this.changeListeners = null;
          this.atom = new BaseAtom(name || ("ObservableArray@" + getNextId()));
        }
        ObservableArrayAdministration.prototype.makeReactiveArrayItem = function (value) {
          assertUnwrapped(value, "Array values cannot have modifiers");
          if (this.mode === ValueMode.Flat || this.mode === ValueMode.Reference)
            return value;
          return makeChildObservable(value, this.mode, this.atom.name + "[..]");
        };
        ObservableArrayAdministration.prototype.intercept = function (handler) {
          return registerInterceptor(this, handler);
        };
        ObservableArrayAdministration.prototype.observe = function (listener, fireImmediately) {
          if (fireImmediately === void 0) { fireImmediately = false; }
          if (fireImmediately) {
            listener({
              object: this.array,
              type: "splice",
              index: 0,
              added: this.values.slice(),
              addedCount: this.values.length,
              removed: [],
              removedCount: 0
            });
          }
          return registerListener(this, listener);
        };
        ObservableArrayAdministration.prototype.getArrayLength = function () {
          this.atom.reportObserved();
          return this.values.length;
        };
        ObservableArrayAdministration.prototype.setArrayLength = function (newLength) {
          if (typeof newLength !== "number" || newLength < 0)
            throw new Error("[mobx.array] Out of range: " + newLength);
          var currentLength = this.values.length;
          if (newLength === currentLength)
            return;
          else if (newLength > currentLength)
            this.spliceWithArray(currentLength, 0, new Array(newLength - currentLength));
          else
            this.spliceWithArray(newLength, currentLength - newLength);
        };
        ObservableArrayAdministration.prototype.updateArrayLength = function (oldLength, delta) {
          if (oldLength !== this.lastKnownLength)
            throw new Error("[mobx] Modification exception: the internal structure of an observable array was changed. Did you use peek() to change it?");
          this.lastKnownLength += delta;
          if (delta > 0 && oldLength + delta + 1 > OBSERVABLE_ARRAY_BUFFER_SIZE)
            reserveArrayBuffer(oldLength + delta + 1);
        };
        ObservableArrayAdministration.prototype.spliceWithArray = function (index, deleteCount, newItems) {
          checkIfStateModificationsAreAllowed();
          var length = this.values.length;
          if (index === undefined)
            index = 0;
          else if (index > length)
            index = length;
          else if (index < 0)
            index = Math.max(0, length + index);
          if (arguments.length === 1)
            deleteCount = length - index;
          else if (deleteCount === undefined || deleteCount === null)
            deleteCount = 0;
          else
            deleteCount = Math.max(0, Math.min(deleteCount, length - index));
          if (newItems === undefined)
            newItems = [];
          if (hasInterceptors(this)) {
            var change = interceptChange(this, {
              object: this.array,
              type: "splice",
              index: index,
              removedCount: deleteCount,
              added: newItems
            });
            if (!change)
              return EMPTY_ARRAY;
            deleteCount = change.removedCount;
            newItems = change.added;
          }
          newItems = newItems.map(this.makeReactiveArrayItem, this);
          var lengthDelta = newItems.length - deleteCount;
          this.updateArrayLength(length, lengthDelta);
          var res = (_a = this.values).splice.apply(_a, [index, deleteCount].concat(newItems));
          if (deleteCount !== 0 || newItems.length !== 0)
            this.notifyArraySplice(index, newItems, res);
          return res;
          var _a;
        };
        ObservableArrayAdministration.prototype.notifyArrayChildUpdate = function (index, newValue, oldValue) {
          var notifySpy = !this.owned && isSpyEnabled();
          var notify = hasListeners(this);
          var change = notify || notifySpy ? {
            object: this.array,
            type: "update",
            index: index, newValue: newValue, oldValue: oldValue
          } : null;
          if (notifySpy)
            spyReportStart(change);
          this.atom.reportChanged();
          if (notify)
            notifyListeners(this, change);
          if (notifySpy)
            spyReportEnd();
        };
        ObservableArrayAdministration.prototype.notifyArraySplice = function (index, added, removed) {
          var notifySpy = !this.owned && isSpyEnabled();
          var notify = hasListeners(this);
          var change = notify || notifySpy ? {
            object: this.array,
            type: "splice",
            index: index, removed: removed, added: added,
            removedCount: removed.length,
            addedCount: added.length
          } : null;
          if (notifySpy)
            spyReportStart(change);
          this.atom.reportChanged();
          if (notify)
            notifyListeners(this, change);
          if (notifySpy)
            spyReportEnd();
        };
        return ObservableArrayAdministration;
      }());
      var ObservableArray = (function (_super) {
        __extends(ObservableArray, _super);
        function ObservableArray(initialValues, mode, name, owned) {
          if (owned === void 0) { owned = false; }
          _super.call(this);
          var adm = new ObservableArrayAdministration(name, mode, this, owned);
          addHiddenFinalProp(this, "$mobx", adm);
          if (initialValues && initialValues.length) {
            adm.updateArrayLength(0, initialValues.length);
            adm.values = initialValues.map(adm.makeReactiveArrayItem, adm);
            adm.notifyArraySplice(0, adm.values.slice(), EMPTY_ARRAY);
          }
          else {
            adm.values = [];
          }
          if (safariPrototypeSetterInheritanceBug) {
            Object.defineProperty(adm.array, "0", ENTRY_0);
          }
        }
        ObservableArray.prototype.intercept = function (handler) {
          return this.$mobx.intercept(handler);
        };
        ObservableArray.prototype.observe = function (listener, fireImmediately) {
          if (fireImmediately === void 0) { fireImmediately = false; }
          return this.$mobx.observe(listener, fireImmediately);
        };
        ObservableArray.prototype.clear = function () {
          return this.splice(0);
        };
        ObservableArray.prototype.concat = function () {
          var arrays = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            arrays[_i - 0] = arguments[_i];
          }
          this.$mobx.atom.reportObserved();
          return Array.prototype.concat.apply(this.slice(), arrays.map(function (a) { return isObservableArray(a) ? a.slice() : a; }));
        };
        ObservableArray.prototype.replace = function (newItems) {
          return this.$mobx.spliceWithArray(0, this.$mobx.values.length, newItems);
        };
        ObservableArray.prototype.toJS = function () {
          return this.slice();
        };
        ObservableArray.prototype.toJSON = function () {
          return this.toJS();
        };
        ObservableArray.prototype.peek = function () {
          return this.$mobx.values;
        };
        ObservableArray.prototype.find = function (predicate, thisArg, fromIndex) {
          if (fromIndex === void 0) { fromIndex = 0; }
          this.$mobx.atom.reportObserved();
          var items = this.$mobx.values, l = items.length;
          for (var i = fromIndex; i < l; i++)
            if (predicate.call(thisArg, items[i], i, this))
              return items[i];
          return undefined;
        };
        ObservableArray.prototype.splice = function (index, deleteCount) {
          var newItems = [];
          for (var _i = 2; _i < arguments.length; _i++) {
            newItems[_i - 2] = arguments[_i];
          }
          switch (arguments.length) {
            case 0:
              return [];
            case 1:
              return this.$mobx.spliceWithArray(index);
            case 2:
              return this.$mobx.spliceWithArray(index, deleteCount);
          }
          return this.$mobx.spliceWithArray(index, deleteCount, newItems);
        };
        ObservableArray.prototype.push = function () {
          var items = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            items[_i - 0] = arguments[_i];
          }
          var adm = this.$mobx;
          adm.spliceWithArray(adm.values.length, 0, items);
          return adm.values.length;
        };
        ObservableArray.prototype.pop = function () {
          return this.splice(Math.max(this.$mobx.values.length - 1, 0), 1)[0];
        };
        ObservableArray.prototype.shift = function () {
          return this.splice(0, 1)[0];
        };
        ObservableArray.prototype.unshift = function () {
          var items = [];
          for (var _i = 0; _i < arguments.length; _i++) {
            items[_i - 0] = arguments[_i];
          }
          var adm = this.$mobx;
          adm.spliceWithArray(0, 0, items);
          return adm.values.length;
        };
        ObservableArray.prototype.reverse = function () {
          this.$mobx.atom.reportObserved();
          var clone = this.slice();
          return clone.reverse.apply(clone, arguments);
        };
        ObservableArray.prototype.sort = function (compareFn) {
          this.$mobx.atom.reportObserved();
          var clone = this.slice();
          return clone.sort.apply(clone, arguments);
        };
        ObservableArray.prototype.remove = function (value) {
          var idx = this.$mobx.values.indexOf(value);
          if (idx > -1) {
            this.splice(idx, 1);
            return true;
          }
          return false;
        };
        ObservableArray.prototype.toString = function () {
          return "[mobx.array] " + Array.prototype.toString.apply(this.$mobx.values, arguments);
        };
        ObservableArray.prototype.toLocaleString = function () {
          return "[mobx.array] " + Array.prototype.toLocaleString.apply(this.$mobx.values, arguments);
        };
        return ObservableArray;
      }(StubArray));
      declareIterator(ObservableArray.prototype, function () {
        return arrayAsIterator(this.slice());
      });
      makeNonEnumerable(ObservableArray.prototype, [
        "constructor",
        "intercept",
        "observe",
        "clear",
        "concat",
        "replace",
        "toJS",
        "toJSON",
        "peek",
        "find",
        "splice",
        "push",
        "pop",
        "shift",
        "unshift",
        "reverse",
        "sort",
        "remove",
        "toString",
        "toLocaleString"
      ]);
      Object.defineProperty(ObservableArray.prototype, "length", {
        enumerable: false,
        configurable: true,
        get: function () {
          return this.$mobx.getArrayLength();
        },
        set: function (newLength) {
          this.$mobx.setArrayLength(newLength);
        }
      });
      [
        "every",
        "filter",
        "forEach",
        "indexOf",
        "join",
        "lastIndexOf",
        "map",
        "reduce",
        "reduceRight",
        "slice",
        "some"
      ].forEach(function (funcName) {
        var baseFunc = Array.prototype[funcName];
        invariant(typeof baseFunc === "function", "Base function not defined on Array prototype: '" + funcName + "'");
        addHiddenProp(ObservableArray.prototype, funcName, function () {
          this.$mobx.atom.reportObserved();
          return baseFunc.apply(this.$mobx.values, arguments);
        });
      });
      var ENTRY_0 = {
        configurable: true,
        enumerable: false,
        set: createArraySetter(0),
        get: createArrayGetter(0)
      };
      function createArrayBufferItem(index) {
        var set = createArraySetter(index);
        var get = createArrayGetter(index);
        Object.defineProperty(ObservableArray.prototype, "" + index, {
          enumerable: false,
          configurable: true,
          set: set, get: get
        });
      }
      function createArraySetter(index) {
        return function (newValue) {
          var adm = this.$mobx;
          var values = adm.values;
          assertUnwrapped(newValue, "Modifiers cannot be used on array values. For non-reactive array values use makeReactive(asFlat(array)).");
          if (index < values.length) {
            checkIfStateModificationsAreAllowed();
            var oldValue = values[index];
            if (hasInterceptors(adm)) {
              var change = interceptChange(adm, {
                type: "update",
                object: adm.array,
                index: index, newValue: newValue
              });
              if (!change)
                return;
              newValue = change.newValue;
            }
            newValue = adm.makeReactiveArrayItem(newValue);
            var changed = (adm.mode === ValueMode.Structure) ? !deepEquals(oldValue, newValue) : oldValue !== newValue;
            if (changed) {
              values[index] = newValue;
              adm.notifyArrayChildUpdate(index, newValue, oldValue);
            }
          }
          else if (index === values.length) {
            adm.spliceWithArray(index, 0, [newValue]);
          }
          else
            throw new Error("[mobx.array] Index out of bounds, " + index + " is larger than " + values.length);
        };
      }
      function createArrayGetter(index) {
        return function () {
          var impl = this.$mobx;
          if (impl && index < impl.values.length) {
            impl.atom.reportObserved();
            return impl.values[index];
          }
          console.warn("[mobx.array] Attempt to read an array index (" + index + ") that is out of bounds (" + impl.values.length + "). Please check length first. Out of bound indices will not be tracked by MobX");
          return undefined;
        };
      }
      function reserveArrayBuffer(max) {
        for (var index = OBSERVABLE_ARRAY_BUFFER_SIZE; index < max; index++)
          createArrayBufferItem(index);
        OBSERVABLE_ARRAY_BUFFER_SIZE = max;
      }
      reserveArrayBuffer(1000);
      function createObservableArray(initialValues, mode, name) {
        return new ObservableArray(initialValues, mode, name);
      }
      function fastArray(initialValues) {
        deprecated("fastArray is deprecated. Please use `observable(asFlat([]))`");
        return createObservableArray(initialValues, ValueMode.Flat, null);
      }
      exports.fastArray = fastArray;
      var isObservableArrayAdministration = createInstanceofPredicate("ObservableArrayAdministration", ObservableArrayAdministration);
      function isObservableArray(thing) {
        return isObject(thing) && isObservableArrayAdministration(thing.$mobx);
      }
      exports.isObservableArray = isObservableArray;
      var ObservableMapMarker = {};
      var ObservableMap = (function () {
        function ObservableMap(initialData, valueModeFunc) {
          var _this = this;
          this.$mobx = ObservableMapMarker;
          this._data = {};
          this._hasMap = {};
          this.name = "ObservableMap@" + getNextId();
          this._keys = new ObservableArray(null, ValueMode.Reference, this.name + ".keys()", true);
          this.interceptors = null;
          this.changeListeners = null;
          this._valueMode = getValueModeFromModifierFunc(valueModeFunc);
          if (this._valueMode === ValueMode.Flat)
            this._valueMode = ValueMode.Reference;
          allowStateChanges(true, function () {
            if (isPlainObject(initialData))
              _this.merge(initialData);
            else if (Array.isArray(initialData))
              initialData.forEach(function (_a) {
                var key = _a[0], value = _a[1];
                return _this.set(key, value);
              });
          });
        }
        ObservableMap.prototype._has = function (key) {
          return typeof this._data[key] !== "undefined";
        };
        ObservableMap.prototype.has = function (key) {
          if (!this.isValidKey(key))
            return false;
          key = "" + key;
          if (this._hasMap[key])
            return this._hasMap[key].get();
          return this._updateHasMapEntry(key, false).get();
        };
        ObservableMap.prototype.set = function (key, value) {
          this.assertValidKey(key);
          key = "" + key;
          var hasKey = this._has(key);
          assertUnwrapped(value, "[mobx.map.set] Expected unwrapped value to be inserted to key '" + key + "'. If you need to use modifiers pass them as second argument to the constructor");
          if (hasInterceptors(this)) {
            var change = interceptChange(this, {
              type: hasKey ? "update" : "add",
              object: this,
              newValue: value,
              name: key
            });
            if (!change)
              return;
            value = change.newValue;
          }
          if (hasKey) {
            this._updateValue(key, value);
          }
          else {
            this._addValue(key, value);
          }
        };
        ObservableMap.prototype.delete = function (key) {
          var _this = this;
          this.assertValidKey(key);
          key = "" + key;
          if (hasInterceptors(this)) {
            var change = interceptChange(this, {
              type: "delete",
              object: this,
              name: key
            });
            if (!change)
              return false;
          }
          if (this._has(key)) {
            var notifySpy = isSpyEnabled();
            var notify = hasListeners(this);
            var change = notify || notifySpy ? {
              type: "delete",
              object: this,
              oldValue: this._data[key].value,
              name: key
            } : null;
            if (notifySpy)
              spyReportStart(change);
            transaction(function () {
              _this._keys.remove(key);
              _this._updateHasMapEntry(key, false);
              var observable = _this._data[key];
              observable.setNewValue(undefined);
              _this._data[key] = undefined;
            }, undefined, false);
            if (notify)
              notifyListeners(this, change);
            if (notifySpy)
              spyReportEnd();
            return true;
          }
          return false;
        };
        ObservableMap.prototype._updateHasMapEntry = function (key, value) {
          var entry = this._hasMap[key];
          if (entry) {
            entry.setNewValue(value);
          }
          else {
            entry = this._hasMap[key] = new ObservableValue(value, ValueMode.Reference, this.name + "." + key + "?", false);
          }
          return entry;
        };
        ObservableMap.prototype._updateValue = function (name, newValue) {
          var observable = this._data[name];
          newValue = observable.prepareNewValue(newValue);
          if (newValue !== UNCHANGED) {
            var notifySpy = isSpyEnabled();
            var notify = hasListeners(this);
            var change = notify || notifySpy ? {
              type: "update",
              object: this,
              oldValue: observable.value,
              name: name, newValue: newValue
            } : null;
            if (notifySpy)
              spyReportStart(change);
            observable.setNewValue(newValue);
            if (notify)
              notifyListeners(this, change);
            if (notifySpy)
              spyReportEnd();
          }
        };
        ObservableMap.prototype._addValue = function (name, newValue) {
          var _this = this;
          transaction(function () {
            var observable = _this._data[name] = new ObservableValue(newValue, _this._valueMode, _this.name + "." + name, false);
            newValue = observable.value;
            _this._updateHasMapEntry(name, true);
            _this._keys.push(name);
          }, undefined, false);
          var notifySpy = isSpyEnabled();
          var notify = hasListeners(this);
          var change = notify || notifySpy ? {
            type: "add",
            object: this,
            name: name, newValue: newValue
          } : null;
          if (notifySpy)
            spyReportStart(change);
          if (notify)
            notifyListeners(this, change);
          if (notifySpy)
            spyReportEnd();
        };
        ObservableMap.prototype.get = function (key) {
          key = "" + key;
          if (this.has(key))
            return this._data[key].get();
          return undefined;
        };
        ObservableMap.prototype.keys = function () {
          return arrayAsIterator(this._keys.slice());
        };
        ObservableMap.prototype.values = function () {
          return arrayAsIterator(this._keys.map(this.get, this));
        };
        ObservableMap.prototype.entries = function () {
          var _this = this;
          return arrayAsIterator(this._keys.map(function (key) { return [key, _this.get(key)]; }));
        };
        ObservableMap.prototype.forEach = function (callback, thisArg) {
          var _this = this;
          this.keys().forEach(function (key) { return callback.call(thisArg, _this.get(key), key); });
        };
        ObservableMap.prototype.merge = function (other) {
          var _this = this;
          transaction(function () {
            if (isObservableMap(other))
              other.keys().forEach(function (key) { return _this.set(key, other.get(key)); });
            else
              Object.keys(other).forEach(function (key) { return _this.set(key, other[key]); });
          }, undefined, false);
          return this;
        };
        ObservableMap.prototype.clear = function () {
          var _this = this;
          transaction(function () {
            untracked(function () {
              _this.keys().forEach(_this.delete, _this);
            });
          }, undefined, false);
        };
        Object.defineProperty(ObservableMap.prototype, "size", {
          get: function () {
            return this._keys.length;
          },
          enumerable: true,
          configurable: true
        });
        ObservableMap.prototype.toJS = function () {
          var _this = this;
          var res = {};
          this.keys().forEach(function (key) { return res[key] = _this.get(key); });
          return res;
        };
        ObservableMap.prototype.toJs = function () {
          deprecated("toJs is deprecated, use toJS instead");
          return this.toJS();
        };
        ObservableMap.prototype.toJSON = function () {
          return this.toJS();
        };
        ObservableMap.prototype.isValidKey = function (key) {
          if (key === null || key === undefined)
            return false;
          if (typeof key !== "string" && typeof key !== "number" && typeof key !== "boolean")
            return false;
          return true;
        };
        ObservableMap.prototype.assertValidKey = function (key) {
          if (!this.isValidKey(key))
            throw new Error("[mobx.map] Invalid key: '" + key + "'");
        };
        ObservableMap.prototype.toString = function () {
          var _this = this;
          return this.name + "[{ " + this.keys().map(function (key) { return (key + ": " + ("" + _this.get(key))); }).join(", ") + " }]";
        };
        ObservableMap.prototype.observe = function (listener, fireImmediately) {
          invariant(fireImmediately !== true, "`observe` doesn't support the fire immediately property for observable maps.");
          return registerListener(this, listener);
        };
        ObservableMap.prototype.intercept = function (handler) {
          return registerInterceptor(this, handler);
        };
        return ObservableMap;
      }());
      exports.ObservableMap = ObservableMap;
      declareIterator(ObservableMap.prototype, function () {
        return this.entries();
      });
      function map(initialValues, valueModifier) {
        return new ObservableMap(initialValues, valueModifier);
      }
      exports.map = map;
      var isObservableMap = createInstanceofPredicate("ObservableMap", ObservableMap);
      exports.isObservableMap = isObservableMap;
      var ObservableObjectAdministration = (function () {
        function ObservableObjectAdministration(target, name, mode) {
          this.target = target;
          this.name = name;
          this.mode = mode;
          this.values = {};
          this.changeListeners = null;
          this.interceptors = null;
        }
        ObservableObjectAdministration.prototype.observe = function (callback, fireImmediately) {
          invariant(fireImmediately !== true, "`observe` doesn't support the fire immediately property for observable objects.");
          return registerListener(this, callback);
        };
        ObservableObjectAdministration.prototype.intercept = function (handler) {
          return registerInterceptor(this, handler);
        };
        //TODO hcr 添加获取原始值的方法
        ObservableObjectAdministration.prototype.getRealValue = function (name) {
          if (this.values && this.values[name] && this.values[name].get){
            return this.values[name].get();
          }else{
            return undefined;
          }
        };

        return ObservableObjectAdministration;
      }());
      function asObservableObject(target, name, mode) {
        if (mode === void 0) { mode = ValueMode.Recursive; }
        if (isObservableObject(target))
          return target.$mobx;
        if (!isPlainObject(target))
          name = target.constructor.name + "@" + getNextId();
        if (!name)
          name = "ObservableObject@" + getNextId();
        var adm = new ObservableObjectAdministration(target, name, mode);
        addHiddenFinalProp(target, "$mobx", adm);
        return adm;
      }
      function setObservableObjectInstanceProperty(adm, propName, descriptor) {
        if (adm.values[propName]) {
          invariant("value" in descriptor, "cannot redefine property " + propName);
          adm.target[propName] = descriptor.value;
        }
        else {
          if ("value" in descriptor)
            defineObservableProperty(adm, propName, descriptor.value, true, undefined);
          else
            defineObservableProperty(adm, propName, descriptor.get, true, descriptor.set);
        }
      }
      function defineObservableProperty(adm, propName, newValue, asInstanceProperty, setter) {
        if (asInstanceProperty)
          assertPropertyConfigurable(adm.target, propName);
        var observable;
        var name = adm.name + "." + propName;
        var isComputed = true;
        if (isComputedValue(newValue)) {
          observable = newValue;
          newValue.name = name;
          if (!newValue.scope)
            newValue.scope = adm.target;
        }
        else if (typeof newValue === "function" && newValue.length === 0 && !isAction(newValue)) {
          observable = new ComputedValue(newValue, adm.target, false, name, setter);
        }
        else if (getModifier(newValue) === ValueMode.Structure && typeof newValue.value === "function" && newValue.value.length === 0) {
          observable = new ComputedValue(newValue.value, adm.target, true, name, setter);
        }
        else {
          isComputed = false;
          if (hasInterceptors(adm)) {
            var change = interceptChange(adm, {
              object: adm.target,
              name: propName,
              type: "add",
              newValue: newValue
            });
            if (!change)
              return;
            newValue = change.newValue;
          }
          observable = new ObservableValue(newValue, adm.mode, name, false);
          newValue = observable.value;
        }
        adm.values[propName] = observable;
        if (asInstanceProperty) {
          Object.defineProperty(adm.target, propName, isComputed ? generateComputedPropConfig(propName) : generateObservablePropConfig(propName));
        }
        if (!isComputed)
          notifyPropertyAddition(adm, adm.target, propName, newValue);
      }
      var observablePropertyConfigs = {};
      var computedPropertyConfigs = {};
      function generateObservablePropConfig(propName) {
        var config = observablePropertyConfigs[propName];
        if (config)
          return config;
        return observablePropertyConfigs[propName] = {
          configurable: true,
          enumerable: true,
          get: function () {
            //return this.$mobx.values[propName].get();
            return getPropertyValue(this, propName);
          },
          set: function (v) {
            setPropertyValue(this, propName, v);
          }
        };
      }
      function generateComputedPropConfig(propName) {
        var config = computedPropertyConfigs[propName];
        if (config)
          return config;
        return computedPropertyConfigs[propName] = {
          configurable: true,
          enumerable: false,
          get: function () {
            //return this.$mobx.values[propName].get();
            return getPropertyValue(this, propName);
          },
          set: function (v) {
            return this.$mobx.values[propName].set(v);
          }
        };
      }

//hcr 支持读的interceptor
      function getPropertyValue(instance, name){
        var adm = instance.$mobx;
        var value = adm.values[name].get();
        if (hasInterceptors(adm)) {
          var change = interceptChange(adm, {
            type: "read",
            object: instance,
            name: name, value: value
          });
          if (change){
            value = change.value;
          }
        }
        return value;
      }

      function setPropertyValue(instance, name, newValue) {
        var adm = instance.$mobx;
        var observable = adm.values[name];
        if (hasInterceptors(adm)) {
          var change = interceptChange(adm, {
            type: "update",
            object: instance,
            name: name, newValue: newValue
          });
          if (!change)
            return;
          newValue = change.newValue;
        }
        newValue = observable.prepareNewValue(newValue);
        if (newValue !== UNCHANGED) {
          var notify = hasListeners(adm);
          var notifySpy = isSpyEnabled();
          var change = notify || notifySpy ? {
            type: "update",
            object: instance,
            oldValue: observable.value,
            name: name, newValue: newValue
          } : null;
          if (notifySpy)
            spyReportStart(change);
          observable.setNewValue(newValue);
          if (notify)
            notifyListeners(adm, change);
          if (notifySpy)
            spyReportEnd();
        }
      }
      function notifyPropertyAddition(adm, object, name, newValue) {
        var notify = hasListeners(adm);
        var notifySpy = isSpyEnabled();
        var change = notify || notifySpy ? {
          type: "add",
          object: object, name: name, newValue: newValue
        } : null;
        if (notifySpy)
          spyReportStart(change);
        if (notify)
          notifyListeners(adm, change);
        if (notifySpy)
          spyReportEnd();
      }
      var isObservableObjectAdministration = createInstanceofPredicate("ObservableObjectAdministration", ObservableObjectAdministration);
      function isObservableObject(thing) {
        if (isObject(thing)) {
          runLazyInitializers(thing);
          return isObservableObjectAdministration(thing.$mobx);
        }
        return false;
      }
      exports.isObservableObject = isObservableObject;
      var UNCHANGED = {};
      var ObservableValue = (function (_super) {
        __extends(ObservableValue, _super);
        function ObservableValue(value, mode, name, notifySpy) {
          if (name === void 0) { name = "ObservableValue@" + getNextId(); }
          if (notifySpy === void 0) { notifySpy = true; }
          _super.call(this, name);
          this.mode = mode;
          this.hasUnreportedChange = false;
          this.value = undefined;
          var _a = getValueModeFromValue(value, ValueMode.Recursive), childmode = _a[0], unwrappedValue = _a[1];
          if (this.mode === ValueMode.Recursive)
            this.mode = childmode;
          this.value = makeChildObservable(unwrappedValue, this.mode, this.name);
          if (notifySpy && isSpyEnabled()) {
            spyReport({ type: "create", object: this, newValue: this.value });
          }
        }
        ObservableValue.prototype.set = function (newValue) {
          var oldValue = this.value;
          newValue = this.prepareNewValue(newValue);
          if (newValue !== UNCHANGED) {
            var notifySpy = isSpyEnabled();
            if (notifySpy) {
              spyReportStart({
                type: "update",
                object: this,
                newValue: newValue, oldValue: oldValue
              });
            }
            this.setNewValue(newValue);
            if (notifySpy)
              spyReportEnd();
          }
        };
        ObservableValue.prototype.prepareNewValue = function (newValue) {
          assertUnwrapped(newValue, "Modifiers cannot be used on non-initial values.");
          checkIfStateModificationsAreAllowed();
          if (hasInterceptors(this)) {
            var change = interceptChange(this, { object: this, type: "update", newValue: newValue });
            if (!change)
              return UNCHANGED;
            newValue = change.newValue;
          }
          var changed = valueDidChange(this.mode === ValueMode.Structure, this.value, newValue);
          if (changed)
            return makeChildObservable(newValue, this.mode, this.name);
          return UNCHANGED;
        };
        ObservableValue.prototype.setNewValue = function (newValue) {
          var oldValue = this.value;
          this.value = newValue;
          this.reportChanged();
          if (hasListeners(this))
            notifyListeners(this, [newValue, oldValue]);
        };
        ObservableValue.prototype.get = function () {
          this.reportObserved();
          return this.value;
        };
        ObservableValue.prototype.intercept = function (handler) {
          return registerInterceptor(this, handler);
        };
        ObservableValue.prototype.observe = function (listener, fireImmediately) {
          if (fireImmediately)
            listener(this.value, undefined);
          return registerListener(this, listener);
        };
        ObservableValue.prototype.toJSON = function () {
          return this.get();
        };
        ObservableValue.prototype.toString = function () {
          return this.name + "[" + this.value + "]";
        };
        return ObservableValue;
      }(BaseAtom));
      var isObservableValue = createInstanceofPredicate("ObservableValue", ObservableValue);
      function getAtom(thing, property) {
        if (typeof thing === "object" && thing !== null) {
          if (isObservableArray(thing)) {
            invariant(property === undefined, "It is not possible to get index atoms from arrays");
            return thing.$mobx.atom;
          }
          if (isObservableMap(thing)) {
            if (property === undefined)
              return getAtom(thing._keys);
            var observable_2 = thing._data[property] || thing._hasMap[property];
            invariant(!!observable_2, "the entry '" + property + "' does not exist in the observable map '" + getDebugName(thing) + "'");
            return observable_2;
          }
          runLazyInitializers(thing);
          if (isObservableObject(thing)) {
            invariant(!!property, "please specify a property");
            var observable_3 = thing.$mobx.values[property];
            invariant(!!observable_3, "no observable property '" + property + "' found on the observable object '" + getDebugName(thing) + "'");
            return observable_3;
          }
          if (isAtom(thing) || isComputedValue(thing) || isReaction(thing)) {
            return thing;
          }
        }
        else if (typeof thing === "function") {
          if (isReaction(thing.$mobx)) {
            return thing.$mobx;
          }
        }
        invariant(false, "Cannot obtain atom from " + thing);
      }
      function getAdministration(thing, property) {
        invariant(thing, "Expecting some object");
        if (property !== undefined)
          return getAdministration(getAtom(thing, property));
        if (isAtom(thing) || isComputedValue(thing) || isReaction(thing))
          return thing;
        if (isObservableMap(thing))
          return thing;
        runLazyInitializers(thing);
        if (thing.$mobx)
          return thing.$mobx;
        invariant(false, "Cannot obtain administration from " + thing);
      }
      function getDebugName(thing, property) {
        var named;
        if (property !== undefined)
          named = getAtom(thing, property);
        else if (isObservableObject(thing) || isObservableMap(thing))
          named = getAdministration(thing);
        else
          named = getAtom(thing);
        return named.name;
      }
      function createClassPropertyDecorator(onInitialize, get, set, enumerable, allowCustomArguments) {
        function classPropertyDecorator(target, key, descriptor, customArgs, argLen) {
          invariant(allowCustomArguments || quacksLikeADecorator(arguments), "This function is a decorator, but it wasn't invoked like a decorator");
          if (!descriptor) {
            var newDescriptor = {
              enumerable: enumerable,
              configurable: true,
              get: function () {
                if (!this.__mobxInitializedProps || this.__mobxInitializedProps[key] !== true)
                  typescriptInitializeProperty(this, key, undefined, onInitialize, customArgs, descriptor);
                return get.call(this, key);
              },
              set: function (v) {
                if (!this.__mobxInitializedProps || this.__mobxInitializedProps[key] !== true) {
                  typescriptInitializeProperty(this, key, v, onInitialize, customArgs, descriptor);
                }
                else {
                  set.call(this, key, v);
                }
              }
            };
            if (arguments.length < 3 || arguments.length === 5 && argLen < 3) {
              Object.defineProperty(target, key, newDescriptor);
            }
            return newDescriptor;
          }
          else {
            if (!hasOwnProperty(target, "__mobxLazyInitializers")) {
              addHiddenProp(target, "__mobxLazyInitializers", (target.__mobxLazyInitializers && target.__mobxLazyInitializers.slice()) || []);
            }
            var value_1 = descriptor.value, initializer_1 = descriptor.initializer;
            target.__mobxLazyInitializers.push(function (instance) {
              onInitialize(instance, key, (initializer_1 ? initializer_1.call(instance) : value_1), customArgs, descriptor);
            });
            return {
              enumerable: enumerable, configurable: true,
              get: function () {
                if (this.__mobxDidRunLazyInitializers !== true)
                  runLazyInitializers(this);
                return get.call(this, key);
              },
              set: function (v) {
                if (this.__mobxDidRunLazyInitializers !== true)
                  runLazyInitializers(this);
                set.call(this, key, v);
              }
            };
          }
        }
        if (allowCustomArguments) {
          return function () {
            if (quacksLikeADecorator(arguments))
              return classPropertyDecorator.apply(null, arguments);
            var outerArgs = arguments;
            var argLen = arguments.length;
            return function (target, key, descriptor) { return classPropertyDecorator(target, key, descriptor, outerArgs, argLen); };
          };
        }
        return classPropertyDecorator;
      }
      function typescriptInitializeProperty(instance, key, v, onInitialize, customArgs, baseDescriptor) {
        if (!hasOwnProperty(instance, "__mobxInitializedProps"))
          addHiddenProp(instance, "__mobxInitializedProps", {});
        instance.__mobxInitializedProps[key] = true;
        onInitialize(instance, key, v, customArgs, baseDescriptor);
      }
      function runLazyInitializers(instance) {
        if (instance.__mobxDidRunLazyInitializers === true)
          return;
        if (instance.__mobxLazyInitializers) {
          addHiddenProp(instance, "__mobxDidRunLazyInitializers", true);
          instance.__mobxDidRunLazyInitializers && instance.__mobxLazyInitializers.forEach(function (initializer) { return initializer(instance); });
        }
      }
      function quacksLikeADecorator(args) {
        return (args.length === 2 || args.length === 3) && typeof args[1] === "string";
      }
      function iteratorSymbol() {
        return (typeof Symbol === "function" && Symbol.iterator) || "@@iterator";
      }
      var IS_ITERATING_MARKER = "__$$iterating";
      function arrayAsIterator(array) {
        invariant(array[IS_ITERATING_MARKER] !== true, "Illegal state: cannot recycle array as iterator");
        addHiddenFinalProp(array, IS_ITERATING_MARKER, true);
        var idx = -1;
        addHiddenFinalProp(array, "next", function next() {
          idx++;
          return {
            done: idx >= this.length,
            value: idx < this.length ? this[idx] : undefined
          };
        });
        return array;
      }
      function declareIterator(prototType, iteratorFactory) {
        addHiddenFinalProp(prototType, iteratorSymbol(), iteratorFactory);
      }
      var SimpleEventEmitter = (function () {
        function SimpleEventEmitter() {
          this.listeners = [];
          deprecated("extras.SimpleEventEmitter is deprecated and will be removed in the next major release");
        }
        SimpleEventEmitter.prototype.emit = function () {
          var listeners = this.listeners.slice();
          for (var i = 0, l = listeners.length; i < l; i++)
            listeners[i].apply(null, arguments);
        };
        SimpleEventEmitter.prototype.on = function (listener) {
          var _this = this;
          this.listeners.push(listener);
          return once(function () {
            var idx = _this.listeners.indexOf(listener);
            if (idx !== -1)
              _this.listeners.splice(idx, 1);
          });
        };
        SimpleEventEmitter.prototype.once = function (listener) {
          var subscription = this.on(function () {
            subscription();
            listener.apply(this, arguments);
          });
          return subscription;
        };
        return SimpleEventEmitter;
      }());
      exports.SimpleEventEmitter = SimpleEventEmitter;
      var EMPTY_ARRAY = [];
      Object.freeze(EMPTY_ARRAY);
      function getNextId() {
        return ++globalState.mobxGuid;
      }
      function invariant(check, message, thing) {
        if (!check)
          throw new Error("[mobx] Invariant failed: " + message + (thing ? " in '" + thing + "'" : ""));
      }
      var deprecatedMessages = [];
      function deprecated(msg) {
        if (deprecatedMessages.indexOf(msg) !== -1)
          return;
        deprecatedMessages.push(msg);
        console.error("[mobx] Deprecated: " + msg);
      }
      function once(func) {
        var invoked = false;
        return function () {
          if (invoked)
            return;
          invoked = true;
          return func.apply(this, arguments);
        };
      }
      var noop = function () { };
      function unique(list) {
        var res = [];
        list.forEach(function (item) {
          if (res.indexOf(item) === -1)
            res.push(item);
        });
        return res;
      }
      function joinStrings(things, limit, separator) {
        if (limit === void 0) { limit = 100; }
        if (separator === void 0) { separator = " - "; }
        if (!things)
          return "";
        var sliced = things.slice(0, limit);
        return "" + sliced.join(separator) + (things.length > limit ? " (... and " + (things.length - limit) + "more)" : "");
      }
      function isObject(value) {
        return value !== null && typeof value === "object";
      }
      function isPlainObject(value) {
        if (value === null || typeof value !== "object")
          return false;
        var proto = Object.getPrototypeOf(value);
        return proto === Object.prototype || proto === null;
      }
      function objectAssign() {
        var res = arguments[0];
        for (var i = 1, l = arguments.length; i < l; i++) {
          var source = arguments[i];
          for (var key in source)
            if (hasOwnProperty(source, key)) {
              res[key] = source[key];
            }
        }
        return res;
      }
      function valueDidChange(compareStructural, oldValue, newValue) {
        return compareStructural
          ? !deepEquals(oldValue, newValue)
          : oldValue !== newValue;
      }
      var prototypeHasOwnProperty = Object.prototype.hasOwnProperty;
      function hasOwnProperty(object, propName) {
        return prototypeHasOwnProperty.call(object, propName);
      }
      function makeNonEnumerable(object, propNames) {
        for (var i = 0; i < propNames.length; i++) {
          addHiddenProp(object, propNames[i], object[propNames[i]]);
        }
      }
      function addHiddenProp(object, propName, value) {
        Object.defineProperty(object, propName, {
          enumerable: false,
          writable: true,
          configurable: true,
          value: value
        });
      }
      function addHiddenFinalProp(object, propName, value) {
        Object.defineProperty(object, propName, {
          enumerable: false,
          writable: false,
          configurable: true,
          value: value
        });
      }
      function isPropertyConfigurable(object, prop) {
        var descriptor = Object.getOwnPropertyDescriptor(object, prop);
        return !descriptor || (descriptor.configurable !== false && descriptor.writable !== false);
      }
      function assertPropertyConfigurable(object, prop) {
        invariant(isPropertyConfigurable(object, prop), "Cannot make property '" + prop + "' observable, it is not configurable and writable in the target object");
      }
      function getEnumerableKeys(obj) {
        var res = [];
        for (var key in obj)
          res.push(key);
        return res;
      }
      function deepEquals(a, b) {
        if (a === null && b === null)
          return true;
        if (a === undefined && b === undefined)
          return true;
        var aIsArray = isArrayLike(a);
        if (aIsArray !== isArrayLike(b)) {
          return false;
        }
        else if (aIsArray) {
          if (a) {
            if (a.length !== b.length)
              return false;
            for (var i = a.length - 1; i >= 0; i--)
              if (!deepEquals(a[i], b[i]))
                return false;
          }
          return true;
        }
        else if (typeof a === "object" && typeof b === "object") {
          if (a === null || b === null)
            return false;
          if (getEnumerableKeys(a).length !== getEnumerableKeys(b).length)
            return false;
          for (var prop in a) {
            if (!(prop in b))
              return false;
            if (!deepEquals(a[prop], b[prop]))
              return false;
          }
          return true;
        }
        return a === b;
      }
      function createInstanceofPredicate(name, clazz) {
        var propName = "isMobX" + name;
        clazz.prototype[propName] = true;
        return function (x) {
          return isObject(x) && x[propName] === true;
        };
      }
      function isArrayLike(x) {
        return Array.isArray(x) || isObservableArray(x);
      }
      exports.isArrayLike = isArrayLike;

      function fromPromise(origPromise,oldPromise) {
        if(!(origPromise && origPromise.then)){
          return origPromise;
        }

        if (origPromise.isPromiseBasedObservable === true){
          return origPromise;
        }
        origPromise.then(
          action("observableFromPromise-resolve", (value) => {
            origPromise.value = value;
            origPromise.state = "FULFILLED";
          }),
          action("observableFromPromise-reject", (reason) => {
            origPromise.value = reason;
            origPromise.state = "REJECTED";
          })
        )
        const oldData = oldPromise && oldPromise.state === "FULFILLED" ? oldPromise.value: undefined;
        extendObservable(
          origPromise,
          {
            value: oldData,
            state: "PENDING",
          },
          {},
          { deep: false }
        )

        origPromise.isPromiseBasedObservable = true;
        return origPromise;
      }
      exports.fromPromise = fromPromise;

      function asyncRun(obValue,asyncFn,name) {
        //name应该模式是asyncFn的名字
        name = "_mobxObservablePromise" + name;
        let oldObservablePromise = obValue[name];
        if(oldObservablePromise && oldObservablePromise.state == "PENDING"){
          return;
        }else if(oldObservablePromise && (oldObservablePromise.state == "FULFILLED" || oldObservablePromise.state == "REJECTED")){
          delete obValue[name];
          return;
        }

        let currentPromise = asyncFn(obValue);
        //返回值是promise
        if(currentPromise && currentPromise.then){
          let observablePromise = extendObservable(
            currentPromise,
            {
              value: obValue
            },
            {},
            { deep: false }
          );
          observablePromise.state = "PENDING";


          currentPromise.then(
            action((value) => {
              observablePromise.state = "FULFILLED";
              observablePromise.value = value;
            }),
            action((reason) => {
              observablePromise.state = "REJECTED";
              observablePromise.value = reason;
            })
          )
        }else{
          return;
        }
      }
      exports.asyncRun = asyncRun;


    }).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
  },{}]},{},[1])(1)
});
