import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxLabel from  '../../../../../components/wx/WxLabel/WxLabel'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxInput from  '../../../../../components/wx/WxInput/WxInput'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import React from 'react';
const WxLabel =  componentRenderWrapper(_WxLabel);
const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxInput =  componentRenderWrapper(_WxInput);
const WxView =  componentRenderWrapper(_WxView);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/user/userDialog/login.w');
import wxPageDeclaration from './login';
import wxPageStyle from './login.css'; 
import wxPageConfig from './login.json'; 
export default class __loginPage__ extends PageComponent {
	constructor(...args){
		super(...args);
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var __pageid__=$state.__pageid__;
		var useSmsService=$state.useSmsService;
		var hidden=$state.hidden;
		var btnDisabled=$state.btnDisabled;
		var ctrl=$state.ctrl;
		var input2=$state.input2;
		var input1=$state.input1;
		var show=$state.show;
		var isTwoFactorLogin=$state.isTwoFactorLogin;
		var loginData=$state.loginData;
		var required=$state.required;
		var toptips=$state.toptips;
		var input3_2=$state.input3_2;
		var debounce=$state.debounce;
		var current=$state.current;
		var password=$state.password;
		var readonly=$state.readonly;
		var compid=$state.compid;
		var label9=$state.label9;
		var input3_1=$state.input3_1;
		var button1=$state.button1;
		var _userdata=$state._userdata;
		var val=$state.val;
		var wx=$state.wx;
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var button2=$state.button2;
		var button3=$state.button3;
		var label10=$state.label10;
		var message=$state.message;
		var btnLabel=$state.btnLabel;
		var label3=$state.label3;
		var useRegister=$state.useRegister;
		var form=$state.form;
		var name=$state.name;
		var x=$state.x;
		var typeClass=$state.typeClass;
		var page=$state.page;
		var form3=$state.form3;
		return (
<WxView navigationBarTitleText="登录" id="page" data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('null x-page-wx')}>  
    
  <WxView id="inVisibleCompContainer1">
  </WxView>  
  
  <WxView id="form" data-compid={((_exRun('form.compid','form'))(form))} hidden={((_exRun('form.hidden','form'))(form))} className={this.getModuledCss('weui-cells weui-cells_form weui-cell-full')}> 
    <WxView id="cell1" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd1" className={this.getModuledCss('weui-cell__hd')}> 
        <WxView id="label1" className={this.getModuledCss('weui-label')}><WxLabel id="label1_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("登录账号")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("登录账号")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("登录账号")'))()+'===""'))()+''))()+')?"":("登录账号")'))()}</WxLabel></WxView> 
      </WxView>  
      <WxView id="bd1" className={this.getModuledCss('weui-cell__bd')}> 
        <WxInput id="input1" placeholder="可使用用户名、手机号、邮箱" value={((_exRun('loginData.current.name','loginData'))(loginData))} type="text" data-compid={((_exRun('input1.compid','input1'))(input1))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input1.compid','input1'))(input1)) + ':onChange'} data-bindinput={((_exRun('input1.compid','input1'))(input1)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name.hidden','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('loginData.current','loginData'))(loginData)+'','loginData'))(loginData)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+'','loginData'))(loginData))} className={this.getModuledCss(((_exRun('loginData.current._userdata.name.required.val?"x-wx-required":""','loginData'))(loginData)) + ' weui-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')?"readonly":""','loginData'))(loginData)))}/> 
      </WxView> 
    </WxView>  
    <WxView id="cell2" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd21" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label2" className={this.getModuledCss('weui-label')}><WxLabel id="label2_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("密码")'))()+'===""'))()+''))()+')?"":("密码")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd2" className={this.getModuledCss('weui-cell__bd')}> 
        <WxInput password={(true)} id="input2" placeholder="密码" value={((_exRun('loginData.current.password','loginData'))(loginData))} type="text" data-compid={((_exRun('input2.compid','input2'))(input2))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input2.compid','input2'))(input2)) + ':onChange'} data-bindinput={((_exRun('input2.compid','input2'))(input2)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password.hidden','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('loginData.current','loginData'))(loginData)+'','loginData'))(loginData)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+'','loginData'))(loginData))} className={this.getModuledCss(((_exRun('loginData.current._userdata.password.required.val?"x-wx-required":""','loginData'))(loginData)) + ' weui-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')?"readonly":""','loginData'))(loginData)))}/> 
      </WxView> 
      <WxView id="ft1" hidden={((_exRun('!'+(__exRun('ctrl.current.useSmsService','ctrl'))(ctrl)+'','ctrl'))(ctrl))} className={this.getModuledCss('weui-cell__ft')}> 
        <WxView id="button3" data-compid={((_exRun('button3.compid','button3'))(button3))} data-bindtap="btnForgetClick" data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-vcode-btn')}> 
          <WxLabel __id="id_34" id="label4">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("忘记密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("忘记密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("忘记密码")'))()+'===""'))()+''))()+')?"":("忘记密码")'))()}</WxLabel>
        </WxView>
      </WxView>       
    </WxView> 
  </WxView>  
  <WxView id="form3" data-compid={((_exRun('form3.compid','form3'))(form3))} hidden={((_exRun('form3.hidden','form3'))(form3))} className={this.getModuledCss('weui-cells weui-cells_form weui-cell-full')}> 
    <WxView id="cell3_1" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd3_1" className={this.getModuledCss('weui-cell__hd')}> 
        <WxView id="label3_1" className={this.getModuledCss('weui-label')}><WxLabel id="label3_1_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("手机号")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("手机号")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("手机号")'))()+'===""'))()+''))()+')?"":("手机号")'))()}</WxLabel></WxView> 
      </WxView>  
      <WxView id="bd3_1" className={this.getModuledCss('weui-cell__bd')}> 
        <WxInput id="input3_1" placeholder="请输入手机号" value={((_exRun('loginData.current.name','loginData'))(loginData))} type="text" data-compid={((_exRun('input3_1.compid','input3_1'))(input3_1))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input3_1.compid','input3_1'))(input3_1)) + ':onChange'} data-bindinput={((_exRun('input3_1.compid','input3_1'))(input3_1)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name.hidden','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('loginData.current','loginData'))(loginData)+'','loginData'))(loginData)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+'','loginData'))(loginData))} className={this.getModuledCss(((_exRun('loginData.current._userdata.name.required.val?"x-wx-required":""','loginData'))(loginData)) + ' weui-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.name.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')?"readonly":""','loginData'))(loginData)))}/> 
      </WxView> 
    </WxView>  
    <WxView id="cell3_2" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd3_21" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label3_2" className={this.getModuledCss('weui-label')}><WxLabel id="label3_2_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("验证码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("验证码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("验证码")'))()+'===""'))()+''))()+')?"":("验证码")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd3_2" className={this.getModuledCss('weui-cell__bd')}> 
        <WxInput password={(true)} id="input3_2" placeholder="请输入验证码" value={((_exRun('loginData.current.password','loginData'))(loginData))} type="text" data-compid={((_exRun('input3_2.compid','input3_2'))(input3_2))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input3_2.compid','input3_2'))(input3_2)) + ':onChange'} data-bindinput={((_exRun('input3_2.compid','input3_2'))(input3_2)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password.hidden','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('loginData.current','loginData'))(loginData)+'','loginData'))(loginData)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+')','loginData'))(loginData)+'','loginData'))(loginData)+'','loginData'))(loginData))} className={this.getModuledCss(((_exRun('loginData.current._userdata.password.required.val?"x-wx-required":""','loginData'))(loginData)) + ' weui-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('loginData.current._userdata','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password','loginData'))(loginData)+'','loginData'))(loginData)+'&&'+(__exRun('loginData.current._userdata.password.readonly','loginData'))(loginData)+'','loginData'))(loginData)+')?"readonly":""','loginData'))(loginData)))}/> 
      </WxView>
      <WxView __id="id_31" id="ft4" className={this.getModuledCss('weui-cell__ft')}> 
        <WxView __id="id_32" id="button2" data-compid={((_exRun('button2.compid','button2'))(button2))} data-bindtap="sendSmsBtnClick" data-pageid={(__pageid__)} bindtap="dispatchBind" disabled={((_exRun('ctrl.current.btnDisabled','ctrl'))(ctrl))} className={this.getModuledCss('weui-vcode-btn')}> 
          <WxLabel __id="id_33" id="label_vcode-btn">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===null','ctrl'))(ctrl)+'||'+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===undefined','ctrl'))(ctrl)+'','ctrl'))(ctrl)+'||'+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===""','ctrl'))(ctrl)+'','ctrl'))(ctrl)+')?"":('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)}</WxLabel>
        </WxView>
      </WxView>       
    </WxView> 
  </WxView>
  <WxView id="row4" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col1" className={this.getModuledCss('x-col x-col-10')}/>  
    <WxView id="col2" className={this.getModuledCss('x-col')}> 
  		<WxButton id="button1" type="primary" loading={((_exRun('button1.debounce','button1'))(button1))} data-compid={((_exRun('button1.compid','button1'))(button1))} data-bindtap="loginBtnClick" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn btn-ok')}><WxIcon id="button1-icon" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/><WxView id="button1-label" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("登录")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("登录")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("登录")'))()+'===""'))()+''))()+')?"":("登录")'))()}</WxView></WxButton>  
    </WxView>  
    <WxView id="col3" className={this.getModuledCss('x-col x-col-10')}/> 
  </WxView> 
  <WxView id="row5" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col13" className={this.getModuledCss('x-col x-col-10')}/>  
    <WxView id="col15" style={__styleToJSON('text-align: center')} className={this.getModuledCss('x-col')}> 
      <WxLabel id="label3" style={__styleToJSON('color:#2fa4e7')} data-compid={((_exRun('label3.compid','label3'))(label3))} data-bindtap="regBtnClick" data-pageid={(__pageid__)} bindtap="dispatchBind" hidden={((_exRun('!'+(__exRun('ctrl.current.useRegister','ctrl'))(ctrl)+'','ctrl'))(ctrl))}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("用户注册")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("用户注册")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("用户注册")'))()+'===""'))()+''))()+')?"":("用户注册")'))()}</WxLabel> 
    </WxView>  
    <WxView id="col14" style={__styleToJSON('text-align: center;')} hidden={((_exRun(''+(__exRun('!'+(__exRun('ctrl.current.useSmsService','ctrl'))(ctrl)+'','ctrl'))(ctrl)+'||'+(__exRun('ctrl.current.isTwoFactorLogin','ctrl'))(ctrl)+'','ctrl'))(ctrl))} className={this.getModuledCss('x-col')}> 
      <WxLabel id="label9" style={__styleToJSON('color:#2fa4e7')} data-compid={((_exRun('label9.compid','label9'))(label9))} data-bindtap="$evtH_label9_tap" data-pageid={(__pageid__)} bindtap="dispatchBind" hidden={((_exRun('label9.hidden','label9'))(label9))}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("短信验证码登录")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("短信验证码登录")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("短信验证码登录")'))()+'===""'))()+''))()+')?"":("短信验证码登录")'))()}</WxLabel> 
      <WxLabel id="label10" style={__styleToJSON('color:#2fa4e7')} data-compid={((_exRun('label10.compid','label10'))(label10))} data-bindtap="$evtH_label10_tap" data-pageid={(__pageid__)} bindtap="dispatchBind" hidden={((_exRun('label10.hidden','label10'))(label10))}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("密码登录")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("密码登录")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("密码登录")'))()+'===""'))()+''))()+')?"":("密码登录")'))()}</WxLabel> 
    </WxView>  
    <WxView id="col16" className={this.getModuledCss('x-col x-col-10')}/> 
  </WxView> 
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto1" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
