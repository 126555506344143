import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './login.user';

 var $g_fns_loginData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['password','name','id','type','_key']);
		}
}; 


 var $g_fns_ctrl = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['useSmsService','useRegister','btnDisabled','id','isTwoFactorLogin','_key','btnLabel']);
		}
}; 


import '../../wrapper/wrapper';
import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../row/css/row.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $refPathFn_input3_1: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current._path ;
}

,
 $refFn_input3_1: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current.name ;
}

,
 $refPathFn_input3_2: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current._path ;
}

,$evtH_label10_tap: function({$event,$row,$data,ctrl,$item,params,$page,loginData,props}){
let $$$args = arguments[0];
	let args={};
	args={"col":"type","item":"loginData.current.type","data":"loginData"};
	args.row=loginData.current;
	args.value="password";
	return $page.$compByCtx('commonOperation1',$event.source).executeOperation('setValue', args, $$$args);

}

,
 $refFn_input3_2: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current.password ;
}

,
 $attrBindFn_hidden_label10: function({$row,ctrl,$comp,params,$page,loginData,props}){
 try{return wx.Util.iif(loginData.current.type!="password",false,true)}catch(e){return ''} ;
}

,
 $attrBindFn_hidden_form3: function({$row,ctrl,$comp,params,$page,loginData,props}){
 try{return wx.Util.iif(loginData.current.type!="password",false,true)}catch(e){return ''} ;
}

,
 $refFn_input2: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current.password ;
}

,$evtH_label9_tap: function({$event,$row,$data,ctrl,$item,params,$page,loginData,props}){
let $$$args = arguments[0];
	let args={};
	args={"col":"type","item":"loginData.current.type","data":"loginData"};
	args.row=loginData.current;
	args.value="smsCode";
	return $page.$compByCtx('commonOperation1',$event.source).executeOperation('setValue', args, $$$args);

}

,
 $refFn_input1: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current.name ;
}

,
 $attrBindFn_hidden_form: function({$row,ctrl,$comp,params,$page,loginData,props}){
 try{return wx.Util.iif(loginData.current.type!="password",true,false)}catch(e){return ''} ;
}

,
 $attrBindFn_hidden_label9: function({$row,ctrl,$comp,params,$page,loginData,props}){
 try{return wx.Util.iif(loginData.current.type!="password",true,false)}catch(e){return ''} ;
}

,
 $refPathFn_input2: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current._path ;
}

,
 $refPathFn_input1: function({$row,ctrl,$comp,params,$page,loginData,props}){
 return loginData.current._path ;
}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"loginData",
					"type":"array",
					"items":{
						"fns":$g_fns_loginData,
						"type":"object",
						"key":"_key",
						"props":{
							"password":{
								"define":"password",
								"label":"密码",
								"type":"string"
							},
							"name":{
								"define":"name",
								"label":"登录账号",
								"type":"string"
							},
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string"
							},
							"type":{
								"define":"type",
								"label":"登录方式",
								"type":"string"
							},
							"_key":{
								"type":"string"
							}
						}
					}
				},
				"initData":[
					{
						"id":"login",
						"type":"password"
					}
				],
				"options":{
					"isMain":false,
					"autoMode":"load",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"loginData",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"ctrl",
					"type":"array",
					"items":{
						"fns":$g_fns_ctrl,
						"type":"object",
						"key":"_key",
						"props":{
							"useSmsService":{
								"define":"useSmsService",
								"label":"是否启用短信服务",
								"type":"boolean"
							},
							"useRegister":{
								"define":"useRegister",
								"label":"开启用户注册",
								"type":"boolean"
							},
							"btnDisabled":{
								"define":"btnDisabled",
								"label":"获取验证码按钮可用",
								"type":"boolean"
							},
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string"
							},
							"isTwoFactorLogin":{
								"define":"isTwoFactorLogin",
								"label":"双重认证",
								"type":"boolean"
							},
							"_key":{
								"type":"string"
							},
							"btnLabel":{
								"define":"btnLabel",
								"label":"获取验证码按钮文字",
								"type":"string"
							}
						}
					}
				},
				"initData":[
					{
						"useSmsService":true,
						"useRegister":false,
						"btnDisabled":false,
						"id":"data",
						"isTwoFactorLogin":true,
						"btnLabel":"获取验证码"
					}
				],
				"options":{
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"ctrl",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"load":"pageLoad"
			},
			"id":"page"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"form",
			"$attrBindFns":{
				"hidden":"$attrBindFn_hidden_form"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"name",
			"$refFn":"$refFn_input1",
			"$events":{
				"valuechange":"nameChange"
			},
			"id":"input1",
			"$refPathFn":"$refPathFn_input1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"password",
			"$refFn":"$refFn_input2",
			"id":"input2",
			"$refPathFn":"$refPathFn_input2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"button3"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"form3",
			"$attrBindFns":{
				"hidden":"$attrBindFn_hidden_form3"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"name",
			"$refFn":"$refFn_input3_1",
			"id":"input3_1",
			"$refPathFn":"$refPathFn_input3_1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"password",
			"$refFn":"$refFn_input3_2",
			"id":"input3_2",
			"$refPathFn":"$refPathFn_input3_2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"button2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"label3"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"label9",
			"$attrBindFns":{
				"hidden":"$attrBindFn_hidden_label9"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"label10",
			"$attrBindFns":{
				"hidden":"$attrBindFn_hidden_label10"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"depends":["__toptips__.show","__toptips__.compid"],
			"__auto1":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			}
		},
		"form":{
			"cell2":{
				"bd2":{
					"depends":[],
					"input2":{
						"depends":["loginData.current._userdata.password.required.val","loginData.current._userdata","loginData.current._userdata.password","loginData.current._userdata.password.readonly","true","loginData.current.password","input2.compid","__pageid__","loginData.current._userdata.password.hidden","loginData.current"]
					}
				},
				"ft1":{
					"button3":{
						"label4":{
							"depends":[]
						},
						"depends":["button3.compid","__pageid__"]
					},
					"depends":["ctrl.current.useSmsService"]
				},
				"depends":[],
				"hd21":{
					"depends":[],
					"label2":{
						"depends":[],
						"label2_1":{
							"depends":[]
						}
					}
				}
			},
			"depends":["form.compid","form.hidden"],
			"cell1":{
				"hd1":{
					"depends":[],
					"label1":{
						"depends":[],
						"label1_1":{
							"depends":[]
						}
					}
				},
				"depends":[],
				"bd1":{
					"depends":[],
					"input1":{
						"depends":["loginData.current._userdata.name.required.val","loginData.current._userdata","loginData.current._userdata.name","loginData.current._userdata.name.readonly","loginData.current.name","input1.compid","__pageid__","loginData.current._userdata.name.hidden","loginData.current"]
					}
				}
			}
		},
		"inVisibleCompContainer1":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"form3":{
			"cell3_2":{
				"depends":[],
				"hd3_21":{
					"label3_2":{
						"depends":[],
						"label3_2_1":{
							"depends":[]
						}
					},
					"depends":[]
				},
				"bd3_2":{
					"input3_2":{
						"depends":["loginData.current._userdata.password.required.val","loginData.current._userdata","loginData.current._userdata.password","loginData.current._userdata.password.readonly","true","loginData.current.password","input3_2.compid","__pageid__","loginData.current._userdata.password.hidden","loginData.current"]
					},
					"depends":[]
				},
				"ft4":{
					"button2":{
						"label_vcode-btn":{
							"depends":["ctrl.current.btnLabel"]
						},
						"depends":["button2.compid","__pageid__","ctrl.current.btnDisabled"]
					},
					"depends":[]
				}
			},
			"cell3_1":{
				"bd3_1":{
					"depends":[],
					"input3_1":{
						"depends":["loginData.current._userdata.name.required.val","loginData.current._userdata","loginData.current._userdata.name","loginData.current._userdata.name.readonly","loginData.current.name","input3_1.compid","__pageid__","loginData.current._userdata.name.hidden","loginData.current"]
					}
				},
				"depends":[],
				"hd3_1":{
					"depends":[],
					"label3_1":{
						"depends":[],
						"label3_1_1":{
							"depends":[]
						}
					}
				}
			},
			"depends":["form3.compid","form3.hidden"]
		},
		"row5":{
			"col16":{
				"depends":[]
			},
			"col15":{
				"depends":[],
				"label3":{
					"depends":["label3.compid","__pageid__","ctrl.current.useRegister"]
				}
			},
			"col14":{
				"label9":{
					"depends":["label9.compid","__pageid__","label9.hidden"]
				},
				"depends":["ctrl.current.useSmsService","ctrl.current.isTwoFactorLogin"],
				"label10":{
					"depends":["label10.compid","__pageid__","label10.hidden"]
				}
			},
			"col13":{
				"depends":[]
			},
			"depends":[]
		},
		"row4":{
			"depends":[],
			"col2":{
				"depends":[],
				"button1":{
					"depends":["button1.debounce","button1.compid","__pageid__"],
					"button1-icon":{
						"depends":[]
					},
					"button1-label":{
						"depends":[]
					}
				}
			},
			"col3":{
				"depends":[]
			},
			"col1":{
				"depends":[]
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"登录"},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
