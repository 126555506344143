
/*
  {
  		"defCols": {
  			"code":{
				"define":"code",
				"label":"编码",
				"type":"string",
				"rules": {
					"readonly": fn($row),
					"default": fn(),
					"required": {
						"fn": fn($row),
						"msg": "",
					},
					"constraint": {
						"fn": fn($row),
						"msg": "",
					},
					"calculate": fn($row)
				}
			},
			name: {
                define: "name",
                label: "名称",
                type: "string"
           },
            id: {
                define: "name",
                label: "名称",
                type: "string"
            }
  		},
  		"initData":[
			{
				"code":"2",
				"name":"1",
				"id":"3"
			}
		],
  		"filters": {},
  		"options":{
  			"limit":20,  
			"orderBy":[], 
			
			"depends":[
				"tableCustomData0"
			],
			"isMain":false,
			"autoMode":"new",
			"directDelete":true,
			"confirmDelete":true,
			"confirmRefreshText":"",
			"allowEmpty":false,
			"confirmDeleteText":"",
			"confirmRefresh":true,
			"idColumn":"id",
			"dataReadonly": fn($data),   //原来是$roFn
  			"deleteConstraint": fn($row), //原来是"$checkDeleteRowFn":"$checkDeleteRowFn_tableCustomData0",
  			"deleteConstraintMsg": fn($row),//原来是"$getCanNotDeleteRowMsgFn":"$getCanNotDeleteRowMsgFn_tableCustomData0",
		}
  }
 */
var READONLY_RULE = "readonly";
var DEFAULT_RULE = "default";
var REQUIRED_RULE = "required";
var CONSTRAINT_RULE = "constraint";
var CALCULATE_RULE = "calculate";


export function prepareConfig(id, config){
	if (config && config.schema){
		config.options = config.options || {};
		if (config.$roFn){
			config.options.dataReadonly = config.$roFn;
			delete config.$roFn;
		}
		if (config.options.$checkDeleteRowFn){
			config.deleteConstraint = config.options.$checkDeleteRowFn;
			delete config.options.$checkDeleteRowFn;
		}
		if (config.options.$getCanNotDeleteRowMsgFn){
			config.deleteConstraintMsg = config.options.$getCanNotDeleteRowMsgFn;
			delete config.options.$getCanNotDeleteRowMsgFn;
		}
		return config;
	}else{
		let defCols = config.defCols || {};
		let autoruns = [];
		let cols = [];
		let rules = {};
		let props = {};
		for (let name in defCols){
			if (defCols.hasOwnProperty(name)){
				cols.push(name);
				let col = defCols[name];
				props[name] = col;
				let colRules = col.rules;
				if (colRules){
					delete col.rules;
					if (typeof colRules[DEFAULT_RULE] == "function"){
						props[name][DEFAULT_RULE] = colRules[DEFAULT_RULE];
					}
					if (typeof colRules[READONLY_RULE] == "function"){
						rules[name] = rules[name] || {};
						rules[name].readonly = function($row){
							try{
					 			return colRules[READONLY_RULE]($row);
							}catch(_$e){
								return null;
							}
						};
					}
					if (colRules[REQUIRED_RULE]){
						rules[name] = rules[name] || {};
						if (typeof colRules[REQUIRED_RULE].fn == "function"){
							rules[name].required = {
								val: function($self){
									try{
							 			return colRules[REQUIRED_RULE].fn($self);
									}catch(_$e){
										return null;
									}
								},
								msg: colRules[REQUIRED_RULE].msg
							};
						}
					}
					if (colRules[CONSTRAINT_RULE]){
						rules[name] = rules[name] || {};
						if (typeof colRules[CONSTRAINT_RULE].fn == "function"){
							rules[name].constraint = {
								val: function($self){
									try{
							 			return colRules[CONSTRAINT_RULE].fn($self);
									}catch(_$e){
										return null;
									}
								},
								msg: colRules[CONSTRAINT_RULE].msg
							};
						}
					}
					
					if (typeof colRules[CALCULATE_RULE] == "function"){
						autoruns.push(function($row){
							//不处理计算中的依赖，由用户函数自身实现
							try{
								$row[name] = colRules[CALCULATE_RULE]($row);
							}catch(__$e){
								console.error(__$e);
							}
						});
						
					}
				}
				
			}
		}
		
		let options = config.options || {};
		let fns = {
			get _userdata(){
				let rulesValue = {};
				for (let key in rules){
					if (rules.hasOwnProperty(key)){
						let rule = rules[key];
						rulesValue[key] = {};
						if (rule.readonly){
							rulesValue[key].readonly = rule.readonly(this);
						}
						if (rule.required && rule.required.val){
							rulesValue[key].required = {
								val: rule.required.val(this),
								msg: rule.required.msg
							};
						}
						if (rule.constraint && rule.constraint.val){
							rulesValue[key].constraint = {
								val: rule.constraint.val(this),
								msg: rule.constraint.msg
							};
						}
					}
				}
				if (typeof options.dataReadonly == "function"){
					for (let i=0; i<cols.length; i++){
						rulesValue[cols[i]] = rulesValue[cols[i]] || {};
						rulesValue[cols[i]].readonly = rulesValue[cols[i]].readonly || options.dataReadonly(this.$data);
					}	
				}
				return wx.Util.prepareUserData(this, rulesValue, cols);
			}	
		};
		props._key = {type:"string"};
		let schema = {
			type:"array",	
			id: id,
			keyItems: "_key",
			items: {
				autorun: autoruns,
				fns: fns,
				type: "object",
				key: "_key",
				props: props
			}
		};
		
		schema.limit = options.limit || 20;
		delete options.limit;
		if (options.orderBy){
			schema.orderBy = options.orderBy;
			delete options.orderBy;
		}
		
		let ret = {
			schema: schema,
			options: options,
			id: id
		};
		options.dynamicCreate = true;
		if (config.filter){
			ret.filter = config.filter;
		}
		if (config.initData){
			ret.initData = config.initData;
		}
		return ret;
	}
	
}
