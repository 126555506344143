var ServiceUtil = {
	getServiceUrl: function(url, serviceName,parentPath = ""){
		//浏览器行为 标准线
		if(window && window.location && window.location.origin){
			let ret = url;
			let servicePath =  serviceName?"/" + serviceName: "";
			if (url){
				if (url.indexOf("http://")==0 || url.indexOf("https://")==0){
					ret = url;
				}else{
					if (url.indexOf("/")!==0){
						url = "/" + url;
					}
					ret = location.origin + parentPath + servicePath + url;
				}
			}
			return ret;
		}else{
			//小程序运行兼容逻辑
			var ret = url;
			if (url){
				if (url.indexOf("http://")==0 || url.indexOf("https://")==0){
					ret = url;
				}else{
					ret = wx.App.baseUrl;
					if (serviceName){
						// 解决应用独立部署时, baseUrl带服务名的问题
						if (ret.indexOf("http://")==0 || ret.indexOf("https://")==0){
							var items = ret.split("://");
							ret = items[0] + "://" + items[1].split("/")[0];
						}
						if (serviceName.indexOf("/")!==0){
							ret += "/";
						}
						ret += serviceName;
					}
					if (url.indexOf("/")!==0){
						ret += "/";
					}
					ret += url;
				}
			}
			return ret;
		}
	},

   	//依次根据defaultExecutor和门户当前岗位查找，如果没有找到使用有权限的第一项，否则使用defaultExecutor||门户当前岗位
   	addExecutor: function(url, defaultExecutor){
   		if (!url || url.indexOf("/") == -1) return url;
   		var urlObj = this._parseUrl(url);
		if (urlObj.search.indexOf("executor=")==0 
			|| urlObj.search.indexOf("&executor=")>0){
			return url;
		}else{
			var executor = this._getExecutor(urlObj.path, defaultExecutor);
			if (executor){
				if (urlObj.search) urlObj.search += "&";
				urlObj.search += "executor=" + encodeURIComponent(executor);
			}
			var ret = urlObj.path || "";
			if (urlObj.search){
				ret += "?" + urlObj.search;
			}
			if (urlObj.hash){
				ret += "#" + urlObj.hash;
			}
			return ret;
		}
   	},
   	
   	_getExecutor: function(url, defaultExecutor){
   		var ret = "";
   		if (url){
   			var path = this._parseUrl(url).path;
   			path = path.replace("/pcxapp/pcx/", "/mobileapp/mobile/");
   			path = path.replace("/pcapp/pc/", "/mobileapp/mobile/");
   			
   			var executors = [];
   			if (defaultExecutor) executors.push(defaultExecutor);
   			if (window && window.justep && window.justep.Util && window.justep.Util.getGlobalValue){
				//获取门户的默认执行者
				var defaultPersonMember = justep.Util.getGlobalValue("portal.defaultPersonMember");
				if (defaultPersonMember && defaultPersonMember.fid){
					executors.push(defaultPersonMember.fid);
				}
			}
   			
   			var menu = this._getMenu();
   			var psms = this._getPersonMembersByUrl(menu, path) || [];
   			
   			for (var i=0; i<executors.length; i++){
   				ret = this._doGetExecutor(psms, executors[i]);
   				if (ret) break;
   			}
   			
   			if (!ret && psms.length>0){
   				ret = psms[0].fid;
   			}
   			
   			if (!ret && executors.length>0){
   				ret = executors[0];
   			}
   		}
   		
   		return ret || "";
   	},
   	
   	_doGetExecutor: function(psms, executor){
   		if (psms && psms.length>0 && executor){
			for (var i=0; i<psms.length; i++){
				var psm = psms[i];
				if (psm && psm.fid
						&& (psm.fid.indexOf(executor) === 0)){
					return psm.fid;
				}
			}
		}
   	},
   	
   	_getPersonMembersByUrl: function(items, url){
   		var ret = null;
	   	if (items){
	   		if (url && (url.substr(-2) != ".w")) url += ".w"; //解决传入的url有时不带.w的问题
	   		for (var i=0; i<items.length; i++){
	   			var item = items[i];
	   			var originUrl = item.originUrl;
	   			if (originUrl){
	   				originUrl = originUrl.split("?")[0];
	   				// 兼容route和originUrl少一个/的情况
	   				if ((originUrl === url) || (originUrl === "/" + url)){
	   					ret = item.personMembers || [];
						break;
					}
				}
				if (item.children){
					ret = this._getPersonMembersByUrl(item.children, url);
					if (ret) break;
				}
		 	}
	   	}
	   	return ret;
   	},   	
   	
   	_getMenu: function(){
   		var menu = (getApp()||{}).menu;
   		if (!menu && window && window.justep && window.justep.Util){
   			menu = window.justep.Util.getGlobalValue("portal.menu");
	   	}
   		return menu;
   	},
   	
   	_parseUrl: function(url){
   		var path=url, search="", hash="";
   		if (path){
   			var i = path.indexOf("#");
   			if (i != -1){
   				hash = path.substr(i+1);
   				path = path.substr(0, i);
   			}
   			i = path.indexOf("?");
   			if (i != -1){
   				search = path.substr(i+1);
   				path = path.substr(0, i);
   			}
   		}
   		return {path: path, search: search||"", hash: hash||""};
   	}
};

export default ServiceUtil;
