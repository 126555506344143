import {isObject, toResUrl} from "../../../../wxsys/lib/base/util";
import {findPageByRoute} from 'core/router';
import {addOpenerRoute} from 'core/utils';
import renderComponent from "../../../../wxsys/lib/base/renderComponent";
import {
	observable,
	autorun,
	computed,
	toJS,
	extendObservable,
	runInAction
} from "../../../../wxsys/lib/mobx/mobx-2.6.2.umd";
import Eventable from "../../../../wxsys/lib/base/eventable";
import _Date from "../../../../wxsys/lib/base/date";
import ServiceUtil from "../../../../wxsys/lib/base/serviceUtil";

import PageComponent from 'core/framework/PageComponent';


function getDataType(obj){
	return Object.prototype.toString.call(obj).split(" ")[1].split("]")[0]
}





export default class Page extends PageComponent{
    constructor(props, context){
    	super(props,context);

		//Eventable.prototype._constructor.call(this);
		this.events = this.events || {};
		this.pageType = "uix";
		if (this.listeners) {
			this.on(this.listeners);
			delete this.listeners;
		}
    }

    getConfig(){
    	return this.owner && this.owner.getConfig();
    }

    isReadonly(){
    	return this.$isReadonlyMode();
    }
    
    getParentFrame(){
    	if (window && window.__$$pageFrames 
				&& this.params 
				&& this.params.__pageFrameId__
				&& window.__$$pageFrames[this.params.__pageFrameId__]){
			return window.__$$pageFrames[this.params.__pageFrameId__];
		}else{
			return null;
		}
    }

    comp(key,comp){
    	//首先查找上层组件 主要为数据模型组件 比如restData
        let result = this.$compByCtx(key,comp);
        
        //其次通过ref查找对应的组件实例
        if((!result) && this.owner.reactRefs[key]){
        	result = this.owner.reactRefs[key].current;
        }
        
        //通过ref查找不到 通过idRefMapping查找
        if((!result) && this.owner.__idRefMapping[key]){
        	result = this.owner.__idRefMapping[key].current;
        }
        return result;
    }

    //------------setData相关逻辑开始----------------------------------
	setData(options){

		for (let name in options) {
            let o = this.$getObjectByPath(this.data, name);
			let r = o.obj;
			let i = o.key;
            if (r){
            	r[i] = deepCopy(options[name]);
            }
        }
        
		this.$setDataProxy(options); //只更新需要更新的数据
	}

	getServiceUrl(url, serviceName){
		return ServiceUtil.getServiceUrl(url, serviceName || this.serviceName, this.parentPath);
	}

	//静态资源url 返回带协议的url
	getUIUrl(url){
		return toResUrl(url, false, this.basePath, this.contextPath,this.parentPath);
	}
	getParentPath(){
		return this.parentPath || "";
	}

	getServiceName(){
		return this.serviceName || "";
	}

   	setRequestInfo(key,value){
   		this.$requestInfo[key] = value; 
   	}
   	
   	
   	removeRequestInfo(key){
   		delete this.$requestInfo[key]; 
   	}
   	
   	getRequestInfo(key){
   		return key?this.$requestInfo[key]:this.$requestInfo;
   	}
   	
   	request(params){

		//兼容写 this.request({url:"/entry/authorize/currentUserRoles"}) 这种写法 url不是通过api得到的
		if(this.parentPath && params.url && params.url.indexOf("/") == 0 && params.url.indexOf(this.parentPath) != 0){
			params.url = this.parentPath +  params.url;
		}
   		let requestHeader = this.getRequestInfo();
   		params.header = {...requestHeader,...(params.header || {})};
   		return wx.request(params);
   	}

   	navigateTo(params){

		this._formatPageUrl(params);

		params.openType  = params.openType || "page";
		params.prependServicePath = false;
		
		//传递页面的执行者
		if (!params.executor){
			params.url = wx.ServiceUtil.addExecutor(params.url, this.params.executor);
		}


		this._enableMessageChannle(params);
		return params.impl?params.impl(params):wx.navigateTo(params).then(res=>{
			return {
				...res,
				postMessage:(message={})=>{
					message.source = this.route;
					let page = findPageByRoute(params.url);
					page.postMessage(message);
				}
			}
		});
	}

	navigateBack(params = {}){
		params.currentPage = this.ownerPage || this;
		return wx.navigateBack(params);
	}

	/**
	 *
 	 * 嵌入其他页面到当前页面target dom内  完整生命周期  完整消息通道
	 */
	compose(params,target){
    	this._formatPageUrl(params);
		this._enableMessageChannle(params);
		return wx.uixPageRender(params,target).then((page)=>{
			let postMessage = page.postMessage;
			page.postMessage = (message)=>{
				message.source = this.route;
				postMessage(message);
			}
			return page;
		});
	}

    _enableMessageChannle(params){
		addOpenerRoute(params,this.route);
		if (params.onMessage){
			this.emitter.on('message', (message)=>{
				if(message && message.source == params.url){
					params.onMessage.call(this,message);
				}
			});
		}
	}

	/**
	 *
	 规范写法
	 $UI/pcx/a.w
	 为了兼容
	 $UI/pcx/a
	 navigatewrapper 加.w
	 toResUrl中因为需要.w(处理后去掉.w)

	 /entry/pcx/a.w
	 this.getUIUrl 定位为 获取静态资源url 返回带协议路径
	 this.navigateTo 定位为页面跳转。兼容tab场景和dialog
	 2022.4.13 slm
	 调整为
	 打开页面 统一为
	 this.navigateTo()

	 url
	 规范写法
	 $UI/pcx/a.w
	 后续支持小程序合并（如果需要）
	 *
	 */
	_formatPageUrl(params){
    	let url = params.url;
		if (url.indexOf("$UI/") == "0"){
			url = url.substring(3);
			if(this.serviceName){
				url = "/" + this.serviceName + "/" + this.contextName + url;
			}else{
				url = "/" + this.contextName + url;
			}
			params.url = url;
		}else if(url.indexOf("$ServiceName/") == "0"){
			//跨端场景
			url = url.substring(12);
			if(this.serviceName){
				url = "/" + this.serviceName + url;
			}
			params.url = url;
		}
	}
	/**
	 * mx端页面跨端运行 需要打开pcx中的对话框需要获得pcx的对话框路径
	 * @param url
	 * @returns {string}
	 */
	toPcxUrl(url){
		if (url.indexOf("$UI/") == "0" && this.deviceType == "mx" && window.isPcx == true){
			url = url.substring(3);
			if(this.serviceName){
				url = "/" + this.serviceName + "/pcxapp" + url;
			}else{
				url = "/pcxapp"+ url;
			}
		}
		return url;
	}
}

let mixin = Eventable.prototype;
["clearListener","clearListeners","fireEvent","hasListener","off","on","un"].forEach(function(name){
	Page.prototype[name] = mixin[name];
});
Page.isEventable = true;


Page.TOPTIPS_NAME = "__toptips__";
