import PageImpl from "../../../lib/base/pageImpl";
import User from "../user";
import Util from "../../../lib/base/util";
import Config from "../../../lib/base/config";

var app = getApp();
export default class EditPage extends PageImpl {
  constructor(...args){
	  super(...args);
	  this.isTwoFactorLoginByUsername = {};
  }
  
  login() {
	  let data = this.comp('loginData');
	  let name = data.getValue('name');
	  let password = data.getValue('password');
	  let type = data.getValue('type');
	  let params = {};
	  params.name = name;
		// 自动设置参数的情况
	  params.password = password;
	  if (!params.name) {
			this.hint(type==='password'?'请输入登录账号!':'请输入手机号码','warn');
			return;
		}
	  if (type!=='password' && !params.password) {
			this.hint('请输入短信验证码!','warn');
			return;
		}
	  if (this.userComp) {
		  let username = params.name;
		  
		  if(type==='password'){
			  let ctrl = this.comp('ctrl');
			  let isTwoFactorLogin = ctrl.getValue('isTwoFactorLogin');
			  if(!isTwoFactorLogin){
				  this.userComp.login(params,this,() => this.closePage());
			  }else{
				  this.getTwoFactorLoginByUsername(username).then((isTwoFactorLogin)=>{
					  this.userComp[!isTwoFactorLogin?'login':'prelogin'](params,this).then((pretoken) => {
						  !isTwoFactorLogin?this.closePage():this.userComp.showTwofactorlogin(username,pretoken);
					  },()=>{});
				  },(err)=>{
					  console.error(err);
					  this.hint("查询用户["+username+"]是否双重认证登录状态失败", 'warn');
				  });
			  }
		  }else{
			  //User.phoneNumExist(name).then((exist)=>{
				  //if(exist){
					  this.userComp.login(params,this,() => this.closePage());
				  //}else{
				//	  this.userComp.register({userName:name,phone:name,code:password},this).then(() => {
					//	  this.closePage();
					 // },()=>{});
				  //}
			  //},()=>{
			  //  this.hint('校验手机号失败请重试！', 'warn');
			  //});
		  }
		}
  }
  
  closePage(){
	  return wx.navigateBack();
  }
	
  pageLoad(event){
	  let ctrl = this.comp('ctrl');
	  ctrl.refreshData();
	  if(this.params.userComp){
	  	this.userComp = User.getUserComp(this.params.userComp);
	  	this.userComp && this.userComp.isTwoFactorLogin().then((isTwoFactorLogin)=>{
	  		ctrl.setValue('isTwoFactorLogin',isTwoFactorLogin);
		});
	  	ctrl.setValue('useSmsService',this.userComp && this.userComp.useSmsService);
	  }	
        Config.getConfig("entry","portal-config").then((data)=>{
            if(data.length>0){
                let content = data[0].content;
				if(content.useRegister===false){
					ctrl.setValue('useRegister',false);
				}else{
					ctrl.setValue('useRegister',true);
				}
            }else{
				ctrl.setValue('useRegister',true);
			}
        },function(error){
        });
	}
  
	getTwoFactorLoginByUsername(username) {
		if (this.userComp && username && this.isTwoFactorLoginByUsername[username]===undefined) {
			return this.userComp.isTwoFactorLoginByUsername(username).then((isTwoFactorLogin)=>{
				this.isTwoFactorLoginByUsername[username] = isTwoFactorLogin;
				return isTwoFactorLogin;
			});
		}else{
			return Promise.resolve(this.isTwoFactorLoginByUsername[username]);
		}
	};

  nameChange(event){
	  let ctrl = this.comp('ctrl');
	  event.value && ctrl.getValue('isTwoFactorLogin') && this.getTwoFactorLoginByUsername(event.value);
  }

  sendSmsBtnClick(event) {
		let ctrldata = this.comp('ctrl');
		if(ctrldata.getValue('btnDisabled')) return;
		let data = this.comp('loginData');
		let phoneNumber = data.getValue("name");
		if(!User.phoneReg.test(phoneNumber)){
			this.hint(User.phoneHint,'warn');
			return;			
		}
		let self = this;
		this.userComp && this.userComp.sendVerifyCode("sms-verify-code",phoneNumber,this).then(()=>{
			User.bindTimmer(ctrldata,60,"获取验证码","重发");
		},()=>{});
  }
  
  loginBtnClick(event) {
	  this.login();
  }
  
  regBtnClick(event) {
	  if (this.userComp)
		  this.userComp.showRegister();
  }

  btnForgetClick(event) {
	  if (this.userComp)
		  this.userComp.showForgetPassword();
  }
  
}
