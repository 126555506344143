import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './forgetPassword.user';

 var $g_fns_data = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['verifyCode','phonenumber','newPassword','confirmPassword','id','_key']);
		}
}; 


 var $g_fns_ctrl = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['btnDisabled','id','_key','btnLabel']);
		}
}; 

import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../row/css/row.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $refFn_input1: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.phonenumber ;
}

,
 $refPathFn_input21: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input3: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refPathFn_input2: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refFn_input3: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.confirmPassword ;
}

,
 $refPathFn_input1: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current._path ;
}

,
 $refFn_input2: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.newPassword ;
}

,
 $refFn_input21: function({data,$row,ctrl,$comp,params,$page,props}){
 return data.current.verifyCode ;
}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"data",
					"type":"array",
					"items":{
						"fns":$g_fns_data,
						"type":"object",
						"key":"_key",
						"props":{
							"verifyCode":{
								"define":"verifyCode",
								"label":"验证码",
								"type":"string"
							},
							"phonenumber":{
								"define":"phonenumber",
								"label":"电话号码",
								"type":"string"
							},
							"newPassword":{
								"define":"newPassword",
								"label":"新密码",
								"type":"string"
							},
							"confirmPassword":{
								"define":"confirmPassword",
								"label":"确认密码",
								"type":"string"
							},
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string"
							},
							"_key":{
								"type":"string"
							}
						}
					}
				},
				"initData":[
					{
						"id":"data"
					}
				],
				"options":{
					"isMain":false,
					"autoMode":"load",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"data",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"ctrl",
					"type":"array",
					"items":{
						"fns":$g_fns_ctrl,
						"type":"object",
						"key":"_key",
						"props":{
							"btnDisabled":{
								"define":"btnDisabled",
								"label":"获取验证码按钮可用",
								"type":"boolean"
							},
							"id":{
								"define":"id",
								"label":"ID",
								"type":"string"
							},
							"_key":{
								"type":"string"
							},
							"btnLabel":{
								"define":"btnLabel",
								"label":"获取验证码按钮文字",
								"type":"string"
							}
						}
					}
				},
				"initData":[
					{
						"btnDisabled":false,
						"id":"data",
						"btnLabel":"获取验证码"
					}
				],
				"options":{
					"isMain":false,
					"autoMode":"load",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"ctrl",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"load":"pageLoad"
			},
			"id":"page"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"phonenumber",
			"$refFn":"$refFn_input1",
			"id":"input1",
			"$refPathFn":"$refPathFn_input1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"verifyCode",
			"$refFn":"$refFn_input21",
			"id":"input21",
			"$refPathFn":"$refPathFn_input21"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"button2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"newPassword",
			"$refFn":"$refFn_input2",
			"id":"input2",
			"$refPathFn":"$refPathFn_input2"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"$propName":"confirmPassword",
			"$refFn":"$refFn_input3",
			"id":"input3",
			"$refPathFn":"$refPathFn_input3"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"button1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"depends":["__toptips__.show","__toptips__.compid"],
			"__auto1":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			}
		},
		"form":{
			"cell4":{
				"bd4":{
					"depends":[],
					"input21":{
						"depends":["data.current._userdata.verifyCode.required.val","data.current._userdata","data.current._userdata.verifyCode","data.current._userdata.verifyCode.readonly","data.current.verifyCode","input21.compid","__pageid__","data.current._userdata.verifyCode.hidden","data.current"]
					}
				},
				"depends":[],
				"ft4":{
					"button2":{
						"label_vcode-btn":{
							"depends":["ctrl.current.btnLabel"]
						},
						"depends":["button2.compid","__pageid__","ctrl.current.btnDisabled"]
					},
					"depends":[]
				},
				"hd4":{
					"label4":{
						"label4_1":{
							"depends":[]
						},
						"depends":[]
					},
					"depends":[]
				}
			},
			"cell2":{
				"bd2":{
					"depends":[],
					"input2":{
						"depends":["data.current._userdata.newPassword.required.val","data.current._userdata","data.current._userdata.newPassword","data.current._userdata.newPassword.readonly","true","data.current.newPassword","input2.compid","__pageid__","data.current._userdata.newPassword.hidden","data.current"]
					}
				},
				"depends":[],
				"hd21":{
					"depends":[],
					"label2":{
						"depends":[],
						"label2_1":{
							"depends":[]
						}
					}
				}
			},
			"cell3":{
				"bd3":{
					"depends":[],
					"input3":{
						"depends":["data.current._userdata.confirmPassword.required.val","data.current._userdata","data.current._userdata.confirmPassword","data.current._userdata.confirmPassword.readonly","true","data.current.confirmPassword","input3.compid","__pageid__","data.current._userdata.confirmPassword.hidden","data.current"]
					}
				},
				"depends":[],
				"hd3":{
					"depends":[],
					"label3":{
						"depends":[],
						"label3_1":{
							"depends":[]
						}
					}
				}
			},
			"depends":[],
			"cell1":{
				"hd1":{
					"depends":[],
					"label1":{
						"depends":[],
						"label2_1":{
							"depends":[]
						}
					}
				},
				"depends":[],
				"bd1":{
					"depends":[],
					"input1":{
						"depends":["data.current._userdata.phonenumber.required.val","data.current._userdata","data.current._userdata.phonenumber","data.current._userdata.phonenumber.readonly","data.current.phonenumber","input1.compid","__pageid__","data.current._userdata.phonenumber.hidden","data.current"]
					}
				}
			}
		},
		"inVisibleCompContainer1":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"row7":{
			"depends":[],
			"col21":{
				"depends":[]
			},
			"col20":{
				"depends":[],
				"button1":{
					"depends":["button1.debounce","button1.compid","__pageid__"],
					"button1-icon":{
						"depends":[]
					},
					"button1-label":{
						"depends":[]
					}
				}
			},
			"col19":{
				"depends":[]
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"重新设置密码"},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
