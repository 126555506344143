import Component from "../../lib/base/component";
import { parsePath } from "../../lib/base/pageImpl";
import { isArray } from "../../lib/base/util";
import Data from "../tableData/data";
import TableData from "../tableData/tableData";
import _String from "../../lib/base/string";
import { receiveByMapping } from "./js/receiveMapping";
import Operational from "../../lib/base/operational";
import CommonOperationImpl from './CommonOperationImpl'
export default class CommonOperation extends Component {
	constructor(page, id, props, context) {
		super(page, id, props, context);
		this.impl = new CommonOperationImpl(this.page)
	}

	setValue(dataId, col, value, row) {
		this.impl.setValue(dataId, col, value, row);
	}

	filedValues(dataId, value) {
		this.impl.fieldValues(dataId, value);
	}

	allSetValue(dataId, col, value) {
		this.impl.allSetValue(dataId, col, value);
	}

	saveReturn(dataId, insertPos) {
		return this.impl.saveReturn(dataId, insertPos)
	}

	deleteData(dataId, row, filter, confirm, batch) {
		return this.impl.deleteData(dataId, row, filter, confirm, batch)
	}

	saveData(dataId, row, successHint, successInfo, batch) {
		return this.impl.saveData(dataId, row, successHint, successInfo, batch)
	}

	openPageDialog(url, dataId, params, option, row, wait, openType) {
		return this.impl.openPageDialog(url, dataId, params, option, row, wait, openType)
	}

	loadData(dataId, rows, append, parent, index) {
		this.impl.loadData(dataId, rows, append, parent, index);
	}

	close() {
		return this.impl.close()
	}

	okclose(dataId, insertPos) {
		this.impl.okclose(dataId, insertPos)
	}

	initOperation() {
		super.initOperation();
		this.defineOperation(
			'setValue', {
			label: '数据赋值',
			argsDef: [{
				name: 'data',
				displayName: '目标数据集',
				required: true
			}, {
				name: 'col',
				displayName: '列',
				required: true
			}, {
				name: 'value',
				displayName: '值',
				required: true
			}, {
				name: 'row',
				displayName: '行ID',
			}],
			method: function (args, ctx) {
				var row = args.row || ctx.$item;
				var data = args.data || ctx.$data || ctx.$page.$mainData;//兼容保留ctx.$data,设计时list时赋值操作没有给出data参数
				return this.owner.setValue(data, args.col, args.value, row);
			}
		}
		);

		this.defineOperation(
			'filedValues', {
			label: '多列赋值',
			argsDef: [{
				name: 'data',
				displayName: '目标数据集',
				required: true
			}, {
				name: 'values',
				displayName: '值',
				required: true
			}],
			method: function (args, ctx) {
				return this.owner.filedValues(args.data, args.values);
			}
		}
		);

		this.defineOperation(
			'allSetValue', {
			label: '数据所有行赋值',
			argsDef: [{
				name: 'data',
				displayName: '目标数据集',
				required: true
			}, {
				name: 'col',
				displayName: '列',
				required: true
			}, {
				name: 'value',
				displayName: '值',
				required: true
			}],
			method: function (args, ctx) {
				var data = args.data || ctx.$page.$mainData;
				return this.owner.allSetValue(data, args.col, args.value);
			}
		}
		);

		this.defineOperation(
			'saveReturn', {
			label: '保存并返回',
			argsDef: [{ name: 'data', displayName: '目标数据集' }, { name: 'index', displayName: '新增插入位置' }],
			method: function (args, ctx) {
				var data = args.data || ctx.$page.$mainData;
				return this.owner.saveReturn(data, args.index);
			}
		}
		);

		this.defineOperation(
			'saveData', {
			label: '保存',
			argsDef: [{ name: 'data', displayName: '目标数据集' }, {
				name: 'successHint',
				displayName: '成功提示'
			}, {
				name: 'successInfo',
				displayName: '成功提示语'
			}],
			method: function (args, ctx) {
				var data = args.data || ctx.$page.$mainData;
				var row = args.row || ctx.$item;
				if (ctx.$data) {
					if ('string' === typeof (data)) row = data !== ctx.$data.id ? undefined : row;
					else row = data !== ctx.$data ? undefined : row;
				}
				return this.owner.saveData(data, row, args.successHint, args.successInfo, Operational.getBatch(ctx));
			}
		}
		);

		this.defineOperation(
			'deleteData', {
			label: '删除',
			argsDef: [{ name: 'data', displayName: '目标数据集' }, { name: 'row', displayName: '行ID' }, { name: 'filter', displayName: '删除条件', type: 'fn', params: '$row' }, { name: 'force', displayName: '禁止提示' }],
			method: function (args, ctx) {
				var data = args.data || ctx.$page.$mainData;
				var confirm = args.force === undefined ? undefined : (typeof (args.force) === 'string' ? ('true' !== args.force) : !args.force);
				var row = args.row || ctx.$item;
				if (ctx.$data) {
					if ('string' === typeof (data)) row = data !== ctx.$data.id ? undefined : row;
					else row = data !== ctx.$data ? undefined : row;
				}
				var filter = args.filter;
				return this.owner.deleteData(data, row, filter, confirm, Operational.getBatch(ctx));
			}
		}
		);

		this.defineOperation(
			'loadData', {
			label: '加载数据',
			argsDef: [{ name: 'data', displayName: '目标数据集' }, { name: 'rows', displayName: '数据' }, { name: 'append', displayName: '增量模式' }],//{name:'parent',displayName:'父'}, {name:'index',displayName:'加载位置'},
			method: function (args, ctx) {
				var data = args.data || ctx.$page.$mainData, rows = args.rows || [], append = args.append, parent = args.parent, index = args.index, override = args.override;
				if (typeof (append) === 'string') append = append == 'true'
				return this.owner.loadData(data, rows, append, parent, index, override);
			}
		}
		);

		this.defineOperation(
			'openPageDialog', {
			label: '打开子页面',
			argsDef: [{ name: 'url', displayName: '页面源' }, { name: 'params', displayName: '参数' }, { name: 'receData', displayName: '共享数据集' }, { name: 'option', displayName: '对话框配置' }],
			method: function (args, ctx) {
				var data = args.receData || ctx.$page.$mainData;
				var row = ctx.$item;
				if (ctx.$data) {
					if ('string' === typeof (data)) row = data !== ctx.$data.id ? undefined : row;
					else row = data !== ctx.$data ? undefined : row;
				}
				var option = args.option || {};
				option.url = args.url;
				option.params = args.params;
				return this.owner.openPageDialog(args.url, data, args.params, option, row, true, "dialog");
			}
		}
		);

		this.defineOperation(
			'openPageDialogWait', {
			label: '打开对话框',
			argsDef: [{ name: 'url', displayName: '页面源' },
			{ name: 'params', displayName: '参数' },
			{ name: 'receData', displayName: '共享数据集' },
			{ name: 'modal', displayName: '对话框参数' },
			{ name: 'option', displayName: '对话框配置' }],
			method: function (args, ctx) {
				var data = args.receData || ctx.$page.$mainData;
				var row = ctx.$item;
				if (ctx.$data) {
					if ('string' === typeof (data)) row = data !== ctx.$data.id ? undefined : row;
					else row = data !== ctx.$data ? undefined : row;
				}
				var option = args.option || {};
				option.url = args.url;
				option.params = args.params;
				option.modal = args.modal;
				return this.owner.openPageDialog(args.url, data, args.params, option, row, true, "dialog");
			}
		}
		);

		this.defineOperation(
			'close', {
			label: '关闭页面',
			argsDef: [],
			method: function (args, ctx) {
				return this.owner.close();
			}
		}
		);

		this.defineOperation(
			'okclose', {
			label: '确定返回',
			argsDef: [{ name: 'data', displayName: '目标数据集' }, { name: 'index', displayName: '新增插入位置' }],
			method: function (args, ctx) {
				return this.owner.okclose(args.data, args.index);
			}
		}
		);
	}
}

wx.comp = wx.comp || {};
wx.comp.CommonOperation = CommonOperation;
