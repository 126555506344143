import {request, setConfig} from "../reqPromise/wx-promise-request.min";
import UUID from "./uuid";
import Util from "./util";
import Base64 from "./base64";
import _Date from "./date";
import _String from "./string";
import _Number from "./number";
import _CommonUtilFn from "../fn/commonUtilFn";
import _DateTimeUtilFn from "../fn/dateTimeUtilFn";
import _EncryptUtilFn from "../fn/encryptUtilFn";
import _MathUtilFn from "../fn/mathUtilFn";
import _RandomUtilFn from "../fn/randomUtilFn";
import _StringUtilFn from "../fn/stringUtilFn";
import _UrlUtilFn from "../fn/urlUtilFn";
import _CollectionUtilFn from "../fn/collectionUtilFn";
import {runInAction} from  "../mobx/mobx-2.6.2.umd";
import Eventable from "./eventable";
import Device from "./device";
import StringCheck from "./stringCheck";
import BatchRequest from "./batchRequest";
import ServiceUtil from "./serviceUtil";
import Config from "./config";




wx.Device = Device;
wx.UUID = UUID;
wx.Util = Util;
wx.Base64 = Base64;
wx.Date = _Date;
wx.String = _String;
wx.Number = _Number;
wx.CommonUtilFn=_CommonUtilFn
wx.DateTimeUtilFn=_DateTimeUtilFn
wx.EncryptUtilFn=_EncryptUtilFn
wx.MathUtilFn=_MathUtilFn
wx.RandomUtilFn=_RandomUtilFn
wx.StringUtilFn =_StringUtilFn 
wx.UrlUtilFn=_UrlUtilFn
wx.CollectionUtilFn=_CollectionUtilFn
wx.StringCheck = StringCheck
wx.Config = Config;
wx.ServiceUtil = ServiceUtil;

wx.compClass = function(url){
	if (!url) return null;
	if (wx.comp[url]){
		return wx.comp[url];
	}else {
		var items = url.split("/");
		var name = items[items.length-1];
		if (name){
			name = name.substr(0, 1).toUpperCase() + name.substr(1);
			if(wx.comp[name]){
				return wx.comp[name];
			}else{
				//基础命名空间组件
				items[items.length -1] = items[items.length -4];
				items[items.length -2] = items[items.length -4];
				return wx.comp[items.join("/")];
			}
		}
	}
	return null;
};


//操作特殊的Promise: 同步结果同步执行，异步的异步执行
function OperationPromise(data){
    this.data = data;
    this.then = function (fn) {
      var value = undefined;
      if (fn) value = fn(this.data);
      if (typeof value === "object" && value.then) {
        return value;
      } else {
        return new OperationPromise(value);
      }
    };
};
OperationPromise.resolve = function(value){
  if (typeof value === "object" && value.then) {
    return Promise.resolve(value);
  } else {
    return new OperationPromise(value);
  }
};

wx.OperationPromise = OperationPromise;


function callFns(fn1, fn2, params){
	var ex = null;
	runInAction(() => {
		try{
			fn1 && fn1(params);
		}catch(e){
			ex=e;
		}
	});	
	if (fn2){
		setTimeout(function(){
			runInAction(() => {
				fn2(params);
			});
	    });
	}
	if (ex != null){
		console.error(ex);
		throw ex;
	}
}

function requestWrapper(){
	var wxRequest = wx._request;
	var oldRequest = wx.request;
	if(!Device.isDom()){
		oldRequest = wxRequest;
		setConfig({request: wxRequest, concurrency: 5}); //为了支持没有appid的测试模式 , 允许的并发数为5
	}
	wx.request = function (params) {
		if (params.url){
			//默认/是相对后台域名
			if (params.url.indexOf("/") == 0){
				params.url = (wx.App.baseUrl || "") + params.url;
			}
		}
		
		var success = params.success;
	    var fail = params.fail;
	    var complete = params.complete;
	    
	    delete params.success;
	    delete params.fail;
	    delete params.complete;
	    
		let result;
		if(!(params && params.batch && typeof(params.batch.add)==="function")){
			//非批操作旧逻辑
			result = oldRequest(params);
		}else{
			//加入到批操作
			//需要去掉parentPath防止后台识别错误
			if(window && window.parentPath && window.location.origin){
				params.url = params.url.replace(window.location.origin + window.parentPath,window.location.origin);
			}
			result = params.batch.add(params);
		}
	    
	    result.then(function (res) {
	    	setTimeout(function(){
		    	if ((res.statusCode >= 200) && (res.statusCode < 300)){
		    		callFns(success, complete, res);
		    	}else{
		    		callFns(fail, complete, res);
		    	}
	    	}, 1);
	    }, function (err) {
	    	setTimeout(function(){
	    		callFns(fail, complete, err);	
	    	}, 1);
	    });
	    return result;
	};
}


// 特别指定的wx对象中不进行Promise封装的方法
const noPromiseMethods = {
  clearStorage: 1,
  hideToast: 1,
  showNavigationBarLoading: 1,
  hideNavigationBarLoading: 1,
  drawCanvas: 1,
  canvasToTempFilePath: 1,
  hideKeyboard: 1,
  getRecorderManager: 1,
  compClass: 1,
};

function promisifyApi() {
  Object.keys(wx).forEach((key) => {
	 if(typeof wx[key] === "function"){
		 if (!(noPromiseMethods[key]                        // 特别指定的方法
		 		  || (key.length>0 && /^[A-Z]/.test(key.substr(0, 1)))  //首字母大写不需要
			      || /^(on|create|stop|pause|close)/.test(key) // 以on* create* stop* pause* close* 开头的方法
			      || /\w+Sync$/.test(key)                      // 以Sync结尾的方法
			      )) {
		      let _temp = wx[key];
		      wx[key] = function (obj) {
		        obj = obj || {};
		        if(obj.success || obj.fail || obj.complete){
		          let fn = obj.fail;
		          obj.fail = function(res){
		                errMsgFn(res);
		                fn && fn(res);
		              }
		          return _temp.call(wx,obj);
		        }else{
		          return new Promise((resolve, reject) => {
		            obj.success = resolve;
		            obj.fail = (res) => {
		              errMsgFn(res);
		              reject(res);
		            };
		            _temp.call(wx,obj);
		          });
		        }
		      }
	    }	
	 } 
    
  });
}

//部分错误信息转中文特殊处理 ghc 2018-12
function errMsgFn(ret) {  
	  if (ret.errMsg == "switchTab:fail can not switch to no-tabBar page") {
	    ret.errMsg = "只允许跳转到 tabBar 页面";
	  } else if (ret.errMsg == "navigateTo:fail can not navigateTo a tabbar page") {
	    ret.errMsg = "不允许跳转到 tabBar 页面";
	  } else if (ret.errMsg == "redirectTo:fail can not redirectTo a tabbar page") {
	    ret.errMsg = "不允许跳转到 tabBar 页面";
	  }
	}

function addParamsToUrl(url, params, action){
	if (!url) return url;
	
	for (var key in params){
		if (params.hasOwnProperty(key)){
			var value = params[key];
			if (value===null || value===undefined)
				continue;
			if (value instanceof Date){
				value = value.toJSON();
			}else if (Util.isArray(value) || Util.isObject(value)){
				value = JSON.stringify(value);
			}

			url += (url.indexOf("?") != -1) ? "&" : "?";
			url += key + "=" + encodeURIComponent(value);
		}
	}
	
	//自动添加当前页面的executor参数
	if (action==="redirectTo" || action==="navigateTo"){
		var defaultExecutor = "";
		var page = getCurrentPages()[getCurrentPages().length-1];
		if (page && page.$page && page.$page.params && page.$page.params.executor){
			defaultExecutor = page.$page.params.executor;
		}
		url = ServiceUtil.addExecutor(url, defaultExecutor);
	}
	return url;
}

//规范：{params: 传递的参数, onClose: 子页面关闭时调用的函数, 里面带有传递的数据}
function navigateWrapper(){
	var keys = ["switchTab", "navigateTo", "redirectTo", "navigateBack", "reLaunch"];
	for (var i=0; i<keys.length; i++){
		let key = keys[i];
		let oldFn = wx[key];
		wx[key] = function(obj){
			obj = obj || {};
			if (!obj.url && (key !='navigateBack')){
				throw new Error("参数url不允许为空！");
		    }
			if (obj.url){
				if (obj.url.indexOf(".w") == -1){
					var items = obj.url.split("?");
					items[0] = items[0] + ".w";
					obj.url = items.join("?");
				}
				obj.url = Util.toResUrl(obj.url, obj.isCrossServiceUrl);
			}
			
			let params = obj.params || {};
			 if (key !== "switchTab"){
				 obj.url = addParamsToUrl(obj.url, params, key); //解决分享时, 没有带参数的问题	 
			 }
			
			
			delete obj.params;
			if (key === "navigateBack"){
				if (obj.$onClose){
					obj.$onClose({data: params});
				}else{
					//TODO 要求必须原路返回, 否则会有问题
					var page = getCurrentPages()[getCurrentPages().length-1];
					if (page && page.$pageOptions && page.$pageOptions.onClose)
						page.$pageOptions.onClose({data: params});
				}
				
			}else{
				//参数统一走url的方式
				getApp().$pageOptions = {
					onClose: obj.onClose || null
				}
			}
			getApp().pageOptions = getApp().pageOptions || {};
			getApp().pageOptions.action = key;
			
			oldFn.call(wx, obj);
		}
	}
}

function initBaseUrl(){
	if (!wx.App.baseUrl && window && window.location && window.location.origin){
		if (window.isMicroService){
			//微服务模式
			wx.App.baseUrl = window.location.origin;
			if (window.location.pathname.split("/").length > 1){
				//默认第一级+第二级是应用名
				wx.App.resPath = window.location.origin + "/" + window.location.pathname.split("/")[1] + "/" + window.location.pathname.split("/")[2];
			}
		}else{
			//独立模式
			//要求当前的window.location.pathname必须以/结尾，例如:/serviceName/xxapp/或/xxapp/
			wx.App.resPath = window.location.origin + window.location.pathname;
			if (wx.App.resPath.substr(-1) == "/"){
				wx.App.resPath = wx.App.resPath.substr(0, wx.App.resPath.length-1);
			}
			var base = window.location.pathname;
			base = base.substr(0, base.lastIndexOf("/")); //删除最后一个/
			base = base.substr(0, base.lastIndexOf("/")); //删除倒数第二个/
			wx.App.baseUrl = window.location.origin + base;
		}
	}
}

function supportRequestCookie() {
	let oldUploadFile = wx.uploadFile;
		wx.uploadFile = function (obj) {
			let cookie = wx.getStorageSync('Cookie');
			if (cookie) {
					let cookieValue = "";
					for(let key in cookie){
							cookieValue +=  ";";
							cookieValue += (key + "=" + cookie[key]);
					}
					obj.header = obj.header || {};
					obj.header.Cookie = cookieValue;
			}
			let result = oldUploadFile.call(wx, obj);
			return result;
		}

    let oldRequest = wx.request;
    wx.request = function (obj) {
        let cookie = wx.getStorageSync('Cookie');
        if (cookie) {
            let cookieValue = "";
            for(let key in cookie){
                cookieValue +=  ";";
                cookieValue += (key + "=" + cookie[key]);
            }
            obj.header = obj.header || {};
            obj.header.Cookie = cookieValue;
        }
        let result = oldRequest.call(wx, obj);
        result.then(function (res) {
            let header = res.header;
						let cookie = header['Set-Cookie'];
            if(cookie && cookie.indexOf(';')!=-1){
                cookie = cookie.split(";")[0];
            }
            if(cookie.indexOf('=') !=-1){
                let key = cookie.split("=")[0];
                let value = cookie.split("=")[1];
                let storeCookie = wx.getStorageSync('Cookie') || {};
                storeCookie[key] = value;
                wx.setStorageSync('Cookie', storeCookie);
            }
        }).catch(function (res) {
        });
        return result;
    }
}

class EventableRequest extends Eventable{
	constructor(){
		super();
		this.oldRequest = wx.request;
	}
	
	request(obj){
		this.fireEvent("requestSend",{
			data:obj
		});
		let result = this.oldRequest.call(wx, obj);
        let self = this;
        result.then(function (res) {
        	_Date.initServerDate(res);
        	self.fireEvent("requestSuccess",{
    			data:res
    		});
        }).catch(function (res) {
        	_Date.initServerDate(res);
        	self.fireEvent("requestError",{
    			data:res
    		});
        });
        return result;
    }
}

function supportRequestEvent(){
	let eventableRequest = new EventableRequest();
	wx.request = function(obj){
		return eventableRequest.request(obj);
	};
	
	wx.request.on = function(name,func){
		eventableRequest.on(name,func);
	};
	
	wx.request.off = function(name,func){
		eventableRequest.off(name,func);
	};
	//增加批操作函数
	wx.request.batch = BatchRequest.batch;
}


function interceptGetUserProfile(){
	wx.removeStorageSync('wxUserProfile');
	let profile = wx.getUserProfile;
	wx.getUserProfile = function(obj){
		let _wxUserProfile = wx.getStorageSync("wxUserProfile");
		if(_wxUserProfile){
			if(obj.success){
				obj.success(_wxUserProfile);
			}
			if(obj.complete){
				obj.complete(_wxUserProfile);
			}
			return;
		}

		let oldSuccess = obj.success;
		obj.success = function(wxUserProfile){
				wx.setStorageSync('wxUserProfile', wxUserProfile);
				return oldSuccess && oldSuccess(wxUserProfile);
		}
		let result = profile.call(wx, obj);
		return result;
	}
}

export function initenv(){
	wx._request = wx.request;
	initBaseUrl();
	navigateWrapper();
	interceptGetUserProfile();
	promisifyApi();
	requestWrapper();


		//只有微信小程序才需要支持cookie, 其它不需要: 解决批处理流程时切换账号不起作用
    if (!window){
			supportRequestCookie();
		}
	supportRequestEvent();
		
}
