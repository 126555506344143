import PageImpl from "../../../lib/base/pageImpl";
import Comp from "../dialogSelect";
import {observable, extendObservable, autorun, toJS, isObservableArray, isObservableObject, isObservable } from  "../../../lib/mobx/mobx-2.6.2.umd";
import {cloneJSON} from "../../../lib/base/util";

const ID_COL_NAME = "__id_";
export default class IndexPage extends PageImpl {
	constructor(...args){
		super(...args);
        this.dataUUID = observable();
		this.dataDef = null;
	}

    getLabels(){
        let v = Comp.getDataDef(this.dataUUID.get());
        if(v && v.labels){
            return v.labels;
        }
        return [];
    }

    getLabel(listitem,labelItem){;
        return listitem[labelItem.name];
    }

	getItems(){
        let v = Comp.getDataDef(this.dataUUID.get());
        if(v && v.data){
        	let idCol = v.data.getIdColumn();
            let items = toJS(v.data.value);
            if(ID_COL_NAME!==idCol && items && items.length>0){
            	for(let i=0,len=items.length;i<len;i++){
            		let item = items[i];
            		item[ID_COL_NAME] = item[idCol];
            	}
            }
            return items;
        }
        return [];
	}

	isMultiSelection(){
		return "true"===this.params.multiSelection;	
	}
	
    setSelected(ids){
		if(this.dataDef && this.dataDef.data){
			let selectedCol = this.dataDef.selectedCol;
			ids = ids || [];
			let data = this.dataDef.data;
			let idCol = data.getIdColumn();
			data.each((p)=>{
				let id = p.row[idCol];
				p.row[selectedCol] = (ids.indexOf(id)>-1)?"1":"";
			});
		}
    }

    setHideSearchBar(b){
    	let v = b?"true":"false";
    	this.comp("ctrlData").setValue("hideSearchBar",v);
    }
    
    onPageLoaded(event){
		if(this.params.compid){
			this.dataDef = Comp.getDataDef(this.params.compid);
			let v = this.dataDef; 
			if(v && v.filters && v.data){
				let searchBar = this.comp('searchBar');
				searchBar.setData(v.data);
				let cols = [],hideSearchBar = true;
				v.filters.forEach((filter, i)=>{
					if('smart'===filter.type){
						let filterCols = filter.filterCols;
						if(filterCols){
							filterCols = filterCols.split(',');
							cols.push.apply(cols, filterCols);
							hideSearchBar = false;
						}
					}else{
						filter.filterCol && (cols.push(filter.filterCol),hideSearchBar = false);
					}
				});
				searchBar.setFilterProps(cols.join());
				this.setHideSearchBar(hideSearchBar);
			}else{
				this.setHideSearchBar(true);
			}
			if(v && v.data){
				let pagination = this.comp('pagination');
				pagination.setData(v.data);
				let tableComp = this.comp('table');
				let idCol = v.data.getIdColumn();
				let columns = [];
				if(v.labels){
					v.labels.forEach((o, i)=>{
						let label = o.name;
						columns.push({dataIndex:label,title:v.data.label(label)});				
					});
				}
				tableComp.set({
					rowSelectionType: this.isMultiSelection()?"checkbox":"radio",
							idColumn: idCol,
							columns: columns,
							data: v.data
				});
			}
		}
        this.dataUUID.set(this.params.compid);
	}

/*特殊代码目前保留,当组件绑定外部传入data时需要下面函数重载
	comp(id){
		let comp = super.comp(id);
		if(!comp && this.dataDef && this.dataDef.data && this.dataDef.data.id==id){
			comp = this.dataDef.data;
		}
		return comp;
	}
*/
	
    onOkTap(event){
    	let tableComp = this.comp('table');
    	let ids = tableComp.getSelectedRowIds();
    	this.setSelected(ids);
        wx.navigateBack({
            params: {
              isOk: true,
              compid: this.dataUUID.get()
            }
        });
	}
}
