import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxCheckbox from  '../../../../../components/wx/WxCheckbox/WxCheckbox'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxInput from  '../../../../../components/wx/WxInput/WxInput'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import _WxText from  '../../../../../components/wx/WxText/WxText'; 
import React from 'react';
const WxCheckbox =  componentRenderWrapper(_WxCheckbox);
const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxInput =  componentRenderWrapper(_WxInput);
const WxView =  componentRenderWrapper(_WxView);
const WxText =  componentRenderWrapper(_WxText);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/associationSelect/dlg/selectValue.w');
import wxPageDeclaration from './selectValue';
import wxPageStyle from './selectValue.css'; 
 var  wxPageConfig = {};export default class __selectValuePage__ extends PageComponent {
	constructor(...args){
		super(...args);
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var cancel=$state.cancel;
		var __pageid__=$state.__pageid__;
		var labellist=$state.labellist;
		var hidden=$state.hidden;
		var loader=$state.loader;
		var show=$state.show;
		var required=$state.required;
		var toptips=$state.toptips;
		var debounce=$state.debounce;
		var readonly=$state.readonly;
		var compid=$state.compid;
		var weui=$state.weui;
		var children=$state.children;
		var __auto1=$state.__auto1;
		var checkbox=$state.checkbox;
		var loadingAfterShow=$state.loadingAfterShow;
		var checked=$state.checked;
		var text=$state.text;
		var ok=$state.ok;
		var value=$state.value;
		var _userdata=$state._userdata;
		var selected=$state.selected;
		var val=$state.val;
		var wx=$state.wx;
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var __id_=$state.__id_;
		var length=$state.length;
		var list=$state.list;
		var message=$state.message;
		var icon1=$state.icon1;
		var input=$state.input;
		var hide=$state.hide;
		var text1=$state.text1;
		var searchBar=$state.searchBar;
		var x=$state.x;
		var name=$state.name;
		var typeClass=$state.typeClass;
		var loadingNum=$state.loadingNum;
		var page=$state.page;
		var items=$state.items;
		return (
<WxView id="page" style={__styleToJSON('')} data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('x-page x-page-wx')}>
	
	<WxView style={__styleToJSON('z-index: 10001;')} id="__auto1" data-compid={((_exRun('__auto1.compid','__auto1'))(__auto1))} className={this.getModuledCss('weui-loader-full ' + ((_exRun('('+(__exRun(''+(__exRun('__auto1.loadingAfterShow','__auto1'))(__auto1)+'&&'+(__exRun(''+(__exRun('__auto1.loadingNum','__auto1'))(__auto1)+'<=0','__auto1'))(__auto1)+'','__auto1'))(__auto1)+')?"weui-loader-hide":""','__auto1'))(__auto1)))}>
		<WxView id="__auto2" className={this.getModuledCss('ball-scale-ripple-multiple')}>
			<WxView id="__auto3" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto4" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto5" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto6" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto7" className={this.getModuledCss('weui-loader-view')}/>
		</WxView>
	</WxView>
	<WxView id="inVisibleCompContainer"/>
	<WxView id="panel" style={__styleToJSON('')} className={this.getModuledCss('x-panel x-full')}>
		<WxView id="top" className={this.getModuledCss('x-panel-top')}>
			<WxView id="buttonGroup" className={this.getModuledCss('wx-btn-group')}/>
			<WxView id="searchBar" placeholder="查询条件" data-compid={((_exRun('searchBar.compid','searchBar'))(searchBar))} className={this.getModuledCss('weui-search-bar')}>
				<WxView id="view" className={this.getModuledCss('weui-search-bar__box')}>
					<WxIcon id="icon" size="16" type="search" className={this.getModuledCss('weui-icon-search_in-box')}/>
					<WxInput id="input" placeholder="请输入" type="text" value={((_exRun('searchBar.value','searchBar'))(searchBar))} data-compid={((_exRun('input.compid','input'))(input))} data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindinput={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onInput,' + ((_exRun('input.compid','input'))(input)) + ':onChange'} className={this.getModuledCss('weui-search-bar__input')}/>
					<WxIcon id="icon1" size="16" type="clear" hidden={((_exRun('!'+(__exRun('searchBar.value.length','searchBar'))(searchBar)+'','searchBar'))(searchBar))} data-compid={((_exRun('icon1.compid','icon1'))(icon1))} data-bindtap={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onClear'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-icon-clear')}/>
				</WxView>
				<WxText id="text" data-compid={((_exRun('text.compid','text'))(text))} data-bindtap={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onSearch'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-search-bar-btn')}>{(_exRun('text.text','text'))(text)}</WxText>
			</WxView>
		</WxView>
		<WxView id="content" style={__styleToJSON('top:48px;bottom:48px')} className={this.getModuledCss('x-panel-content')}>
					{(_toArray(((_exRun('list.items','list'))(list)))).map((listitem, listindex)  => (<WxView _created="true" id="list" label="动态列表" data-index={(listindex)} data-idcolumn={((_exRun('listitem.__id_','listitem'))(listitem))} data-compid={((_exRun('list.compid','list'))(list))} data-bindtap="onListTap" data-pageid={(__pageid__)} bindtap="dispatchBind" data-capture-bindtap={((_exRun('list.compid','list'))(list)) + ':onRowClick'} capturebindtap="dispatchCaptureBind" key={listitem['_key'] || _.random(0, 1000,true)}>
						<WxView id="row" className={this.getModuledCss('x-row')}>
							<WxView id="col" style={__styleToJSON('flex: 0 0 auto;width:auto;padding-left:16px;padding-right:16px')} className={this.getModuledCss('x-col xui-hignlight-selected')}>
								<WxCheckbox id="checkbox" value="1" checked={((_exRun('listitem.$children.checkbox.checked','listitem'))(listitem))} data-compid={((_exRun('listitem.$children.checkbox.compid','listitem'))(listitem))} data-bindtap={((_exRun('listitem.$children.checkbox.compid','listitem'))(listitem)) + ':onTap'} data-pageid={(__pageid__)} bindtap="dispatchBind" hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('listitem._userdata','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.selected','listitem'))(listitem)+'','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.selected.hidden','listitem'))(listitem)+'','listitem'))(listitem)+')','listitem'))(listitem))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!listitem','listitem'))(listitem)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('listitem._userdata','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.selected','listitem'))(listitem)+'','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.selected.readonly','listitem'))(listitem)+'','listitem'))(listitem)+')','listitem'))(listitem)+'','listitem'))(listitem)+')','listitem'))(listitem)+'','listitem'))(listitem)+'','listitem'))(listitem))} className={this.getModuledCss(((_exRun('listitem._userdata.selected.required.val?"x-wx-required":""','listitem'))(listitem)) + ' ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('listitem._userdata','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.selected','listitem'))(listitem)+'','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.selected.readonly','listitem'))(listitem)+'','listitem'))(listitem)+')?"readonly":""','listitem'))(listitem)))}/>
							</WxView>
							<WxView id="col1" className={this.getModuledCss('x-col')}>
								{(_toArray(((_exRun('listitem.$children.labellist.items','listitem'))(listitem)))).map((labellistitem, labellistindex)  => (<WxView _created="true" id="labellist" label="显示值" data-index={(labellistindex)} data-idcolumn={((_exRun('labellistitem.name','labellistitem'))(labellistitem))} data-compid={((_exRun('listitem.$children.labellist.compid','listitem'))(listitem))} data-pageid={(__pageid__)} data-capture-bindtap={((_exRun('listitem.$children.labellist.compid','listitem'))(listitem)) + ':onRowClick'} capturebindtap="dispatchCaptureBind" key={labellistitem['_key'] || _.random(0, 1000,true)}>
									<WxText id="text1" data-compid={((_exRun('labellistitem.$children.text1.compid','labellistitem'))(labellistitem))}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)+'===null','labellistitem'))(labellistitem)+'||'+(__exRun(''+(__exRun('('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)+'===undefined','labellistitem'))(labellistitem)+'','labellistitem'))(labellistitem)+'||'+(__exRun(''+(__exRun('('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)+'===""','labellistitem'))(labellistitem)+'','labellistitem'))(labellistitem)+')?"":('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)}</WxText>
								</WxView>))}

							</WxView>
						</WxView>
					</WxView>))}

		</WxView>
		<WxView id="bottom" className={this.getModuledCss('x-panel-bottom')}>
			<WxView id="buttonGroup1" className={this.getModuledCss('wx-btn-group')}>
				<WxButton id="ok" loading={((_exRun('ok.debounce','ok'))(ok))} data-compid={((_exRun('ok.compid','ok'))(ok))} data-bindtap="onOkTap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn')}>
					<WxIcon id="icon2" size="15" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>
					<WxView id="view2" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("确定")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===""'))()+''))()+')?"":("确定")'))()}</WxView>
				</WxButton>
				<WxButton id="cancel" loading={((_exRun('cancel.debounce','cancel'))(cancel))} data-compid={((_exRun('cancel.compid','cancel'))(cancel))} data-bindtap="$evtH_cancel_tap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn')}>
					<WxIcon id="icon3" size="15" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>
					<WxView id="view3" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("取消")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("取消")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("取消")'))()+'===""'))()+''))()+')?"":("取消")'))()}</WxView>
				</WxButton>
			</WxView>
		</WxView>
	</WxView>
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto8" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
