import RestData from "./restData";
import {isArray} from "../../lib/base/util";
import UUID from "../../lib/base/uuid";

export default class Subquery extends RestData {
	constructor(page, id, props, context){
		super(page, id, props, context);
	}

	init(){
		super.init();
	}
	
	_initDefinition() {
		super._initDefinition();
		//产生idCol相关定义
		if(!this.props.options.idColumn){
			this.props.options.idColumn = 'idCol';
			this.props.schema.items.props['idCol'] = {type:'String',label:'ID列',define:'EXPRESS'};
		}
	}
	
	saveData(option) {
		this.showError({message:"不支持保存"});
	}
	
	deleteData(option) {
		this.showError({message:"不支持删除"});
	}
	
	_loadData(data, append, parent, index, override, fireEvt){
		if(isArray(data)){
			let idColumn = this.getIdColumn();
			for (let i=0; i<data.length; i++){
				 let row = data[i];
				 if(!row[idColumn]){
						let uuid = new UUID().toString();
						row[idColumn] = uuid.replace(/-/g,'').toUpperCase();
				 }
			 }
		}
		return super._loadData(data, append, parent, index, override, fireEvt);
	}

}

wx.comp = wx.comp || {};
wx.comp.Subquery = Subquery
