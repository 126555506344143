import Component from "../../lib/base/component";
import TimerImpl from "./TimerImpl";

export default class Timer extends Component {
	constructor(page, id, props, context) {
		super(page, id, props, context);
		this.enabled = this.props.enabled == "false" ? false : true;
		this.times = isNaN(this.props.times) || this.props.times == "0" ? -1 : parseInt(this.props.times);
		this._pause = false;
		this.interval = parseInt(this.props.interval) || 1000;
		this._timerHandle = null;
		this.impl = new TimerImpl(page);
		this.init();
	}

	init() {
		this._inited = true
		if (this.enabled) this.start();
	}

	start() {
		this.impl.start({
			timerHandle: this._timerHandle,
			pause: this._pause,
			interval: this.interval,
			dispatchEventFn: (eventName, data) => {
				data.source = this;
				this.fireEvent(eventName, data);
			}
		});
	}

	stop() {
		this.impl.stop((eventName, data) => {
			data.source = this;
			this.fireEvent(eventName, data);
		});
	}

	pause() {
		this.impl.pause();
	}

	destroy() {
		this.impl._clearInterval();
		super.destroy();
	}


	initOperation() {
		super.initOperation();
		this.defineOperation('timerStart', {
			label: '',//开始
			argsDef: [
				{ name: 'times' },//触发次数
				{ name: 'interval' }
			],//间隔时间
			method: function (args) {
				if (undefined !== args.times) this.owner.times = parseInt(args.times);
				if (undefined !== args.interval) this.owner.interval = parseInt(args.interval);
				return this.owner.start();
			}
		})
		this.defineOperation('timerStop', {
			label: '',//停止
			argsDef: [],
			method: function (args) {
				return this.owner.stop();
			}
		})
		this.defineOperation('timerPause', {
			label: '',//暂停
			argsDef: [],
			method: function (args) {
				return this.owner.pause();
			}
		})
	}
};

wx.comp = wx.comp || {};
wx.comp.Timer = Timer;
