import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import _WxText from  '../../../../../components/wx/WxText/WxText'; 
import React from 'react';
const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxView =  componentRenderWrapper(_WxView);
const WxText =  componentRenderWrapper(_WxText);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/user/userDialog/authorize.w');
import wxPageDeclaration from './authorize';
import wxPageStyle from './authorize.css'; 
import wxPageConfig from './authorize.json'; 
export default class __authorizePage__ extends PageComponent {
	constructor(...args){
		super(...args);
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var wx=$state.wx;
		var __pageid__=$state.__pageid__;
		var loader=$state.loader;
		var show=$state.show;
		var message=$state.message;
		var button=$state.button;
		var toptips=$state.toptips;
		var debounce=$state.debounce;
		var hide=$state.hide;
		var compid=$state.compid;
		var weui=$state.weui;
		var __auto1=$state.__auto1;
		var typeClass=$state.typeClass;
		var loadingAfterShow=$state.loadingAfterShow;
		var loadingNum=$state.loadingNum;
		var page=$state.page;
		var text=$state.text;
		var button1=$state.button1;
		return (
<WxView navigationBarTitleText="授权" id="page" data-compid={((_exRun('page.compid','page'))(page))} className={this.getModuledCss('x-page x-page-wx')}>  
  <WxView style={__styleToJSON('z-index: 10001;')} id="__auto1" data-compid={((_exRun('__auto1.compid','__auto1'))(__auto1))} className={this.getModuledCss('weui-loader-full ' + ((_exRun('('+(__exRun(''+(__exRun('__auto1.loadingAfterShow','__auto1'))(__auto1)+'&&'+(__exRun(''+(__exRun('__auto1.loadingNum','__auto1'))(__auto1)+'<=0','__auto1'))(__auto1)+'','__auto1'))(__auto1)+')?"weui-loader-hide":""','__auto1'))(__auto1)))}> 
    <WxView id="__auto2" className={this.getModuledCss('ball-scale-ripple-multiple')}> 
      <WxView id="__auto3" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto4" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto5" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto6" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto7" className={this.getModuledCss('weui-loader-view')}/> 
    </WxView> 
  </WxView>  
    
  <WxView id="inVisibleCompContainer"/>  
  <WxView id="view" style={__styleToJSON('height:120px')}/>  
  <WxView id="row" className={this.getModuledCss('x-row')}> 
    <WxView id="col" className={this.getModuledCss('x-col x-col-flex-1')}/>  
    <WxView id="col1" className={this.getModuledCss('x-col x-col-flex-8')}> 
      <WxIcon type="safe_warn" id="icon2" size="40"/>  
      <WxText id="text" style={__styleToJSON('padding-left:12px')} data-compid={((_exRun('text.compid','text'))(text))}>{(_exRun('text.text','text'))(text)}</WxText>  
      <WxView id="view2" style={__styleToJSON('height:60px')}/>  
      <WxButton id="button" type="primary" loading={((_exRun('button.debounce','button'))(button))} data-compid={((_exRun('button.compid','button'))(button))} data-bindtap="onGetUserProfile" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn')}> 
        <WxIcon id="icon" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>  
        <WxView id="view1" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("授权")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("授权")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("授权")'))()+'===""'))()+''))()+')?"":("授权")'))()}</WxView> 
      </WxButton>
      
      <WxButton style={__styleToJSON('margin-top:8px;')} id="button1" loading={((_exRun('button1.debounce','button1'))(button1))} data-compid={((_exRun('button1.compid','button1'))(button1))} data-bindtap="$evtH_button1_tap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn')}> 
        <WxIcon id="icon2" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>  
        <WxView id="view3" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("暂时不授权")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("暂时不授权")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("暂时不授权")'))()+'===""'))()+''))()+')?"":("暂时不授权")'))()}</WxView>
      </WxButton> 
    </WxView>  
    <WxView id="col2" className={this.getModuledCss('x-col x-col-flex-1')}/> 
  </WxView> 
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto8" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
