import Component from "../../lib/base/component";
import {observable, autorun, toJS, untracked, transaction} from  "../../lib/mobx/mobx-2.6.2.umd";
import {isArray, isObject, cloneJSON} from "../../lib/base/util";
import fileApi from "./fileapi";


var FromKind = {
		UI : 'UI',
		DB : 'DB',
		DBRest : 'DBRest',
		Empty: 'Empty'
	};

var downloadURL = "/excel/download";
var exportURL = "/excel/export";
var taskURL = "/excel/task/";
var tempURL = "/storage/getObject";

export default class ExportExcel extends Component{
    constructor(page, id, props, context){
        super(page, id, props, context);
		this.data = props.data;
		
		this.from = props.from || FromKind.DB;
		this.columns = props.columns || "";
    } 
    
	getData() {
		if (this.data)
			return this.page.comp(this.data);
	}

	getVal(){
    	if (this.props.$valFn){
    		return this.page[this.props.$valFn](this.context.vars);	
    	}else{
    		return null;
    	}
    }

   	getDownloadFilename(){
    	if (this.props.$downloadFilenameFn){
    		return this.page[this.props.$downloadFilenameFn](this.context.vars);	
    	}else{
    		return null;
    	}
    }

	exportExcel(from,downloadFilename,columns) {
		if(isArray(columns) && columns.length>0){
			columns = columns.join(',');
		}
		var evtData = {source:this,cancel:false};
		this.fireEvent('beforeExport',evtData);
		if (evtData.cancel)
			return;
		return this._doExport(from,downloadFilename,columns);
	}

	checkCreateFileSuccess(data, dtd){
		var taskid = data && data.taskid;
		if(taskid){
			var checkExportHandle, times=1;
			var checkFn = ()=>{
				checkExportHandle && clearTimeout(checkExportHandle);
				
				//检查时间进行递增
				var t = 2000*times++;
				t = t>20000?20000:t;
				var self = this;
				checkExportHandle = setTimeout(function(){
					self.page.request({
						url: self.getUrl(taskURL)+taskid,
						dataType: "json",
						method: "GET",
						success: (res)=>{
							var info = res.data;
							if(info && 2===info.state){
								if(0===info.exitValue){
									dtd.resolve(info);
								}else{
									dtd.reject(info);
								}
							}else checkFn();
						},
						error: function(res){
							dtd.reject(res);
						}
					});								
				}, t);
			};
			
			checkFn();
		}else{
			dtd.reject(data);
		}						
	}

	getUrl(url){
		return this.page.getServiceUrl(url);
	}
	
	getDataDef(){
		var restData = this.getData();
		//{className:,tableName:,label:,defCols:[{name:,label:,type:,extType:}]}
		var defCols=[], def = {className:restData.className,tableName:restData.tableName,label:restData.tableName,defCols:defCols};
	   	 var coldefs = restData.getColumnDefs();
		 for (var k in coldefs){
			var colDef = coldefs[k];
			if(colDef){
                // 服务返回的数据集没有extType
				var extType = colDef.extType || colDef.type.substring(0, 1).toUpperCase() + colDef.type.substring(1);
				var type = (extType!='Image'&& extType!='File')?extType:'ObjectStorage';
				defCols.push({name:k,label:colDef.label,type:type,extType:extType});
			}
		}
		return JSON.stringify(def);
	}
	
	getDataRows(){
		var tableData = this.getData();
		var rows = tableData.toJSON();
		return JSON.stringify(rows || []);
	}

	getDataUrl(){
		var comp = this.getData();
		var queryParam = comp._createRefreshParam(0, -1, false, {});
		if (queryParam){
			var params = comp._getQueryParam(queryParam);
			var headers = {};
			comp.processHeader(headers);
			return JSON.stringify({url: comp.buildUrl(params,true), headers:headers, microServiceName: comp.microServiceName||""});
		}			
	}

	_doExport(from,downloadFilename,columns) {
		var self = this;
		from || (from = this.from);
		var promise = new Promise(async (resolve, reject) => {
			try{
				var restData = this.getData();
				var param = {dataPath:restData.className,props:'',imgLimit:200,exportEnhance:''};
				if(FromKind.UI===from || FromKind.Empty===from || FromKind.DBRest===from){
					param['dataDef'] = this.getDataDef();
					(FromKind.DBRest===from)?
						(param['dbRestUrl'] = this.getDataUrl()):
						(param['rows'] = (FromKind.UI===from?this.getDataRows():'[{}]')||'[{}]');	
				}
				if(columns || this.columns){
					param['props'] = columns || this.columns;
				}
		    	if(wx.showLoading){
		    		wx.showLoading({
		    			title: '文件生成中，请稍候',
		    			mask: true
		    		});
		    	}else{
		    		wx.showToast({
		    			title: '文件生成中，请稍候',
		    			icon: 'loading',
		    			duration: 1000*120
		    		});
		    	}
				var temUrl = "";
				if(this.props.templateUrl){
					temUrl = this.props.templateUrl
				}else{
					var attachment = this.getVal();

					if(attachment){
						var url = eval("("+attachment+")");

						temUrl = this.getUrl(tempURL)+"?storeFileName="+url[0].storeFileName;
					}
				}
				temUrl = encodeURI(temUrl);
				
				var opsJson = eval("("+this.props.option+")");
				if (opsJson) {
					//剔除selected=0的数据
					var entitys = [];
					var list = opsJson.entityList;

					for (var i in list) {
						if (list[i].selected == 1) {
							entitys.push(list[i]);
						}
					}
					opsJson.entityList = entitys;

					if (temUrl) {
						opsJson.templateUrl = temUrl;
					}

					this.props.option = JSON.stringify(opsJson)

					param['exportEnhance'] = this.props.option;

					if (opsJson.maxNum) {
						param['imgLimit'] = opsJson.maxNum;
					}
				}

				this.page.request({
					url: this.getUrl(exportURL),
					dataType: "json",
					method: "POST",
					data: param,
					header: {
						'content-type': 'application/x-www-form-urlencoded'
					}
				}).then((res)=>{
					var data = res.data;
					this.checkCreateFileSuccess(data,{resolve, reject});
				},(err)=>{
					reject(err);
				});			
			}catch(err){
				reject(err);
			}
		});

		var ret = new Promise((resolve, reject) => {
			promise.then((data)=>{
				if(data && data.result){
					var fName = data.result.filename;
					
					var arr = fName.split(".");

					var suffix = arr[arr.length-1];

					var downFilename = downloadFilename || self.getDownloadFilename();

					if(downFilename){
						downFilename = downFilename + "."+suffix;
					}
					var fileName = downFilename||fName||"导出数据.XLS";
					
					var len = fileName.length;
					var isZip = data.result.filename && ".ZIP"===data.result.filename.substring(len-4).toUpperCase();
					
					var extName = fileName.substring(len-4).toUpperCase();
					if(isZip && ".ZIP"!==extName) fileName += ".ZIP";
					
					var url = this.getUrl(downloadURL)+"?file=" + encodeURI(data.result.excel)+'&filename=' + encodeURI(fileName);
					if(window && document){
						wx.hideLoading?wx.hideLoading():wx.hideToast();
						fileApi.download(url);
						var eData = {
								'source' : this,
								'fileName' : fileName
						};
						this.fireEvent('success',eData);
						resolve(eData);
					}else{
						wx.downloadFile({
							url: url,
							filePath: fileName,
							success: (res) => {
								wx.hideLoading?wx.hideLoading():wx.hideToast();
								const filePath = res.tempFilePath;
								wx.openDocument({
									filePath: filePath,
									fileType: "xls",
									success: (res) => {
										var eData = {
												'source' : this,
												'filePath' : filePath,
												'fileName' : fileName
										};
										this.fireEvent('success',eData);
										resolve(eData);
									},
									fail:(err)=>{
										reject(err);
									}
								});
							},
							fail:(err)=>{
								reject(err);
							}
						});
					}
				}			
			},(err)=>{
				wx.hideLoading?wx.hideLoading():wx.hideToast();
				console.error(err);
				err = err || {};
				err.source = this;
				this.fireEvent('error', err);
				wx.showModal({
				    showCancel:false,
				    title: '错误',
				    content: "生成文件失败"+(err.errorInfo?(', '+err.errorInfo):(err.request && err.request.responseJSON && err.request.responseJSON.errorInfo? err.request.responseJSON.errorInfo: ""))
				  });
			});
		});
		
		return ret;
	}

    initOperation(){
   	 super.initOperation();
	   	 this.defineOperation('exportExcel', {
	   		 label : "导出",
	   		 icon : '',
	   		 init : function() {},
	   		 argsDef: [{name:'from',displayName:'数据来源'},{name:'downloadFilename',displayName:'导出文件名'},{name:'columns',displayName:'导出列'}],
	   		 method : function(args) {
	   			 this.owner.exportExcel(args.from,args.downloadFilename,args.columns);
	   		 }
	   	 });
    }
}

wx.comp = wx.comp || {};
wx.comp.ExportExcel = ExportExcel;
