import BindComponent from "../../lib/base/bindComponent";
import {untracked} from  "../../lib/mobx/mobx-2.6.2.umd";
import UUID from "../../lib/base/uuid";

export default class DialogSelect extends BindComponent {
    constructor(page, id, props, context){
        super(page, id, props, context);
        this.dataID = props.dataID;
        this.itemsetDataID = props.itemsetDataID;
        this.singleValue = props.singleValue!='false';
		this.uuid = new UUID().toString();
		DialogSelect.regComp(this);
     }

	 destroy() {
		 DialogSelect.unregComp(this);
		super.destroy();
	 }
	 
	 getDataComp(){
		 return this.page.comp(this.dataID);
	 }
		
	 getItemsetDataComp(){
		 return this.page.comp(this.itemsetDataID);
	 }
		
     buildState(context){
    	 var state = super.buildState(context);
    	 return state;
     }

     getTitle(){
    	 let title;
    	 if (this.props.titleFn) {
    		 title = this.page[this.props.titleFn](this.context.vars);
    	 }else{
    		 title = this.props.title;
    	 }
    	 
    	 return title || '请选择...';
     }
     
     execFilter(row){
    	 let ret = true;
    	 if(row && this.props.filterFn){
    		 let vars = Object.assign({},this.context.vars);
    		 vars['$row'] = row;
    		 try{
    			 ret = this.page[this.props.filterFn](vars);
    		 }catch(e){console.error(e)}
    	 }
    	 return ret;
     }
     
     getSelectDialogUrl(){
    	let dataComp = this.getItemsetDataComp();
		return !dataComp.isTree()?this.props.selectDialogUrl:this.props.selectTreeDialogUrl;
	 }
     
     isDisabled(){
    	 return this.props.$disabled && this.page[this.props.$disabled](this.context.vars);
     }
     
     showSelectDialog(evt){
     	if(this.isDisabled()){
    		return;
    	}
    	let dataComp = this.getItemsetDataComp();
    	if(dataComp && this.props.forceRefreshOnOpen){
    		dataComp.refreshData();
    	} 
    	if ((evt.currentTarget.dataset.disabled==true) || (evt.currentTarget.dataset.disabled=="true")) return;
    	let multiSelection = !this.singleValue;
    	let option = {
    		url: this.getSelectDialogUrl(),
    		params: {
    			compid: this.uuid,
    			multiSelection: multiSelection,
        		title: this.getTitle()
    		}
    	};
 		option.onClose = (event) => {
			let data = event.data || {};
			if (data.isOk){
				if(data.compid==this.uuid){
					let dataComp = this.getItemsetDataComp();
					if(dataComp){
						let rows = [];
						dataComp.each((p)=>{
							let v = p.row[this.props.itemSelected];
							if(v=='1'){
								rows.push(p.row);
								if(!multiSelection) return false;
							}
						});
						this.onChange(rows);
					}
				}else{
					console.error("返回组件id不匹配");
				}
			}
		};
 		wx.navigateTo(option);
     }
     
     onChange(rows){
    	 untracked(() => {
    		 let value = null, label = '';
    		 if(this.singleValue){
    			 let row = (rows && rows.length>0)?rows[0]:{};
    			 value = row[this.props.itemValue];
    			 label = row[this.props.itemLabel]||'';
    			 //处理填充规则
    			 if (this.props.mappings && this.props.mappings.length>0){
    				 for(let i=0,len=this.props.mappings.length;i<len;i++){
    					 let m = this.props.mappings[i];
    					 this.setValue(this.getRefPath(), m.to, row[m.from]);
    				 }
    			 }
    		 }else{
    			 //多选没有填充规则
    			 if (rows && rows.length>0){
    				 let values = [],labels=[];
    				 for(let i=0,len=this.singleValue?1:rows.length;i<len;i++){
    					 let row = rows[i];
    					 values.push(row[this.props.itemValue]);
    					 labels.push(row[this.props.itemLabel]||''); 
    				 }
    				 value = values.join(',');
    				 label = labels.join(',');
    			 }
    		 }
    		 this.doChange({value: value});//触发valueChange时间，必须先修改value
   	         if (this.props.$labelpath){
   	        	 let labelpath = this.page[this.props.$labelpath](this.context.vars);
   	        	 labelpath && this.setValue(labelpath, this.props.labelprop, label);
   	         }
    	 });
     }
}

wx._sys_DialogSelect_comps || (wx._sys_DialogSelect_comps = {});	
DialogSelect.regComp = function(comp){
	comp && comp.uuid && (wx._sys_DialogSelect_comps[comp.uuid] = comp);
};

DialogSelect.unregComp = function(comp){
	comp && comp.uuid && (delete wx._sys_DialogSelect_comps[comp.uuid]);
};

DialogSelect.getComp = function(uuid){
	return wx._sys_DialogSelect_comps[uuid];
};

DialogSelect.getDataDef = function(uuid){
	let comp = DialogSelect.getComp(uuid);
	if(comp){
		let displayProps, props = comp.props && comp.props.displayProps && comp.props.displayProps.split(',');
		if(props){
			displayProps = [];
			props.forEach((o, i)=>{
				displayProps.push({name:o});
			});
		}
		return {
			data: comp.getItemsetDataComp(),
			filters: comp.props.filters,
			selectedCol: comp.props.itemSelected,
			labels: displayProps || [{name:comp.props.itemLabel||comp.props.itemValue}],
			treeRootLabel: comp.props.treeRootLabel
		};
	}
}

wx.comp = wx.comp || {};
wx.comp.DialogSelect = DialogSelect;
