import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxLabel from  '../../../../../components/wx/WxLabel/WxLabel'; 
import _WxImage from  '../../../../../components/wx/WxImage/WxImage'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import React from 'react';
const WxLabel =  componentRenderWrapper(_WxLabel);
const WxImage =  componentRenderWrapper(_WxImage);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxView =  componentRenderWrapper(_WxView);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/user/userDialog/user.w');
import wxPageDeclaration from './user';
import wxPageStyle from './user.css'; 
import wxPageConfig from './user.json'; 
export default class __userPage__ extends PageComponent {
	constructor(...args){
		super(...args);
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var wx=$state.wx;
		var __pageid__=$state.__pageid__;
		var data=$state.data;
		var show=$state.show;
		var image1=$state.image1;
		var message=$state.message;
		var url=$state.url;
		var label4=$state.label4;
		var toptips=$state.toptips;
		var label5=$state.label5;
		var current=$state.current;
		var compid=$state.compid;
		var phone=$state.phone;
		var name=$state.name;
		var typeClass=$state.typeClass;
		var page=$state.page;
		var email=$state.email;
		return (
<WxView navigationBarTitleText="个人信息" id="page" data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('null x-page-wx')}>  
    
  <WxView id="inVisibleCompContainer1"/>  
  <WxView id="row1" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col1" style={__styleToJSON('width:138px')} className={this.getModuledCss('x-col x-col-fixed')}> 
      <WxImage id="image1" mode="aspectFit" style={__styleToJSON('width:128px;height:128px;border-radius: 50%;')} src={wxPagePathResolver(((_exRun('image1.url','image1'))(image1)))} data-compid={((_exRun('image1.compid','image1'))(image1))}/> 
    </WxView>  
    <WxView id="col2" className={this.getModuledCss('x-col x-col-flex-1 x-col-center')}> 
      <WxLabel id="label1" style={__styleToJSON('font-weight: bold;font-size:24px')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('data.current.name','data'))(data)+')','data'))(data)+'===null','data'))(data)+'||'+(__exRun(''+(__exRun('('+(__exRun('data.current.name','data'))(data)+')','data'))(data)+'===undefined','data'))(data)+'','data'))(data)+'||'+(__exRun(''+(__exRun('('+(__exRun('data.current.name','data'))(data)+')','data'))(data)+'===""','data'))(data)+'','data'))(data)+')?"":('+(__exRun('data.current.name','data'))(data)+')','data'))(data)}</WxLabel> 
    </WxView> 
  </WxView>  
  <WxView id="row2" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col3" style={__styleToJSON('width:48px')} className={this.getModuledCss('x-col x-col-fixed x-col-center')}> 
      <WxIcon style={__styleToJSON('color: #10AEFF;')} id="iconphoneNumber" className={this.getModuledCss('icon-android-call wx-icon')}></WxIcon> 
    </WxView>  
    <WxView id="col4" className={this.getModuledCss('x-col x-col-center')}> 
      <WxLabel id="label2">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('data.current.phone','data'))(data)+')','data'))(data)+'===null','data'))(data)+'||'+(__exRun(''+(__exRun('('+(__exRun('data.current.phone','data'))(data)+')','data'))(data)+'===undefined','data'))(data)+'','data'))(data)+'||'+(__exRun(''+(__exRun('('+(__exRun('data.current.phone','data'))(data)+')','data'))(data)+'===""','data'))(data)+'','data'))(data)+')?"":('+(__exRun('data.current.phone','data'))(data)+')','data'))(data)}</WxLabel> 
    </WxView>  
    <WxView id="col5" className={this.getModuledCss('x-col')}/> 
  </WxView>  
  <WxView id="row3" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col6" style={__styleToJSON('width:48px')} className={this.getModuledCss('x-col x-col-fixed x-col-center')}> 
      <WxIcon style={__styleToJSON('color: #10AEFF;')} id="iconEmail" className={this.getModuledCss('icon-android-mail wx-icon')}></WxIcon> 
    </WxView>  
    <WxView id="col7" className={this.getModuledCss('x-col x-col-center')}> 
      <WxLabel id="label3">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('data.current.email','data'))(data)+')','data'))(data)+'===null','data'))(data)+'||'+(__exRun(''+(__exRun('('+(__exRun('data.current.email','data'))(data)+')','data'))(data)+'===undefined','data'))(data)+'','data'))(data)+'||'+(__exRun(''+(__exRun('('+(__exRun('data.current.email','data'))(data)+')','data'))(data)+'===""','data'))(data)+'','data'))(data)+')?"":('+(__exRun('data.current.email','data'))(data)+')','data'))(data)}</WxLabel> 
    </WxView>  
    <WxView id="col8" className={this.getModuledCss('x-col')}/> 
  </WxView>  
  <WxView id="row4" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col9" style={__styleToJSON('width:48px')} className={this.getModuledCss('x-col x-col-fixed x-col-center')}> 
      <WxIcon style={__styleToJSON('color: #10AEFF;')} id="iconPwd" className={this.getModuledCss('icon-android-information wx-icon')}></WxIcon> 
    </WxView>  
    <WxView id="col10" className={this.getModuledCss('x-col x-col-center')}> 
      <WxLabel id="label4" style={__styleToJSON('color:#2fa4e7')} data-compid={((_exRun('label4.compid','label4'))(label4))} data-bindtap="changepwBtnClick" data-pageid={(__pageid__)} bindtap="dispatchBind">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("修改密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("修改密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("修改密码")'))()+'===""'))()+''))()+')?"":("修改密码")'))()}</WxLabel> 
    </WxView>  
    <WxView id="col11" className={this.getModuledCss('x-col')}/> 
  </WxView>  
  <WxView id="row5" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col12" style={__styleToJSON('width:48px')} className={this.getModuledCss('x-col x-col-fixed x-col-center')}> 
      <WxIcon style={__styleToJSON('color: #F43530;')} id="iconLogout" className={this.getModuledCss('icon-android-close wx-icon')}></WxIcon> 
    </WxView>  
    <WxView id="col13" className={this.getModuledCss('x-col x-col-center')}> 
      <WxLabel id="label5" style={__styleToJSON('color:#2fa4e7')} data-compid={((_exRun('label5.compid','label5'))(label5))} data-bindtap="quitBtnClick" data-pageid={(__pageid__)} bindtap="dispatchBind">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("注销")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("注销")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("注销")'))()+'===""'))()+''))()+')?"":("注销")'))()}</WxLabel> 
    </WxView>  
    <WxView id="col14" className={this.getModuledCss('x-col')}/> 
  </WxView> 
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto1" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
