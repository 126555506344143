var Config = {
	
	doGetConfig : function(serviceName,name,grouping,path,range,methodName){
		var params = {};
		if(name){
			params.name = name;
		}
		if(grouping!=undefined){
			params.grouping = grouping;
		}
		if(path!=undefined){
			params.path = path;
		}
		if(range){
			params.range = range;
		}

        return new Promise((resolve,reject)=>{
            wx.request({
                url: "/" + serviceName + "/config/config/"+methodName,
				data: params
            }).then(function(data){ 
                if ((data.statusCode >= 200) && (data.statusCode < 300)){
    				data = data.data;
                    resolve(data);
                }else{
                	reject("获取系统配置出错");
                }
            });
        });
	},

    getConfig : function(serviceName,name,grouping,path,range){
		return this.doGetConfig(serviceName,name,grouping,path,range,"getconfig");
    },

	getOrgConfig : function(serviceName,name,grouping,path,range){
		return this.doGetConfig(serviceName,name,grouping,path,range,"getorgconfig");
	}

};

export default Config;
