import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './treeSelectValue.user';

 var $g_fns_tableData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['__id_','_key']);
		}
}; 


 var $g_fns_tableData1 = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['name','_key']);
		}
}; 


 var $g_fns_ctrlData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['id','hideSearchBar','_key']);
		}
}; 


import '../../wrapper/wrapper';
import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../list/list'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../row/css/row.css'; 
import '../../buttonGroup/css/buttonGroup.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../text/text'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../tree/tree'; 
import '../../tree/css/tree.css'; 
import '../../breadcrumb/breadcrumb'; 
import '../../breadcrumb/css/breadcrumb.css'; 
import '../../checkbox/checkbox'; 
import '../../checkbox/css/checkbox.css'; 
import '../../panel/css/panel.css'; 
import '../../searchBar/searchBar'; 
import '../../searchBar/css/searchBar.css'; 
import '../../loading/loading'; 
import '../../loading/css/loading.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $items_list: function({listindex,ctrlData,$row,tableData1,tableData,$comp,params,$page,props,listitem}){
 return $page.getItems() ;
}

,
 $refPathFn_checkbox1: function({listindex,ctrlData,$row,tableData1,tableData,$comp,$item,params,$page,props,listitem}){
 return listitem._path ;
}

,
 $refFn_checkbox1: function({listindex,ctrlData,$row,tableData1,tableData,$comp,$item,params,$page,props,listitem}){
 return listitem.__selected_ ;
}

,
 $items_labellist: function({listindex,labellistindex,$row,tableData,params,props,listitem,ctrlData,tableData1,$comp,$item,labellistitem,$page}){
 return $page.getLabels() ;
}

,
 $attrBindFn_class_panel: function({ctrlData,$row,tableData1,tableData,$comp,params,$page,props}){
 try{return {'x-hide-search-bar':wx.Util.iif(ctrlData.current.hideSearchBar=="true",true,false)}}catch(e){return ''} ;
}

,
 $attrBindFn_text_text1: function({listindex,labellistindex,$row,tableData,params,props,listitem,ctrlData,tableData1,$comp,$item,labellistitem,$page}){
 try{return $page.getLabel(listitem,labellistitem)}catch(e){return ''} ;
}

,$evtH_cancel_tap: function({$event,ctrlData,$row,$data,tableData1,tableData,$item,params,$page,props}){
let $$$args = arguments[0];
	let args={};
	return $page.$compByCtx('commonOperation',$event.source).executeOperation('close', args, $$$args);

}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"tableData",
					"type":"array",
					"items":{
						"fns":$g_fns_tableData,
						"type":"object",
						"key":"_key",
						"props":{
							"__id_":{
								"define":"__id_",
								"label":"id",
								"type":"string"
							},
							"_key":{
								"type":"string"
							}
						}
					}
				},
				"options":{
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"__id_"
				},
				"id":"tableData",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"tableData1",
					"type":"array",
					"items":{
						"fns":$g_fns_tableData1,
						"type":"object",
						"key":"_key",
						"props":{
							"name":{
								"define":"name",
								"label":"名称",
								"type":"string"
							},
							"_key":{
								"type":"string"
							}
						}
					}
				},
				"options":{
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"name"
				},
				"id":"tableData1",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"ctrlData",
					"type":"array",
					"items":{
						"fns":$g_fns_ctrlData,
						"type":"object",
						"key":"_key",
						"props":{
							"id":{
								"define":"id",
								"label":"id",
								"type":"string"
							},
							"hideSearchBar":{
								"define":"hideSearchBar",
								"label":"隐藏搜索",
								"type":"string"
							},
							"_key":{
								"type":"string"
							}
						}
					}
				},
				"initData":[
					{
						"id":"data",
						"hideSearchBar":"false"
					}
				],
				"options":{
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"id"
				},
				"id":"ctrlData",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"loaded":"onPageLoaded"
			},
			"id":"page",
			"params":{
				"compid":"String"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/loading/loading'),
		"props":{
			"loadingNum":0,
			"id":"__auto1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"panel",
			"$attrBindFns":{
				"class":"$attrBindFn_class_panel"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/searchBar/searchBar'),
		"props":{
			"input":"input",
			"$events":{
				"search":"onSearch"
			},
			"id":"searchBar"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"id":"input"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"icon1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/text/text'),
		"props":{
			"id":"text",
			"text":"搜索"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/tree/tree'),
		"props":{
			"selectedColumn":"__selected_",
			"labelColumn":"name",
			"id":"tree",
			"barID":"breadcrumb",
			"rootLabel":"第一级",
			"selectAllID":"checkbox",
			"multiSelection":false
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/breadcrumb/breadcrumb'),
		"props":{
			"compDisabled":false,
			"id":"breadcrumb"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"label"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/checkbox/checkbox'),
		"props":{
			"id":"checkbox",
			"value":"1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/list/list'),
		"props":{
			"$items":"$items_list",
			"item":"listitem",
			"autoRefresh":true,
			"dataId":"tableData",
			"$template":[
				{
					"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
					"props":{
						"id":"col2"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/checkbox/checkbox'),
					"props":{
						"$propName":"__selected_",
						"$refFn":"$refFn_checkbox1",
						"id":"checkbox1",
						"$refPathFn":"$refPathFn_checkbox1",
						"value":"1"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
					"props":{
						"id":"col4"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/list/list'),
					"props":{
						"$items":"$items_labellist",
						"item":"labellistitem",
						"autoRefresh":true,
						"dataId":"tableData1",
						"$template":[
							{
								"cls":wx.compClass('$UI/wxsys/comps/text/text'),
								"props":{
									"id":"text1",
									"$attrBindFns":{
										"text":"$attrBindFn_text_text1"
									}
								}
							}
						],
						"autoLoadNextPage":true,
						"index":"labellistindex",
						"id":"labellist",
						"items":"tableData1.value",
						"key":"_key"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
					"props":{
						"id":"col5"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/text/text'),
					"props":{
						"id":"text",
						"text":"下级"
					}
				}
			],
			"autoLoadNextPage":true,
			"index":"listindex",
			"id":"list",
			"items":"tableData.value",
			"key":"_key"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"cancel"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"ok"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"__auto8":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			},
			"depends":["__toptips__.show","__toptips__.compid"]
		},
		"inVisibleCompContainer":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"__auto1":{
			"depends":["__auto1.loadingAfterShow","__auto1.loadingNum","__auto1.compid"],
			"__auto2":{
				"__auto7":{
					"depends":[]
				},
				"depends":[],
				"__auto5":{
					"depends":[]
				},
				"__auto6":{
					"depends":[]
				},
				"__auto3":{
					"depends":[]
				},
				"__auto4":{
					"depends":[]
				}
			}
		},
		"panel":{
			"top":{
				"depends":[],
				"searchBar":{
					"view":{
						"input":{
							"depends":["searchBar.value","input.compid","__pageid__","searchBar.compid"]
						},
						"depends":[],
						"icon":{
							"depends":[]
						},
						"icon1":{
							"depends":["searchBar.value.length","icon1.compid","searchBar.compid","__pageid__"]
						}
					},
					"depends":["searchBar.compid","__pageid__"],
					"text":{
						"depends":["text.compid","searchBar.compid","__pageid__","text.text"]
					}
				}
			},
			"depends":["panel.class","panel.compid"],
			"bottom":{
				"depends":[],
				"buttonGroup1":{
					"cancel":{
						"depends":["cancel.debounce","cancel.compid","__pageid__"],
						"icon3":{
							"depends":[]
						},
						"view3":{
							"depends":[]
						}
					},
					"depends":[],
					"ok":{
						"depends":["ok.debounce","ok.compid","__pageid__"],
						"view2":{
							"depends":[]
						},
						"icon2":{
							"depends":[]
						}
					}
				}
			},
			"content":{
				"depends":[],
				"tree":{
					"depends":["tree.multi","tree.compid"],
					"panel":{
						"top":{
							"view":{
								"breadcrumb":{
									"depends":["breadcrumb.list","breadcrumb.compid"],
									"icon":{
										"depends":["index"]
									},
									"label":{
										"depends":["breadcrumb.length","index","label.compid","breadcrumb.compid","__pageid__","item"]
									}
								},
								"depends":[]
							},
							"depends":[]
						},
						"depends":[],
						"content":{
							"depends":[],
							"default1":{
								"depends":[]
							},
							"default0":{
								"depends":[]
							},
							"row":{
								"col":{
									"depends":[],
									"checkbox":{
										"depends":["checkbox.checked","checkbox.compid","__pageid__"]
									}
								},
								"depends":[],
								"col1":{
									"depends":[],
									"label1":{
										"depends":[]
									}
								}
							},
							"list":{
								"row1":{
									"depends":[],
									"col2":{
										"depends":["list.items[].$children.col2.compid","__pageid__"],
										"checkbox1":{
											"depends":["list.items[]._userdata.__selected_.required.val","list.items[]._userdata","list.items[]._userdata.__selected_","list.items[]._userdata.__selected_.readonly","list.items[].$children.checkbox1.checked","list.items[].$children.checkbox1.compid","__pageid__","list.items[]._userdata.__selected_.hidden","list.items[]"]
										}
									},
									"col3":{
										"depends":[],
										"row2":{
											"depends":[],
											"col4":{
												"labellist":{
													"depends":["list.items[].$children.labellist.items","list.items[].$children.labellist.items[]","list.items[].$children.labellist.items[].name","list.items[].$children.labellist.compid","__pageid__"],
													"text1":{
														"depends":["list.items[].$children.labellist.items[].$children.text1.compid","list.items[].$children.labellist.items[].$children.text1.text"]
													}
												},
												"depends":["list.items[].$children.col4.compid","__pageid__"]
											},
											"col5":{
												"children":{
													"depends":[],
													"text":{
														"depends":["list.items[].$children.text.compid","list.items[].$children.text.text"]
													},
													"view2":{
														"depends":[]
													},
													"icon1":{
														"depends":[]
													}
												},
												"depends":["list.items[].$children.col5.compid","__pageid__"]
											}
										}
									}
								},
								"depends":["list.items","list.items[]","list.items[].__id_","list.compid","__pageid__"]
							}
						}
					}
				}
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {"navigationBarTitleText":"请选择..."},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
