import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxLabel from  '../../../../../components/wx/WxLabel/WxLabel'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxInput from  '../../../../../components/wx/WxInput/WxInput'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import React from 'react';
const WxLabel =  componentRenderWrapper(_WxLabel);
const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxInput =  componentRenderWrapper(_WxInput);
const WxView =  componentRenderWrapper(_WxView);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/user/userDialog/register.w');
import wxPageDeclaration from './register';
import wxPageStyle from './register.css'; 
import wxPageConfig from './register.json'; 
export default class __registerPage__ extends PageComponent {
	constructor(...args){
		super(...args);
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var input7=$state.input7;
		var __pageid__=$state.__pageid__;
		var useSmsService=$state.useSmsService;
		var input6=$state.input6;
		var data=$state.data;
		var hidden=$state.hidden;
		var btnDisabled=$state.btnDisabled;
		var input5=$state.input5;
		var ctrl=$state.ctrl;
		var input3=$state.input3;
		var input2=$state.input2;
		var input1=$state.input1;
		var btnUserinfo=$state.btnUserinfo;
		var show=$state.show;
		var input21=$state.input21;
		var required=$state.required;
		var toptips=$state.toptips;
		var debounce=$state.debounce;
		var current=$state.current;
		var readonly=$state.readonly;
		var compid=$state.compid;
		var confirmPassword=$state.confirmPassword;
		var button1=$state.button1;
		var _userdata=$state._userdata;
		var email=$state.email;
		var val=$state.val;
		var wx=$state.wx;
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var button2=$state.button2;
		var verifyCode=$state.verifyCode;
		var more=$state.more;
		var newPassword=$state.newPassword;
		var userName=$state.userName;
		var message=$state.message;
		var btnLabel=$state.btnLabel;
		var phone=$state.phone;
		var x=$state.x;
		var name=$state.name;
		var typeClass=$state.typeClass;
		var page=$state.page;
		return (
<WxView navigationBarTitleText="注册" id="page" data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('null x-page-wx')}>  
    
  <WxView id="inVisibleCompContainer1">
  </WxView>  
    
  <WxView id="form" className={this.getModuledCss('weui-cells weui-cells_form weui-cell-full')}> 
    <WxView id="cell1" hidden={((_exRun('!'+(__exRun('ctrl.current.useSmsService','ctrl'))(ctrl)+'','ctrl'))(ctrl))} className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd1" className={this.getModuledCss('weui-cell__hd')}> 
        <WxView id="label1" className={this.getModuledCss('weui-label')}><WxLabel id="label1_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("手机号")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("手机号")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("手机号")'))()+'===""'))()+''))()+')?"":("手机号")'))()}</WxLabel></WxView> 
      </WxView>  
      <WxView id="bd1" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input1" placeholder="请输入手机号" value={((_exRun('data.current.phone','data'))(data))} type="text" data-compid={((_exRun('input1.compid','input1'))(input1))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input1.compid','input1'))(input1)) + ':onChange'} data-bindinput={((_exRun('input1.compid','input1'))(input1)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.phone','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.phone.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.phone','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.phone.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.phone.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.phone','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.phone.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView>  
    <WxView id="cell4" hidden={((_exRun('!'+(__exRun('ctrl.current.useSmsService','ctrl'))(ctrl)+'','ctrl'))(ctrl))} className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd4" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label4" className={this.getModuledCss('weui-label')}><WxLabel id="label4_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("验证码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("验证码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("验证码")'))()+'===""'))()+''))()+')?"":("验证码")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd4" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input21" placeholder="请输入验证码" value={((_exRun('data.current.verifyCode','data'))(data))} type="text" data-compid={((_exRun('input21.compid','input21'))(input21))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input21.compid','input21'))(input21)) + ':onChange'} data-bindinput={((_exRun('input21.compid','input21'))(input21)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.verifyCode.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView>
      <WxView id="ft4" className={this.getModuledCss('weui-cell__ft')}>
      	<WxView id="button2" data-compid={((_exRun('button2.compid','button2'))(button2))} data-bindtap="sendSmsBtnClick" data-pageid={(__pageid__)} bindtap="dispatchBind" disabled={((_exRun('ctrl.current.btnDisabled','ctrl'))(ctrl))} className={this.getModuledCss('weui-vcode-btn')}><WxLabel id="label_vcode-btn">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===null','ctrl'))(ctrl)+'||'+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===undefined','ctrl'))(ctrl)+'','ctrl'))(ctrl)+'||'+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===""','ctrl'))(ctrl)+'','ctrl'))(ctrl)+')?"":('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)}</WxLabel></WxView>	
      </WxView> 
    </WxView> 

    <WxView id="cell7" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd7" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label7" className={this.getModuledCss('weui-label')}><WxLabel id="label7_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("用户名")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("用户名")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("用户名")'))()+'===""'))()+''))()+')?"":("用户名")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd7" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput placeholder={((_exRun('ctrl.current.useSmsService?"默认同手机号":"请输入用户名"','ctrl'))(ctrl))} id="input7" value={((_exRun('data.current.userName','data'))(data))} type="text" data-compid={((_exRun('input7.compid','input7'))(input7))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input7.compid','input7'))(input7)) + ':onChange'} data-bindinput={((_exRun('input7.compid','input7'))(input7)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.userName','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.userName.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.userName','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.userName.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.userName.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.userName','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.userName.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView>

    <WxView id="cell2" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd21" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label2" className={this.getModuledCss('weui-label')}><WxLabel id="label2_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("新密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("新密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("新密码")'))()+'===""'))()+''))()+')?"":("新密码")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd2" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input2" placeholder="6-16位字母数字组合" password={(true)} value={((_exRun('data.current.newPassword','data'))(data))} type="text" data-compid={((_exRun('input2.compid','input2'))(input2))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input2.compid','input2'))(input2)) + ':onChange'} data-bindinput={((_exRun('input2.compid','input2'))(input2)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.newPassword.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.newPassword.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView> 
    <WxView id="cell3" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd3" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label3" className={this.getModuledCss('weui-label')}><WxLabel id="label3_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("确认密码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("确认密码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("确认密码")'))()+'===""'))()+''))()+')?"":("确认密码")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd3" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input3" placeholder="6-16位字母数字组合" password={(true)} value={((_exRun('data.current.confirmPassword','data'))(data))} type="text" data-compid={((_exRun('input3.compid','input3'))(input3))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input3.compid','input3'))(input3)) + ':onChange'} data-bindinput={((_exRun('input3.compid','input3'))(input3)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPassword.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPassword.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.confirmPassword.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPassword','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.confirmPassword.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView>

    <WxView id="morelink" className={this.getModuledCss('weui-cell weui-cell_link')}>
        <WxView id="more" data-compid={((_exRun('more.compid','more'))(more))} data-bindtap="moreBtnClick" data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-cell__bd')}><WxLabel id="label_more">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("更多")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("更多")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("更多")'))()+'===""'))()+''))()+')?"":("更多")'))()}</WxLabel></WxView>
    </WxView>

    <WxView id="cell6" hidden={((_exRun('data.current.more','data'))(data))} className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd6" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label6" className={this.getModuledCss('weui-label')}><WxLabel id="label6_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("昵称")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("昵称")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("昵称")'))()+'===""'))()+''))()+')?"":("昵称")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd6" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput id="input6" value={((_exRun('data.current.name','data'))(data))} type="text" data-compid={((_exRun('input6.compid','input6'))(input6))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input6.compid','input6'))(input6)) + ':onChange'} data-bindinput={((_exRun('input6.compid','input6'))(input6)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.name','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.name.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.name','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.name.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.name.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.name','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.name.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView>
      <WxView id="ft5" className={this.getModuledCss('weui-cell__ft')}>
      	<WxButton size="mini" type="primary" id="btnUserinfo" open-type="getUserInfo" loading={((_exRun('btnUserinfo.debounce','btnUserinfo'))(btnUserinfo))} data-compid={((_exRun('btnUserinfo.compid','btnUserinfo'))(btnUserinfo))} data-pageid={(__pageid__)} bindgetuserinfo="dispatchBind" data-bindgetuserinfo={((_exRun('btnUserinfo.compid','btnUserinfo'))(btnUserinfo)) + ':getuserinfo'} hidden={((_exRun('btnUserinfo.hidden','btnUserinfo'))(btnUserinfo))} className={this.getModuledCss('wx-btn')}>
			<WxIcon id="btnUserinfo-icon" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>
			<WxView id="btnUserinfo-view" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("使用用户信息")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("使用用户信息")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("使用用户信息")'))()+'===""'))()+''))()+')?"":("使用用户信息")'))()}</WxView>
		</WxButton>	
      </WxView> 
    </WxView>

    <WxView id="cell5" hidden={((_exRun('data.current.more','data'))(data))} className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView id="hd5" className={this.getModuledCss('weui-cell__hd')}>
        <WxView id="label5" className={this.getModuledCss('weui-label')}><WxLabel id="label5_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("邮箱")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("邮箱")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("邮箱")'))()+'===""'))()+''))()+')?"":("邮箱")'))()}</WxLabel></WxView>
      </WxView>  
      <WxView id="bd54" className={this.getModuledCss('weui-cell__bd')}> 
		<WxInput placeholder="default@mail.com" id="input5" value={((_exRun('data.current.email','data'))(data))} type="text" data-compid={((_exRun('input5.compid','input5'))(input5))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input5.compid','input5'))(input5)) + ':onChange'} data-bindinput={((_exRun('input5.compid','input5'))(input5)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.email','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.email.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.email','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.email.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.email.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.email','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.email.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView> 
    </WxView>
  </WxView>  
  <WxView id="row7" className={this.getModuledCss('x-row c-row')}> 
    <WxView id="col19" className={this.getModuledCss('x-col x-col-10')}/>  
    <WxView id="col20" className={this.getModuledCss('x-col')}> 
      <WxButton id="button1" type="primary" loading={((_exRun('button1.debounce','button1'))(button1))} data-compid={((_exRun('button1.compid','button1'))(button1))} data-bindtap="okBtnClick" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn btn-ok')}><WxIcon id="button1-icon" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/><WxView id="button1-label" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("注册")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("注册")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("注册")'))()+'===""'))()+''))()+')?"":("注册")'))()}</WxView></WxButton> 
    </WxView>  
    <WxView id="col21" className={this.getModuledCss('x-col x-col-10')}/> 
  </WxView> 
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto1" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
