import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxLabel from  '../../../../../components/wx/WxLabel/WxLabel'; 
import _WxCheckbox from  '../../../../../components/wx/WxCheckbox/WxCheckbox'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxInput from  '../../../../../components/wx/WxInput/WxInput'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import _WxText from  '../../../../../components/wx/WxText/WxText'; 
import React from 'react';
const WxLabel =  componentRenderWrapper(_WxLabel);
const WxCheckbox =  componentRenderWrapper(_WxCheckbox);
const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxInput =  componentRenderWrapper(_WxInput);
const WxView =  componentRenderWrapper(_WxView);
const WxText =  componentRenderWrapper(_WxText);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/dialogSelect/dlg/treeSelectValue.w');
import wxPageDeclaration from './treeSelectValue';
import wxPageStyle from './treeSelectValue.css'; 
import wxPageConfig from './treeSelectValue.json'; 
export default class __treeSelectValuePage__ extends PageComponent {
	constructor(...args){
		super(...args);
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var cancel=$state.cancel;
		var loader=$state.loader;
		var checkbox1=$state.checkbox1;
		var required=$state.required;
		var multi=$state.multi;
		var toptips=$state.toptips;
		var debounce=$state.debounce;
		var __selected_=$state.__selected_;
		var readonly=$state.readonly;
		var children=$state.children;
		var __auto1=$state.__auto1;
		var checked=$state.checked;
		var col4=$state.col4;
		var text=$state.text;
		var col5=$state.col5;
		var col2=$state.col2;
		var val=$state.val;
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var __id_=$state.__id_;
		var tree=$state.tree;
		var list=$state.list;
		var icon1=$state.icon1;
		var input=$state.input;
		var text1=$state.text1;
		var searchBar=$state.searchBar;
		var name=$state.name;
		var typeClass=$state.typeClass;
		var page=$state.page;
		var items=$state.items;
		var __pageid__=$state.__pageid__;
		var labellist=$state.labellist;
		var hidden=$state.hidden;
		var show=$state.show;
		var enabled=$state.enabled;
		var compid=$state.compid;
		var weui=$state.weui;
		var checkbox=$state.checkbox;
		var loadingAfterShow=$state.loadingAfterShow;
		var panel=$state.panel;
		var ok=$state.ok;
		var value=$state.value;
		var _userdata=$state._userdata;
		var wx=$state.wx;
		var length=$state.length;
		var label=$state.label;
		var message=$state.message;
		var single=$state.single;
		var hide=$state.hide;
		var breadcrumb=$state.breadcrumb;
		var x=$state.x;
		var loadingNum=$state.loadingNum;
		return (
<WxView id="page" style={__styleToJSON('')} navigationBarTitleText="请选择..." data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('x-page x-relation-select-page x-page-wx')}>
	
	<WxView style={__styleToJSON('z-index: 10001;')} id="__auto1" data-compid={((_exRun('__auto1.compid','__auto1'))(__auto1))} className={this.getModuledCss('weui-loader-full ' + ((_exRun('('+(__exRun(''+(__exRun('__auto1.loadingAfterShow','__auto1'))(__auto1)+'&&'+(__exRun(''+(__exRun('__auto1.loadingNum','__auto1'))(__auto1)+'<=0','__auto1'))(__auto1)+'','__auto1'))(__auto1)+')?"weui-loader-hide":""','__auto1'))(__auto1)))}>
		<WxView id="__auto2" className={this.getModuledCss('ball-scale-ripple-multiple')}>
			<WxView id="__auto3" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto4" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto5" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto6" className={this.getModuledCss('weui-loader-view')}/>
			<WxView id="__auto7" className={this.getModuledCss('weui-loader-view')}/>
		</WxView>
	</WxView>
	<WxView id="inVisibleCompContainer"/>
	<WxView id="panel" data-compid={((_exRun('panel.compid','panel'))(panel))} className={this.getModuledCss('x-panel x-full ' + ((_exRun('panel.class','panel'))(panel)))}>
		<WxView id="top" className={this.getModuledCss('x-panel-top')}>
			<WxView id="searchBar" placeholder="查询条件" data-compid={((_exRun('searchBar.compid','searchBar'))(searchBar))} data-pageid={(__pageid__)} className={this.getModuledCss('weui-search-bar x-search-simple')}>
				<WxView id="view" className={this.getModuledCss('weui-search-bar__box')}>
					<WxIcon id="icon" size="16" type="search" className={this.getModuledCss('weui-icon-search_in-box')}/>
					<WxInput id="input" placeholder="请输入" type="text" value={((_exRun('searchBar.value','searchBar'))(searchBar))} data-compid={((_exRun('input.compid','input'))(input))} data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindinput={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onInput,' + ((_exRun('input.compid','input'))(input)) + ':onChange'} className={this.getModuledCss('weui-search-bar__input')}/>
					<WxIcon id="icon1" size="16" type="clear" hidden={((_exRun('!'+(__exRun('searchBar.value.length','searchBar'))(searchBar)+'','searchBar'))(searchBar))} data-compid={((_exRun('icon1.compid','icon1'))(icon1))} data-bindtap={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onClear'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-icon-clear')}/>
				</WxView>
				<WxText id="text" data-compid={((_exRun('text.compid','text'))(text))} data-bindtap={((_exRun('searchBar.compid','searchBar'))(searchBar)) + ':onSearch'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('weui-search-bar-btn')}>{(_exRun('text.text','text'))(text)}</WxText>
			</WxView>
		</WxView>
		<WxView id="content" className={this.getModuledCss('x-panel-content')}>
			<WxView style={__styleToJSON('height:100%;')} id="tree" rootLabel="第一级" selectedColumn="__selected_" data-compid={((_exRun('tree.compid','tree'))(tree))} className={this.getModuledCss('x-tree ' + ((_exRun('tree.multi?"x-tree-multi":"x-tree-single"','tree'))(tree)) + ' ')}>
				<WxView id="panel" className={this.getModuledCss('x-panel x-full')}>
					<WxView height="48" id="top" style={__styleToJSON('box-sizing:border-box')} className={this.getModuledCss('x-panel-top')}>
						<WxView id="view" className={this.getModuledCss('x-tree-bar')}>
							{(_toArray(((_exRun('breadcrumb.list','breadcrumb'))(breadcrumb)))).map((item, index)  => (<WxView id="breadcrumb" size="15" data-compid={((_exRun('breadcrumb.compid','breadcrumb'))(breadcrumb))} key={index || _.random(0, 1000,true)} className={this.getModuledCss('x-breadcrumb')}>
								{childPropWrapper.bind(this)(({},{item,index})=>{return (__renderBlock(((_exRun('index!=0','index'))(index)) ? (<WxIcon id="icon" size="15" className={this.getModuledCss('linear linear-chevronright wx-icon')}/>) : (null)));},['icon'],{item,index})}<WxLabel id="label" data-index={(index)} data-compid={((_exRun('label.compid','label'))(label))} data-bindtap={((_exRun('breadcrumb.compid','breadcrumb'))(breadcrumb)) + ':_tapHandle'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss(' ' + ((_exRun('('+(__exRun(''+(__exRun('breadcrumb.length','breadcrumb'))(breadcrumb)+'!='+(__exRun('index+1','breadcrumb','index'))(breadcrumb,index)+'','breadcrumb','index'))(breadcrumb,index)+')?"enabled":""','breadcrumb','index'))(breadcrumb,index)))}>{item}</WxLabel>
							</WxView>))}

						</WxView>
					</WxView>
					<WxView id="content" style={__styleToJSON('top:48px;bottom:0px')} className={this.getModuledCss('x-panel-content')}>
						<WxView id="row" style={__styleToJSON('border-bottom:8px solid #f6f6f6;height:58px;')} className={this.getModuledCss('x-row x-row-center x-select-all')}>
							<WxView id="col" style={__styleToJSON('padding-left:15px;padding-right: 0px;')} className={this.getModuledCss('x-col x-col-fixed x-tree-checkbox')}>
								<WxCheckbox id="checkbox" value="1" checked={((_exRun('checkbox.checked','checkbox'))(checkbox))} data-compid={((_exRun('checkbox.compid','checkbox'))(checkbox))} data-bindtap={((_exRun('checkbox.compid','checkbox'))(checkbox)) + ':onTap'} data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('wx-checkbox--info')}/>
							</WxView>
							<WxView id="col1" style={__styleToJSON('margin-left:15px;padding-left:0px')} className={this.getModuledCss('x-col')}>
								<WxLabel id="label1" style={__styleToJSON('color:#666666')} className={this.getModuledCss('x-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("全选")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("全选")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("全选")'))()+'===""'))()+''))()+')?"":("全选")'))()}</WxLabel>
							</WxView>
						</WxView>
						<WxView id="default0" className={this.getModuledCss('x-tree-content-top')}/>
						{(_toArray(((_exRun('list.items','list'))(list)))).map((listitem, listindex)  => (<WxView _created="true" id="list" label="动态列表" data-index={(listindex)} data-idcolumn={((_exRun('listitem.__id_','listitem'))(listitem))} data-compid={((_exRun('list.compid','list'))(list))} data-pageid={(__pageid__)} data-capture-bindtap={((_exRun('list.compid','list'))(list)) + ':onRowClick'} capturebindtap="dispatchCaptureBind" key={listitem['_key'] || _.random(0, 1000,true)}>
							<WxView id="row1" className={this.getModuledCss('x-row x-row-center')}>
								<WxView id="col2" style={__styleToJSON('padding-left:15px;')} data-compid={((_exRun('listitem.$children.col2.compid','listitem'))(listitem))} data-bindtap="onSelectTap" data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('x-col x-col-fixed x-tree-checkbox')}>
									<WxCheckbox id="checkbox1" value="1" checked={((_exRun('listitem.$children.checkbox1.checked','listitem'))(listitem))} data-compid={((_exRun('listitem.$children.checkbox1.compid','listitem'))(listitem))} data-bindtap={((_exRun('listitem.$children.checkbox1.compid','listitem'))(listitem)) + ':onTap'} data-pageid={(__pageid__)} bindtap="dispatchBind" hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('listitem._userdata','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.__selected_','listitem'))(listitem)+'','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.__selected_.hidden','listitem'))(listitem)+'','listitem'))(listitem)+')','listitem'))(listitem))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!listitem','listitem'))(listitem)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('listitem._userdata','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.__selected_','listitem'))(listitem)+'','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.__selected_.readonly','listitem'))(listitem)+'','listitem'))(listitem)+')','listitem'))(listitem)+'','listitem'))(listitem)+')','listitem'))(listitem)+'','listitem'))(listitem)+'','listitem'))(listitem))} className={this.getModuledCss(((_exRun('listitem._userdata.__selected_.required.val?"x-wx-required":""','listitem'))(listitem)) + ' wx-checkbox--info ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('listitem._userdata','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.__selected_','listitem'))(listitem)+'','listitem'))(listitem)+'&&'+(__exRun('listitem._userdata.__selected_.readonly','listitem'))(listitem)+'','listitem'))(listitem)+')?"readonly":""','listitem'))(listitem)))}/>
								</WxView>
								<WxView id="col3" style={__styleToJSON('border-bottom:0.5px solid #f6f6f6;margin-left:15px;padding-left:0px')} className={this.getModuledCss('x-col')}>
									<WxView id="row2" style={__styleToJSON('min-height:50px;')} className={this.getModuledCss('x-row x-row-center')}>
										<WxView id="col4" style={__styleToJSON('padding-left:0px')} data-compid={((_exRun('listitem.$children.col4.compid','listitem'))(listitem))} data-bindtap="onSelectTap" data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('x-col')}>
											{(_toArray(((_exRun('listitem.$children.labellist.items','listitem'))(listitem)))).map((labellistitem, labellistindex)  => (<WxView _created="true" id="labellist" label="显示值" data-index={(labellistindex)} data-idcolumn={((_exRun('labellistitem.name','labellistitem'))(labellistitem))} data-compid={((_exRun('listitem.$children.labellist.compid','listitem'))(listitem))} data-pageid={(__pageid__)} data-capture-bindtap={((_exRun('listitem.$children.labellist.compid','listitem'))(listitem)) + ':onRowClick'} capturebindtap="dispatchCaptureBind" key={labellistitem['_key'] || _.random(0, 1000,true)}>
												<WxText id="text1" data-compid={((_exRun('labellistitem.$children.text1.compid','labellistitem'))(labellistitem))}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)+'===null','labellistitem'))(labellistitem)+'||'+(__exRun(''+(__exRun('('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)+'===undefined','labellistitem'))(labellistitem)+'','labellistitem'))(labellistitem)+'||'+(__exRun(''+(__exRun('('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)+'===""','labellistitem'))(labellistitem)+'','labellistitem'))(labellistitem)+')?"":('+(__exRun('labellistitem.$children.text1.text','labellistitem'))(labellistitem)+')','labellistitem'))(labellistitem)}</WxText>
											</WxView>))}

										</WxView>
										<WxView id="col5" style={__styleToJSON('color:#3296FA;padding-right:10px;font-size:11px')} data-compid={((_exRun('listitem.$children.col5.compid','listitem'))(listitem))} data-bindtap="onChildrenTap" data-pageid={(__pageid__)} bindtap="dispatchBind" className={this.getModuledCss('x-col x-col-fixed')}>
											<WxView id="children" className={this.getModuledCss('')}>
												<WxView id="view2" style={__styleToJSON('display:inline-block;height:14px;border-left:1px solid #f3f3f3;')}/>
												<WxIcon id="icon1" size="15" style={__styleToJSON('padding-left:10px;padding-rigth:5px;')} className={this.getModuledCss('dataControl dataControl-dodeparent wx-icon')}/>
												<WxText id="text" data-compid={((_exRun('listitem.$children.text.compid','listitem'))(listitem))}>{(_exRun('listitem.$children.text.text','listitem'))(listitem)}</WxText>
											</WxView>
										</WxView>
									</WxView>
								</WxView>
							</WxView>
						</WxView>))}

						<WxView id="default1" className={this.getModuledCss('x-tree-content-bottom')}/>
					</WxView>
				</WxView>
			</WxView>
		</WxView>
		<WxView id="bottom" className={this.getModuledCss('x-panel-bottom')}>
			<WxView id="buttonGroup1" className={this.getModuledCss('wx-btn-group')}>
				<WxButton id="cancel" loading={((_exRun('cancel.debounce','cancel'))(cancel))} data-compid={((_exRun('cancel.compid','cancel'))(cancel))} data-bindtap="$evtH_cancel_tap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn wx-btn--default')}>
					<WxIcon id="icon3" size="15" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>
					<WxView id="view3" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("取消")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("取消")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("取消")'))()+'===""'))()+''))()+')?"":("取消")'))()}</WxView>
				</WxButton>
				<WxButton id="ok" loading={((_exRun('ok.debounce','ok'))(ok))} data-compid={((_exRun('ok.compid','ok'))(ok))} data-bindtap="onOkTap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn  wx-btn--primary')}>
					<WxIcon id="icon2" size="15" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>
					<WxView id="view2" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("确定")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("确定")'))()+'===""'))()+''))()+')?"":("确定")'))()}</WxView>
				</WxButton>
			</WxView>
		</WxView>
	</WxView>
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto8" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
