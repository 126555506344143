import PageImpl from "../../../lib/base/pageImpl";

export default class IndexPage extends PageImpl {
	constructor(...args){
		super(...args);
	}

	onGetUserProfile(){
		wx.getUserProfile({desc:"用于完善用户信息"}).finally(function(){
            return wx.navigateBack();
        });
	}
}
