/*! 
 * WeX5 v3 (http://www.justep.com) 
 * Copyright 2015 Justep, Inc.
 * Licensed under Apache License, Version 2.0 (http://www.apache.org/licenses/LICENSE-2.0) 
 */

import TableData from "../tableData/tableData";
import Data from "../tableData/data";
import RestData from "../restData/restData";
import _String from "../../lib/base/string";
import {isArray, isObject} from "../../lib/base/util";
import _Date from "../../lib/base/date";

const Filter = RestData.Filter;
const fns = RestData.fns;

class ActionDef{
	constructor(def, type) {
		this._compId = def && def.compid;
		this._def = def;
		this._type = type;
	}
	
	getType(){
		return this._type;
	}
	
	has(){
		return !!this._compId;
	}
	
	getReqComp(model){
		return this._compId && model && model.comp(this._compId);
	}
	
	getReqCompId(){
		return this._compId;
	}

	getDefValue(name){
		return this._def && this._def[name];
	}
}

class QueryActionDef extends ActionDef{
	constructor(def) {
		super(def, "query");
	}
	
	getParentParamName(){
		return this.getDefValue("parentParamName") || "parent";
	}
	
	getPageParamName(){
		return this.getDefValue("pageParamName") || "page";
	}
	
	getSizeParamName(){
		return this.getDefValue("sizeParamName") || "size";
	}
	
	getMasterParamName(){
		return this.getDefValue("masterParamName");
	}

	hasMasterParamName(){
		return !!this.getMasterParamName();
	}

	getFilterParamName(){
		return this.getDefValue("filterParamName");
	}

	hasFilterParam(){
		return !!this.getFilterParamName();
	}
}

class SaveActionDef extends ActionDef{
	constructor(def) {
		super(def, "save");
	}

	getBodyParamName(model){
		let comp = this.getReqComp(model);
		let ret;
		if(comp && comp.props && comp.props.defParams){
			for (let [n, v] of Object.entries(comp.props.defParams)){
				if(v.kind==="RequestBody"){
					ret = n;
					break;
				}
			}
		}
		return ret;
	}
}

class DeleteActionDef extends ActionDef{
	constructor(def) {
		super(def, "delete");
	}
	
	getIdParemName(){
		return this.getDefValue("idParamName") || "id";
	}
}

class NewActionDef extends ActionDef{
	constructor(def) {
		super(def, "new");
	}
}

export default class ServiceData extends TableData {
    constructor(page, id, props, context) {
        super(page, id, props, context);
    }
    
	init(){
		this.filters = new Filter();
		this._filters = {};
		this.directDeleteMode = true;  //默认直接删除
		this.filterFns = fns;
		super.init();
	}
    
    hasDependence(){
 		return true;
 	}

    getFilter(name) {
    	return this.filters.getFilter(name) || this._filters[name];
	}
	
    setFilter(name, filters) {
    	let filter = filters;
    	if(isArray(filters)){
    		//如果是数组只能是同一类
    		filter = filters.length>0?filters[0]:null;
    	}
    	if(isObject(filter) && filter.name && filter.name.startsWith("@")){
    		this.filters.deleteFilter(name); 
    		return this._filters[name] = filters;
    	}else{
    		this._filters && (delete this._filters[name]);
    		return this.filters.setFilter(name, filters);
    	}
	}
    
	_initDefinition() {
		//创建action定义
		this.queryActionDef = new QueryActionDef(this.props.options.queryActionDef);
		this.newActionDef = new NewActionDef(this.props.options.newActionDef);
		this.deleteActionDef = new DeleteActionDef(this.props.options.deleteActionDef);
		this.saveActionDef = new SaveActionDef(this.props.options.saveActionDef);

		super._initDefinition();
		
		!this.saveActionDef.has() && (this.confirmRefresh = false);
		!this.deleteActionDef.has() && (this.confirmDelete = false);

		let func = function(serviceRequestComp, type) {
			//进行事件绑定
			serviceRequestComp.on('success',this['_doRequest'+this._getActionType(type)+'Success'],this,{first:true});
			serviceRequestComp.on('error',this['_doRequest'+this._getActionType(type)+'Error'],this,{first:true});
			serviceRequestComp.on('beforeRequest',this['_doRequest'+this._getActionType(type)+'Before'],this);
			// 标记组件初始化完成
			type=="query" && this._initData(true);
		};

		if (this.queryActionDef.has()) {// 有依赖的主data
			this.compPromise(this.queryActionDef.getReqCompId()).then((serviceRequestComp) => {
				func.call(this, serviceRequestComp, "query");
			}, function(error) {// data[xid=self.xid]初始化失败，error
				throw new Error("data[id="+this.id+"]初始化失败，" +error);
			});
		}else throw new Error("data[id="+this.id+"]初始化失败，没有指定服务请求组件");
		
		let action = ['new','delete','save'];
		for (let name of action.values()) {
			let actDefAttrName = name+'ActionDef';
			if(this[actDefAttrName].has()){
				this.compPromise(this[actDefAttrName].getReqCompId()).then((serviceRequestComp) => {
					func.call(this, serviceRequestComp, name);
				}, function(error) {// data[xid=self.xid]初始化失败，error
					throw new Error("data[id="+this.id+"]初始化失败，" +error);
				});
			}
		}
	}
    
    _initData(force){
 		if(force)
 			super._initData();
     }

  	canSmartLoad(){
  		let smartLoad = super.canSmartLoad();
  		if(!smartLoad){
  			//进行restData判断
  			smartLoad = this.smartKey===this.oldSmartKey;
  		}
  		return smartLoad;
  	}
  	
    _getActionType(type){
    	type || (type="query");
    	return type.substring(0,1).toUpperCase()+type.substring(1);
    }

    getServiceReqComp(type){
    	type || (type="query");
		return this[type+'ActionDef'].getReqComp(this.page);
    }

    _doRequestQueryBefore(event){
    	if(event){
    		let p = [event.url],names=['header','data'];
    		for(let i=0;i<names.length;i++){
    			let o = event[names[i]];
    			if(o){
    				let l = [];
    				for (let [key, value] of Object.entries(o)) {//目前就处理一层
    					p.push(value+"");//强制转换成str 
    				}  
    			}
    		}
    		this.smartKey = p.join("##");
    	}else this.smartKey = "";
    }
    
    _doRequestQuerySuccess(event){
		let option= event.serviceDataOptions || {};

    	//请求成功后加载数据
		if (event.source.props.pageable) {// 需要放在前面
			let count = event.header && (event.header['Content-Range']||event.header['content-range']);
			if (count) {
				let ipos = count.lastIndexOf("/");
				let range;
				if (ipos > -1){
					range = count.substring(0,ipos);
					count = count.substring(ipos + 1);
				}
				count = parseInt(count, 10);
				this.setTotal(count, option.parentRow);
				//根据返回重新设置limit
				var pageRange = event.header['Content-PageRange']||event.header['content-pagerange'];
				if(pageRange){
					ipos = pageRange.lastIndexOf(":");
					if(ipos > -1){
						let size = pageRange.substring(ipos+1);
						size = parseInt(size);
						if(!isNaN(size)){
							this.setLimit(size, option.parentRow);
							event.serviceDataOptions && (option.limit = size);
						}
					}
				}
				if(range){
					ipos = range.lastIndexOf("-");
					if(ipos > -1){
						let i1 = range.substring(0,ipos);
						i1 = parseInt(i1, 10);
						if(!isNaN(i1)){
							this.setOffset(i1, option.parentRow);
							option && (option.offset = i1);
						} 
					}
				}
			}
		}
    	let rows = [];
    	if(isArray(event.data)) rows = event.data;
    	else if(isObject(event.data)) rows = [event.data];
    	else if(event.data!==undefined){
    		let idColumn = this.getIdColumn();
    		let o = {};
    		o[idColumn] = event.data;
    		rows = [o];
    	}
    	this.loadData(rows, option ? option.append : false, option ? option.parent : null, undefined);
    	this.oldSmartKey = this.smartKey;
    	
    		
		this.doRefreshAfter(true, option, {
			source : this,
			rows : rows
		});
    	
    	this.first();
    }
    
    _doRequestQueryError(event){
    	//请求失败清除数据
    	this.clear();
    }

    _doRequestNewBefore(event){    	
    }
    
    _doRequestNewSuccess(event){
    }
    
    _doRequestNewError(event){
    }
    
    _doRequestDeleteBefore(event){    	
    }
    
    _doRequestDeleteSuccess(event){
    }
    
    _doRequestDeleteError(event){
    }
    
    _doRequestSaveBefore(event){    	
    }
    
    _doRequestSaveSuccess(event){
    }
    
    _doRequestSaveError(event){
    }
    
    processFilterParams(p,filters){
    	if(filters || this._filters){
    		p = p || {};
    		for (let filter of Object.values(filters || this._filters)) {
    			if(!isArray(filter)){
    				let fn = filter.op, value = filter.value;
    				/*
    				if(fn=='like'||fn=='ilike'||fn=='nlike'||fn=='nilike'){
    					if(value){
    						if((value+'').indexOf('%')<0) value = '%' + value + '%';
    					}else value = '%';
    				}
    				*/
    				if(value===undefined || value===null || value==="" || (typeof(value)==='number' && isNaN(value))){
    					console.log("serviceData filter value:",value,",忽略");
    				}else{
    					var name = (filter.name && filter.name.startsWith("@"))?filter.name.substring(1):filter.name;
    					p[name] = value;
    				}
    			}else{
    				this.processFilterParams(p,filter);
    			}
    		}
    	}
    	return p;
    }
    
	buildFilter(filters) {
		let ret = [];
		let filterList = filters || this.filters.filterList;
		let variables = this.filters.variables;
		for (let o in filterList) {
			let filter = filterList[o];
			if (!filter) continue;
			let s = this._processFilter(filter,variables);
			if(isArray(s)) ret = ret.concat(s);
		}
		this.processFilterBrac(ret);
		return ret.length>0?(encodeURIComponent(ret.join('&'))):null;
	}
	
	processFilterBrac(filters){
		//进行filter中空括号处理
		let bContinue = false;
		for(let i=filters.length-1;i>=0;i--){
			let f = filters[i];
			if(')'===f){
				let j = i-1;
				if(j>=0 && ('('===filters[j] || 'or('===filters[j])){
					i--;
					bContinue = true;
					filters.splice(i,2);
				}
			}
		}
		if(bContinue) this.processFilterBrac(filters);//递归处理到所有括号全部完成
		if(filters.length>0 && 'or('===filters[0]) filters[0]='('; //第一个条件不能是or(
	}
	
	evalFilterObj(filter, variables){
		var result = null;
		if (filter.op && this.filterFns[filter.op]){
			result = this.filterFns[filter.op](filter.name, filter.value, filter.kind, this);
		}
		return result;
	}
	
	___processFilter(filter, variables){
		let result;
		var vars = Object.assign({}, this.context.vars,  {$page: this.page}, {params: this.page.params}, this.filterFns);
		vars.$$dataObj = this; //添加data对象自身
		if (typeof this.page[filter] === 'function'){
			result = this.page[filter](vars);
		}else if(isArray(filter) && filter.length>0){
			let ret = [];
			for(let i=0,len=filter.length;i<len;i++){
				let f = this.___processFilter(filter[i], variables);
				f && ret.push(f);
			}
			result = ret;
		}else if (wx.Util.isObject(filter)){
			result = this.evalFilterObj(filter, vars);
		}else if(typeof(filter)==='string')
			result = filter;
		return result;
	}
		
	_processFilter(f, variables){
		let filter = this.___processFilter(f, variables);
		if (filter==null || filter==undefined || filter===""){
			return [];
		}else if (isArray(filter)){
			return filter;
		}else{
			return [filter];
		}
	}	
    
    doRefreshData(options) {    	
    	var dfd = {};
		var promise = new Promise(function(resolve, reject) {
			dfd.resolve = resolve;
			dfd.reject = reject;
		});
		let self = this;
		
		this.compPromise(this.queryActionDef.getReqCompId()).then((serviceRequestComp)=>{
			if(serviceRequestComp.props.type == "GET"){
				this.open = true;
			}
	    	let p;
	    	if(serviceRequestComp.props.pageable){
	    		let offset = this.getOffset(options.parentRow), limit = this.getLimit(options.parentRow);
	    		let page = Math.floor(offset/limit);
	    		isNaN(page) && (page=0);
    			p = {};
    			p[this.queryActionDef.getPageParamName()] = page+1;
    			p[this.queryActionDef.getSizeParamName()] = limit;
	    	}
    		if (this.isTree()) {
    			let parentId;
    			if (options.parentRow) {
    				parentId = this.getRowID(options.parentRow);
    			}
    			let parentParamName = this.queryActionDef.getParentParamName();
    			if(!parentParamName){
    				let treeOption = this.getTreeOption();
    				let rootFilter = treeOption.rootFilter;

    				let treeFilter = _String.format("{0}={1}.{2}", treeOption.parent, parentId!==null?"eq":"is", parentId);
    				if (!options.parentRow && parentId===null && rootFilter){
    					let rootFilters = this.buildFilter(rootFilter);
    					rootFilters && rootFilters.length>0 && (treeFilter = rootFilters); 
    				}
    				this.setFilter("_sys_parent_filter_",treeFilter);
    			}else{
    				this.setFilter('_sys_parent_filter_',{op:"eq",name:"@"+parentParamName,value:parentId});
    			}
    		}
			// 主从过滤处理
			if (this.master) {
				//主从数据查询处理
				var masterData = this.master.masterData, masterRow = masterData && masterData.getCurrentRow();
				if (!masterRow){
					console.warn('从数据查询时缺少主数据');
					return false;
				}
				if (masterData) {
					let masterRowId = masterData.getRowID(masterRow);
					if (!masterRowId){
						console.warn('从数据查询时缺少主数据');
						return false;
					}

					var masterParamName = this.queryActionDef.getMasterParamName();
					if(!masterParamName){
						let def = this.getColumnDef(this.master.relation);
						let masterRelationName = (def && def.define)||this.master.relation;
						this.setFilter('_sys_master_id_filter_',_String.format("{0}=eq.{1}",masterRelationName,masterRowId||""));
					}else{
						this.setFilter('_sys_master_id_filter_',{op:"eq",name:"@"+masterParamName,value:masterRowId});
					}
				}
			}
    		
    		//处理filter
    		if(this.queryActionDef.hasFilterParam()){
    			//通用的filter表达式
    			var filter = this.buildFilter();
    			if (filter){
    				p || (p={});
    				p[this.queryActionDef.getFilterParamName()] = filter;
    			}
    		}
    		//简单的参数
    		p = this.processFilterParams(p);
			serviceRequestComp.send(p,options).then(function(){dfd.resolve();},function(){dfd.reject();});
		});
		
		return {success : true,
				async : true};
	}

	isCalculateCol(col) {
		if (col) {
			if ('string' === typeof (col))
				col = this.getColumnDef(col);
			if ('object' === typeof (col)) {
				return "EXPRESS" === col.define || "STATISTICAL" == col.define;
			}
		}
	}
	
	processSaveRow(row, ignoreSlave){
		if(row){
			let idCol = this.getIdColumn();
			let id = row[idCol];
			//转换成真实的
			delete row['_recoredState'];
			for(var col in row){
				var defCol = this.getColumnDef(col);
				
				//特殊处理时间类型,后续去除
				if(defCol.type=='datetime' && row[col]){
					let d = new Date(row[col]);
					row[col] = (new Date(d.getFullYear(),d.getMonth(),d.getDate(),d.getHours(),d.getMinutes(),d.getSeconds())).toISOString();
				}				
				
				if(this.isCalculateCol(defCol)) delete row[col];
				else if(defCol && defCol.table && defCol.table!==this.tableName) delete row[col];
				else if(defCol && defCol.define && col!=defCol.define){
					row[defCol.define] = row[col];
					delete row[col];
				}
			}
			//目前只支持一条主记录，所以增加判断当前行.id==row.id的才处理从数据
			if(this.current && this.current[idCol]===id){
				if(!ignoreSlave){
					//增加从数据处理
					var slaveRows = this.processSlaveSaveRow(row);
					if(isArray(slaveRows) && slaveRows.length>0){
						for(let i=0; i<slaveRows.length; i++){
							let v = slaveRows[i];
							let k = v.key+(v.$versionLock?("@$versionLock="+v.$versionLock):"");
							if(!isArray(row[k])){
								row[k] = v.rows; 
							}else if(v.rows && v.rows.length>0){
								let idsMap = {};
								for (let r of row[k].values()) {
									idsMap[r[v.idColumn]] = true;
								}
								for(let i=0,len=v.rows.length;i<len;i++){
									let r = v.rows[i];
									if(!idsMap[r[v.idColumn]]){
										row[k].push(r);
									}else{
										throw new Error("多个相同从数据中有相同提交记录");
									}
								}
							}
						}
					}
				}
			}　
		}
		return row;
	}
	
	processSlaveSaveRow(row){
		var len = this.slaveDatas.length;
		if(len>0){
			var ret = [];
			for ( let i = 0; i < len; i++) {
				var slaveData = this.slaveDatas[i];
				if (slaveData){
					let idCol = slaveData.getIdColumn();
					let defColID = slaveData.getColumnDef(idCol);
					let idColumn = (defColID.define||idCol);
					let key = slaveData.tableName + '.' + idColumn;
					let rows = [];
					let versionLock = null;
					let propVerLock = slaveData.getVersionLock();
					if(propVerLock) versionLock = propVerLock.define || propVerLock.name;
					
					for (let j=0,len=slaveData.value.length; j<len; j++){
						let r = slaveData.value[j];
						let state = r.getState();
						if (Data.STATE.NEW == state || Data.STATE.EDIT == state || slaveData.isSlaveChanged()){
							rows.push(slaveData.processSaveRow(slaveData.row2json(r)));
						}
					}
					
					if(rows.length>0) ret.push({key: key, idColumn: idColumn, $versionLock:versionLock, rows: rows});
				}						
			}
			return ret;
		}
	}
    
	//保存逻辑
	doSaveData(options) {
    	let dfd = {};
		let promise = new Promise(function(resolve, reject) {
			dfd.resolve = resolve;
			dfd.reject = reject;
		});
		let result = false;
		options = options || {};
		let saveAllData = !!options.allData;
		let self = this;

    	var serviceReqComp = this.getServiceReqComp('save');
    	if(serviceReqComp){
    		let rows;
    		if(saveAllData){//全数据保存,包括新增和修改的数据
				let rowsData = [];
				this.each((p)=>{
					let state = p.row.getState();
					if (Data.STATE.NEW == state || Data.STATE.EDIT == state || this.isSlaveChanged()){
						rowsData.push(this.processSaveRow(this.row2json(p.row),true));
					}
				});
				if(rowsData.length>0){
					rows = rowsData;
					result = true;
				}
    		}else{//单条保存
    			let row = options.row || options.item || options.value || this.getCurrentItem();
    			if (row) {
    				let state = row.getState();
    				if (Data.STATE.NEW == state || Data.STATE.EDIT == state || this.isSlaveChanged()) {
    					let isNew = (Data.STATE.NEW === state);
    					let method = isNew ? "POST" : "PUT";
    					let postData = this.processSaveRow(this.row2json(row), true);
    					rows = [postData];
    					result = true;
    				}
    			}
    		}

    		let name = this.saveActionDef.getBodyParamName(this.page);
    		if(rows && name){
    			let p = {};
    			p[name] = rows; 
    			serviceReqComp.send(p, options).then(function(){dfd.resolve();},function(){dfd.reject();});
    		}
    	}

		return {
			success : result,
			async : true,
			promise: promise
		};
	}
	
	getDeleteParam(rowList) {
		if (!rowList)
			return;
		var ids = [],i;

		var len = rowList.length;
		for ( i = 0; i < len; i++) {
			var row = rowList[i];
			if (Data.STATE.NEW != row.getState()) {
				ids.push(this.getRowID(row));
			}
		}
		return ids.length>0?ids.join(','):null;
	}	    

	//删除逻辑
	doDirectDeleteData(rows, options) {
    	let dfd = {};
		let promise = new Promise(function(resolve, reject) {
			dfd.resolve = resolve;
			dfd.reject = reject;
		});
    	let p={},result=false;
    	let comp = this.getServiceReqComp('delete');
    	let rowids = this.getDeleteParam(ids);
    	if(comp && rowids){
    		p[this.deleteActionDef.getIdParemName()] = rowids;
    		comp.send(p,options).then(function(){dfd.resolve();},function(){dfd.reject();});
    		result=true;
    	}
		return {success : result,
			async : true,
			promise : promise};
	}

	doNewData(rows, options) {
		return super.doNewData(rows, options);
	}

    destroy() {
		this.compPromise(this.serviceRequestCompId).then((serviceRequestComp)=>{
			serviceRequestComp.off('success',this._doRequestSuccess,this);
			serviceRequestComp.off('error',this._doRequestError,this);
		});
        super.destroy();
    }
}
wx.comp = wx.comp || {};
wx.comp.ServiceData = ServiceData;
