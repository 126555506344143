import _createPageConfig from '../../../lib/base/createPageConfig';
import PageClass from './selectValue.user';

 var $g_fns_tableData = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['__id_','_key']);
		}
}; 


 var $g_fns_tableData1 = {
		get _userdata(){
			return wx.Util.prepareUserData(this, {

			}, ['name','_key']);
		}
}; 

import '../../commonOperation/commonOperation'; 
import '../../tableData/tableData'; 
import '../../icon/css/icon.css'; 
import '../../list/list'; 
import '../../input/input'; 
import '../../input/css/input.css'; 
import '../../button/button'; 
import '../../button/css/button.css'; 
import '../../buttonGroup/css/buttonGroup.css'; 
import '../../row/css/row.css'; 
import '../../page/page'; 
import '../../page/css/page.css'; 
import '../../wrapper/wrapper'; 
import '../../text/text'; 
import '../../toptips/toptips'; 
import '../../toptips/css/toptips.css'; 
import '../../checkbox/checkbox'; 
import '../../checkbox/css/checkbox.css'; 
import '../../panel/css/panel.css'; 
import '../../searchBar/searchBar'; 
import '../../searchBar/css/searchBar.css'; 
import '../../loading/loading'; 
import '../../loading/css/loading.css'; 
import '../../wxApi/wxApi'; 
var methods = {

 $items_list: function({listindex,$row,tableData1,tableData,$comp,params,$page,props,listitem}){
 return $page.getItems() ;
}

,
 $refFn_checkbox: function({listindex,$row,tableData1,tableData,$comp,$item,params,$page,props,listitem}){
 return listitem.selected ;
}

,
 $items_labellist: function({listindex,labellistindex,$row,tableData1,tableData,$comp,$item,params,labellistitem,$page,props,listitem}){
 return $page.getLabels() ;
}

,
 $refPathFn_checkbox: function({listindex,$row,tableData1,tableData,$comp,$item,params,$page,props,listitem}){
 return listitem._path ;
}

,
 $attrBindFn_text_text1: function({listindex,labellistindex,$row,tableData1,tableData,$comp,$item,params,labellistitem,$page,props,listitem}){
 try{return $page.getLabel(listitem,labellistitem)}catch(e){return ''} ;
}

,$evtH_cancel_tap: function({$event,$row,$data,tableData1,tableData,$item,params,$page,props}){
let $$$args = arguments[0];
	let args={};
	return $page.$compByCtx('commonOperation',$event.source).executeOperation('close', args, $$$args);

}

}
var template = [
	[
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"tableData",
					"type":"array",
					"items":{
						"fns":$g_fns_tableData,
						"type":"object",
						"key":"_key",
						"props":{
							"__id_":{
								"define":"__id_",
								"label":"id",
								"type":"string"
							},
							"_key":{
								"type":"string"
							}
						}
					}
				},
				"options":{
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"__id_"
				},
				"id":"tableData",
				"filters":{}
			}
		},
		{
			"cls":wx.compClass('$UI/wxsys/comps/tableData/tableData'),
			"props":{
				"schema":{
					"limit":20,
					"orderBy":[],
					"keyItems":"_key",
					"id":"tableData1",
					"type":"array",
					"items":{
						"fns":$g_fns_tableData1,
						"type":"object",
						"key":"_key",
						"props":{
							"name":{
								"define":"name",
								"label":"名称",
								"type":"string"
							},
							"_key":{
								"type":"string"
							}
						}
					}
				},
				"options":{
					"isMain":false,
					"autoMode":"",
					"directDelete":true,
					"checkRange":"",
					"confirmDelete":true,
					"confirmRefreshText":"",
					"allowEmpty":false,
					"checkMode":"",
					"confirmDeleteText":"",
					"confirmRefresh":true,
					"idColumn":"name"
				},
				"id":"tableData1",
				"filters":{}
			}
		}
	],
	{
		"cls":wx.compClass('$UI/wxsys/comps/page/page'),
		"props":{
			"$events":{
				"loaded":"onPageLoaded"
			},
			"id":"page",
			"params":{
				"compid":"String"
			}
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wxApi/wxApi'),
		"props":{
			"id":"wxapi"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/commonOperation/commonOperation'),
		"props":{
			"id":"commonOperation"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/loading/loading'),
		"props":{
			"loadingNum":0,
			"id":"__auto1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/searchBar/searchBar'),
		"props":{
			"input":"input",
			"id":"searchBar"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/input/input'),
		"props":{
			"id":"input"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/wrapper/wrapper'),
		"props":{
			"id":"icon1"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/text/text'),
		"props":{
			"id":"text",
			"text":"搜索"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/list/list'),
		"props":{
			"$items":"$items_list",
			"item":"listitem",
			"autoRefresh":true,
			"dataId":"tableData",
			"$template":[
				{
					"cls":wx.compClass('$UI/wxsys/comps/checkbox/checkbox'),
					"props":{
						"$propName":"selected",
						"$refFn":"$refFn_checkbox",
						"id":"checkbox",
						"$refPathFn":"$refPathFn_checkbox",
						"value":"1"
					}
				},
				{
					"cls":wx.compClass('$UI/wxsys/comps/list/list'),
					"props":{
						"$items":"$items_labellist",
						"item":"labellistitem",
						"autoRefresh":true,
						"dataId":"tableData1",
						"$template":[
							{
								"cls":wx.compClass('$UI/wxsys/comps/text/text'),
								"props":{
									"id":"text1",
									"$attrBindFns":{
										"text":"$attrBindFn_text_text1"
									}
								}
							}
						],
						"autoLoadNextPage":true,
						"index":"labellistindex",
						"id":"labellist",
						"items":"tableData1.value",
						"key":"_key"
					}
				}
			],
			"autoLoadNextPage":true,
			"index":"listindex",
			"id":"list",
			"items":"tableData.value",
			"key":"_key"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"ok"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/button/button'),
		"props":{
			"id":"cancel"
		}
	},
	{
		"cls":wx.compClass('$UI/wxsys/comps/toptips/toptips'),
		"props":{
			"id":"__toptips__"
		}
	}
];
var dependTree = {
	"page":{
		"__toptips__":{
			"__auto8":{
				"depends":["__toptips__.msgs","__toptips__.items[].typeClass","__toptips__.items[].show","__toptips__.items[].message"]
			},
			"depends":["__toptips__.show","__toptips__.compid"]
		},
		"inVisibleCompContainer":{
			"depends":[]
		},
		"depends":["page.compid","__pageid__"],
		"__auto1":{
			"depends":["__auto1.loadingAfterShow","__auto1.loadingNum","__auto1.compid"],
			"__auto2":{
				"__auto7":{
					"depends":[]
				},
				"depends":[],
				"__auto5":{
					"depends":[]
				},
				"__auto6":{
					"depends":[]
				},
				"__auto3":{
					"depends":[]
				},
				"__auto4":{
					"depends":[]
				}
			}
		},
		"panel":{
			"top":{
				"buttonGroup":{
					"depends":[]
				},
				"depends":[],
				"searchBar":{
					"view":{
						"input":{
							"depends":["searchBar.value","input.compid","__pageid__","searchBar.compid"]
						},
						"depends":[],
						"icon":{
							"depends":[]
						},
						"icon1":{
							"depends":["searchBar.value.length","icon1.compid","searchBar.compid","__pageid__"]
						}
					},
					"depends":["searchBar.compid"],
					"text":{
						"depends":["text.compid","searchBar.compid","__pageid__","text.text"]
					}
				}
			},
			"depends":[],
			"bottom":{
				"depends":[],
				"buttonGroup1":{
					"cancel":{
						"depends":["cancel.debounce","cancel.compid","__pageid__"],
						"icon3":{
							"depends":[]
						},
						"view3":{
							"depends":[]
						}
					},
					"depends":[],
					"ok":{
						"depends":["ok.debounce","ok.compid","__pageid__"],
						"view2":{
							"depends":[]
						},
						"icon2":{
							"depends":[]
						}
					}
				}
			},
			"content":{
				"depends":[],
				"list":{
					"depends":["list.items","list.items[]","list.items[].__id_","list.compid","__pageid__"],
					"row":{
						"col":{
							"depends":[],
							"checkbox":{
								"depends":["list.items[]._userdata.selected.required.val","list.items[]._userdata","list.items[]._userdata.selected","list.items[]._userdata.selected.readonly","list.items[].$children.checkbox.checked","list.items[].$children.checkbox.compid","__pageid__","list.items[]._userdata.selected.hidden","list.items[]"]
							}
						},
						"depends":[],
						"col1":{
							"labellist":{
								"depends":["list.items[].$children.labellist.items","list.items[].$children.labellist.items[]","list.items[].$children.labellist.items[].name","list.items[].$children.labellist.compid","__pageid__"],
								"text1":{
									"depends":["list.items[].$children.labellist.items[].$children.text1.compid","list.items[].$children.labellist.items[].$children.text1.text"]
								}
							},
							"depends":[]
						}
					}
				}
			}
		}
	}
}
export function createPageConfig(){
	return _createPageConfig(PageClass, template, methods, {},{}, dependTree)
}
export function createPage(owner, pageid, props){
	var page = new PageClass(owner, props);
	page.$init(template, methods, pageid);
	return page;
}
