import PageComponent from  '../../../../../core/framework/PageComponent'; 
import componentRenderWrapper from  '../../../../../core/render/ComponentRenderWrapper'; 
import {propWrapper,childPropWrapper,__expression,__getPopupContainer,_exRun,__exRun,__toUrl,__renderBlock,__toArraySlot,__toJSONObjectSlot,__toJSONArraySlot,__styleToJSON,_toArray,} from  '../../../../../core/utils'; 
import _WxLabel from  '../../../../../components/wx/WxLabel/WxLabel'; 
import _WxButton from  '../../../../../components/wx/WxButton/WxButton'; 
import _WxIcon from  '../../../../../components/wx/WxIcon/WxIcon'; 
import _WxInput from  '../../../../../components/wx/WxInput/WxInput'; 
import _WxView from  '../../../../../components/wx/WxView/WxView'; 
import _WxText from  '../../../../../components/wx/WxText/WxText'; 
import React from 'react';
const WxLabel =  componentRenderWrapper(_WxLabel);
const WxButton =  componentRenderWrapper(_WxButton);
const WxIcon =  componentRenderWrapper(_WxIcon);
const WxInput =  componentRenderWrapper(_WxInput);
const WxView =  componentRenderWrapper(_WxView);
const WxText =  componentRenderWrapper(_WxText);
let wxPagePathResolver = (function(currentPath){
return function(path,isCodePath){return __toUrl(currentPath,path,isCodePath)}}
)('/wxsys/comps/user/userDialog/bindphone.w');
import wxPageDeclaration from './bindphone';
import wxPageStyle from './bindphone.css'; 
import wxPageConfig from './bindphone.json'; 
export default class __bindphonePage__ extends PageComponent {
	constructor(...args){
		super(...args);
		this.wxPagePathResolve = wxPagePathResolver;
		this.wxPageDeclaration = wxPageDeclaration();
		this.wxPageStyle = wxPageStyle;
		this.wxPageConfig = wxPageConfig;
		this.constructed();
		this.onLoad();
	}
	render(){
		let $page = this.wxPageDeclaration.$page;
	if(!$page){return <React.Fragment></React.Fragment>}		var $state = this.state.data || {data:{}};
		var __pageid__=$state.__pageid__;
		var data=$state.data;
		var hidden=$state.hidden;
		var btnDisabled=$state.btnDisabled;
		var ctrl=$state.ctrl;
		var loader=$state.loader;
		var phonenumber=$state.phonenumber;
		var input1=$state.input1;
		var show=$state.show;
		var input21=$state.input21;
		var required=$state.required;
		var button=$state.button;
		var toptips=$state.toptips;
		var debounce=$state.debounce;
		var current=$state.current;
		var readonly=$state.readonly;
		var compid=$state.compid;
		var weui=$state.weui;
		var __auto1=$state.__auto1;
		var loadingAfterShow=$state.loadingAfterShow;
		var text=$state.text;
		var button1=$state.button1;
		var _userdata=$state._userdata;
		var val=$state.val;
		var wx=$state.wx;
		var __toptips__=$state.__toptips__;
		var msgs=$state.msgs;
		var button2=$state.button2;
		var verifyCode=$state.verifyCode;
		var message=$state.message;
		var btnLabel=$state.btnLabel;
		var hide=$state.hide;
		var x=$state.x;
		var typeClass=$state.typeClass;
		var loadingNum=$state.loadingNum;
		var page=$state.page;
		return (
<WxView navigationBarTitleText="绑定手机" id="page" data-compid={((_exRun('page.compid','page'))(page))} data-pageid={(__pageid__)} className={this.getModuledCss('x-page x-page-wx')}>  
  <WxView style={__styleToJSON('z-index: 10001;')} id="__auto1" data-compid={((_exRun('__auto1.compid','__auto1'))(__auto1))} className={this.getModuledCss('weui-loader-full ' + ((_exRun('('+(__exRun(''+(__exRun('__auto1.loadingAfterShow','__auto1'))(__auto1)+'&&'+(__exRun(''+(__exRun('__auto1.loadingNum','__auto1'))(__auto1)+'<=0','__auto1'))(__auto1)+'','__auto1'))(__auto1)+')?"weui-loader-hide":""','__auto1'))(__auto1)))}>  
    <WxView id="__auto2" className={this.getModuledCss('ball-scale-ripple-multiple')}> 
      <WxView id="__auto3" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto4" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto5" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto6" className={this.getModuledCss('weui-loader-view')}/>  
      <WxView id="__auto7" className={this.getModuledCss('weui-loader-view')}/>
    </WxView>
  </WxView>  
    
  <WxView id="inVisibleCompContainer"/>  
  <WxView id="view" style={__styleToJSON('height:40px')}/>  
  <WxIcon id="icon3" size="40" type="safe_warn" style={__styleToJSON('margin-left:12px;')}/>  
  <WxText id="text" style={__styleToJSON('padding-left:12px')} data-compid={((_exRun('text.compid','text'))(text))}>{(_exRun('text.text','text'))(text)}</WxText>  
  <WxView __id="id_18" id="form" className={this.getModuledCss('weui-cells weui-cells_form  weui-cell-full')}> 
    <WxView __id="id_19" id="cell1" className={this.getModuledCss('weui-cell weui-cell_input')}> 
      <WxView __id="id_20" id="hd1" className={this.getModuledCss('weui-cell__hd')}> 
        <WxView __id="id_21" id="label1" className={this.getModuledCss('weui-label')}> 
          <WxLabel __id="id_22" id="label2_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("手机号")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("手机号")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("手机号")'))()+'===""'))()+''))()+')?"":("手机号")'))()}</WxLabel>
        </WxView>
      </WxView>  
      <WxView __id="id_23" id="bd1" className={this.getModuledCss('weui-cell__bd')}> 
        <WxInput __id="id_24" id="input1" placeholder="请输入手机号" value={((_exRun('data.current.phonenumber','data'))(data))} type="text" data-compid={((_exRun('input1.compid','input1'))(input1))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input1.compid','input1'))(input1)) + ':onChange'} data-bindinput={((_exRun('input1.compid','input1'))(input1)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.phonenumber','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.phonenumber.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.phonenumber','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.phonenumber.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.phonenumber.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.phonenumber','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.phonenumber.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView>
    </WxView>  
    <WxView __id="id_25" id="cell4" className={this.getModuledCss('weui-cell weui-cell_input weui-cell_vcode')}> 
      <WxView __id="id_26" id="hd4" className={this.getModuledCss('weui-cell__hd')}> 
        <WxView __id="id_27" id="label4" className={this.getModuledCss('weui-label')}> 
          <WxLabel __id="id_28" id="label4_1">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("验证码")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("验证码")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("验证码")'))()+'===""'))()+''))()+')?"":("验证码")'))()}</WxLabel>
        </WxView>
      </WxView>  
      <WxView __id="id_29" id="bd4" className={this.getModuledCss('weui-cell__bd')}> 
        <WxInput __id="id_30" id="input21" placeholder="请输入验证码" value={((_exRun('data.current.verifyCode','data'))(data))} type="text" data-compid={((_exRun('input21.compid','input21'))(input21))} bindblur="dispatchBind" data-pageid={(__pageid__)} bindinput="dispatchBind" data-bindblur={((_exRun('input21.compid','input21'))(input21)) + ':onChange'} data-bindinput={((_exRun('input21.compid','input21'))(input21)) + ':onInput'} hidden={((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode.hidden','data'))(data)+'','data'))(data)+')','data'))(data))} disabled={((_exRun('!'+(__exRun('!'+(__exRun('('+(__exRun(''+(__exRun('!'+(__exRun('data.current','data'))(data)+'','data'))(data)+'||'+(__exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode.readonly','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+')','data'))(data)+'','data'))(data)+'','data'))(data))} className={this.getModuledCss(((_exRun('data.current._userdata.verifyCode.required.val?"x-wx-required":""','data'))(data)) + ' weui-input c-input ' + ((_exRun('('+(__exRun(''+(__exRun(''+(__exRun('data.current._userdata','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode','data'))(data)+'','data'))(data)+'&&'+(__exRun('data.current._userdata.verifyCode.readonly','data'))(data)+'','data'))(data)+')?"readonly":""','data'))(data)))}/>
      </WxView>  
      <WxView __id="id_31" id="ft4" className={this.getModuledCss('weui-cell__ft')}> 
        <WxView __id="id_32" id="button2" data-compid={((_exRun('button2.compid','button2'))(button2))} data-bindtap="sendSmsBtnClick" data-pageid={(__pageid__)} bindtap="dispatchBind" disabled={((_exRun('ctrl.current.btnDisabled','ctrl'))(ctrl))} className={this.getModuledCss('weui-vcode-btn')}> 
          <WxLabel __id="id_33" id="label_vcode-btn">{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===null','ctrl'))(ctrl)+'||'+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===undefined','ctrl'))(ctrl)+'','ctrl'))(ctrl)+'||'+(__exRun(''+(__exRun('('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)+'===""','ctrl'))(ctrl)+'','ctrl'))(ctrl)+')?"":('+(__exRun('ctrl.current.btnLabel','ctrl'))(ctrl)+')','ctrl'))(ctrl)}</WxLabel>
        </WxView>
      </WxView>
    </WxView>
  </WxView>  
  <WxView id="row" className={this.getModuledCss('x-row')}>  
    <WxView id="col" className={this.getModuledCss('x-col x-col-flex-1')}/>  
    <WxView id="col1" className={this.getModuledCss('x-col x-col-flex-8')}> 
      <WxButton id="button" type="primary" style={__styleToJSON('margin-top:8px;')} loading={((_exRun('button.debounce','button'))(button))} data-compid={((_exRun('button.compid','button'))(button))} data-bindtap="bindPhone" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn btn-ok')}>  
        <WxIcon id="icon1" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>  
        <WxView id="view2" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("绑定手机")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("绑定手机")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("绑定手机")'))()+'===""'))()+''))()+')?"":("绑定手机")'))()}</WxView>
      </WxButton>  
      <WxButton id="button1" style={__styleToJSON('margin-top:8px;')} loading={((_exRun('button1.debounce','button1'))(button1))} data-compid={((_exRun('button1.compid','button1'))(button1))} data-bindtap="$evtH_button1_tap" data-pageid={(__pageid__)} bindtap="debounceDispatchBind" className={this.getModuledCss('wx-btn btn-ok')}> 
        <WxIcon id="icon2" type="wx-btn-icon" className={this.getModuledCss(' wx-icon')}/>  
        <WxView id="view3" className={this.getModuledCss('wx-btn-label')}>{(_exRun('('+(__exRun(''+(__exRun(''+(__exRun(''+(__exRun('("暂时不绑定")'))()+'===null'))()+'||'+(__exRun(''+(__exRun('("暂时不绑定")'))()+'===undefined'))()+''))()+'||'+(__exRun(''+(__exRun('("暂时不绑定")'))()+'===""'))()+''))()+')?"":("暂时不绑定")'))()}</WxView>
      </WxButton>
    </WxView>  
    <WxView id="col2" className={this.getModuledCss('x-col x-col-flex-1')}/>
  </WxView>
<WxView id="__toptips__" hidden={((_exRun('!'+(__exRun('__toptips__.show','__toptips__'))(__toptips__)+'','__toptips__'))(__toptips__))} data-compid={((_exRun('__toptips__.compid','__toptips__'))(__toptips__))} className={this.getModuledCss('wx-toptips')}>{(_toArray(((_exRun('__toptips__.msgs','__toptips__'))(__toptips__)))).map((item, index)  => (<WxView id="__auto8" key={item['_key'] || _.random(0, 1000,true)} className={this.getModuledCss('wx-toptips-item ' + ((_exRun('item.typeClass','item'))(item)) + ' ' + ((_exRun('item.show?"wx-toptips--show":""','item'))(item)))}>{(_exRun('item.message','item'))(item)}</WxView>))}
</WxView></WxView>
		);
	}
}
