import Base64 from './base64';
import CryptoRequest from "core/api/network/crypto-request";
class FileApi{
    getActionUrl(page,props={},fileInfo = {}){
        let actionUrl = fileInfo.actionUrl || props.actionUrl || "/storage";
        let sourceServiceName = fileInfo.sourceServiceName || props.sourceServiceName;
        return page.getServiceUrl(actionUrl,sourceServiceName);
    }

	getSrc(src,context) {
        if (src && src.startsWith("[{")) {
            let result = JSON.parse(src);
            for (var i = 0; i < result.length; i++) {
                src = this.getFileUrl({
                    actionUrl: this.getActionUrl(context.getPage(), context.props),
                    storeFileName: result[i].storeFileName,
                    realFileName: result[i].realFileName
                })
            }
        }
        return src;
    }

    getFileUrl(info){
        info.operateType = info.operateType || "browse";
        if(info.operateType == "upload"){
            return info.actionUrl + "/postObject";
        }else if(info.operateType == "fileview"){
            var url = info.actionUrl + "/getObject?storeFileName=" + encodeURIComponent(info.storeFileName)
            + (info.ownerID?("&ownerID=" + encodeURIComponent(info.ownerID)):"")
            + (info.realFileName?("&realFileName=" + encodeURIComponent(info.realFileName)):"")
            + (info.operateType?("&operateType=" + encodeURIComponent(info.operateType)):"")
            + "&fullfilename=" + encodeURIComponent(info.realFileName || info.storeFileName);
		    //浏览 下载 返回绝对路径 支持其他设备或者其他应用使用  uix这一支 因为是小程序规范 所以内部不能是http开头的
		    let result = '/fileview/onlinePreview?url='+encodeURIComponent(Base64.encode(url));
            if(window && window.parentPath){
                result = window.parentPath + result;
            }
		    return CryptoRequest.encode(result);
		}else{
            let result = info.actionUrl + "/getObject?storeFileName=" + encodeURIComponent(info.storeFileName)
                    + (info.ownerID?("&ownerID=" + encodeURIComponent(info.ownerID)):"")
                    + (info.realFileName?("&realFileName=" + encodeURIComponent(info.realFileName)):"")
                    + (info.operateType?("&operateType=" + encodeURIComponent(info.operateType)):"")
                    + "&fullfilename=" + encodeURIComponent(info.realFileName || info.storeFileName);
            //浏览 下载 返回绝对路径 支持其他设备或者其他应用使用  uix这一支 因为是小程序规范 所以内部不能是http开头的
            return CryptoRequest.encode(result);
        }
    }
}

export default new FileApi();
