import PageImpl from "../../../lib/base/pageImpl";
import Comp from "../associationSelect";
import {observable, extendObservable, autorun, toJS, isObservableArray, isObservableObject, isObservable } from  "../../../lib/mobx/mobx-2.6.2.umd";
import {cloneJSON} from "../../../lib/base/util";

const ID_COL_NAME = "__id_";
export default class IndexPage extends PageImpl {
	constructor(...args){
		super(...args);
        this.dataUUID = observable();
		this.dataDef = null;
	}

    getLabels(){
        let v = Comp.getDataDef(this.dataUUID.get());
        if(v && v.labels){
            return v.labels;
        }
        return [];
    }

    getLabel(listitem,labelItem){;
        return listitem[labelItem.name];
    }

	getItems(){
        let v = Comp.getDataDef(this.dataUUID.get());
        if(v && v.data){
        	let idCol = v.data.getIdColumn();
            this.comp('list').setData(v.data);
            let items = toJS(v.data.value);
            if(ID_COL_NAME!==idCol && items && items.length>0){
            	for(let i=0,len=items.length;i<len;i++){
            		let item = items[i];
            		item[ID_COL_NAME] = item[idCol];
            	}
            }
            return items;
        }
        return [];
	}

	isMultiSelection(){
		return "true"===this.params.multiSelection;	
	}
	
    onListTap(event){
		if(this.dataDef && this.dataDef.data){
			let data = this.dataDef.data;
			let idCol = data.getIdColumn();
			data.each((p)=>{
				let id = p.row[idCol];
				if(!this.isMultiSelection()){
					p.row.selected = (id==event.currentTarget.dataset.idcolumn?"1":"");
				}else if(id==event.currentTarget.dataset.idcolumn){
					p.row.selected = p.row.selected=="1"?"":"1";
					return false;
				}
			});
		}
    }

    onPageLoaded(event){
		if(this.params.compid){
			this.dataDef = Comp.getDataDef(this.params.compid);
			let v = this.dataDef; 
			if(v && v.labels && v.data){
				let searchBar = this.comp('searchBar');
				searchBar.setData(v.data);
				let labels = [];
				v.labels.forEach((label, i)=>{
					labels.push(label.name);
				});
				searchBar.setFilterProps(labels.join());
			}
		}
        this.dataUUID.set(this.params.compid);
	}

/*特殊代码目前保留,当组件绑定外部传入data时需要下面函数重载
	comp(id){
		let comp = super.comp(id);
		if(!comp && this.dataDef && this.dataDef.data && this.dataDef.data.id==id){
			comp = this.dataDef.data;
		}
		return comp;
	}
*/
	
    onOkTap(event){
        wx.navigateBack({
            params: {
              isOk: true,
              compid: this.dataUUID.get()
            }
        });
	}
}
